import React, { useEffect, useState } from "react";
import "./PaymentInvoiceStyle.css";
import Logo2 from "../../../../../assets/common/rentIq_logo_invoice.png";
import { formatDate } from "../../../../../utils/generalFunctions";
export const PaymentInvoice = ({ rentDetails, tenantDetailsData, orgInfo }) => {
  const [totalPaymentAmount, setTotalPaymentAmount] = useState("₹ 0");
  const [totalCreditBalance, setTotalCreditBalance] = useState("₹ 0");
  const [tenantDetails, setTenantDetails] = useState({});
  useEffect(() => {
    let totalPaymentAmount = 0;
    let totalCreditBalance = 0;
    rentDetails?.map((rentDetail) => {
      if (rentDetail?.paid_amount) {
        totalPaymentAmount += parseFloat(rentDetail?.paid_amount);
      }
      if (rentDetail?.balance_amount) {
        totalCreditBalance += parseFloat(rentDetail?.balance_amount);
      }
    });
    setTotalPaymentAmount("₹ " + totalPaymentAmount);
    setTotalCreditBalance("₹ " + totalCreditBalance);

    let currentTenantObj = {};
    tenantDetailsData?.map((tenantDetail) => {
      currentTenantObj[tenantDetail?.id] = tenantDetail?.value;
    });

    setTenantDetails(currentTenantObj);
  }, [rentDetails]);

  // useEffect(() => {
  //   // print below div as invoice after page load 2 sec
  //   setTimeout(() => {
  //     Print();
  //   }, 2000);
  // });

  return (
    <div className={"tenants_invoice_warapper"} id="tenants_invoice_warapper">
      <div className={"tenants_invoice"}>
        <div className={"header"}>
          {/* <img
            className="rectangle"
            alt="Rectangle"
            src="https://c.animaapp.com/245SKHjh/img/rectangle-34625722.svg"
          />
          <img
            className="img"
            alt="Rectangle"
            src="https://c.animaapp.com/245SKHjh/img/rectangle-34625723.svg"
          /> */}

          <div className={"rectangles"}>
            <img src={Logo2} alt="Logo" />
            <div className={"rectangle rectangle2"}></div>
            <div className={"rectangle rectangle3"}>
              <div className={"heading_textwrapper"}>
                <div className="heading_title">
                  {/* {orgInfo?.organisationName || "RentIQ"} */}
                  RENTAL LEGDGER
                </div>
                <a
                  className="heading_link"
                  href="https://www.rentiq.in/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  www.rentIQ.com
                </a>
              </div>
            </div>
          </div>
          <div className={"mid_title_container"}>
            {/* <div className={"title1"}>RENTAL LEGDGER</div> */}
            <div className={"title1"}>{orgInfo?.organisationName}</div>
            {/* <div className={"title2"}>ABN: 11605961215</div> */}
          </div>

          <div className={"invoice_details"}>
            <div className="left_details">
              <div>Date: {formatDate(Date.now())}</div>
              <div>Name: {tenantDetails?.tenantName}</div>
              <div>Address: {tenantDetails?.unitName}</div>
              <div>
                {/* Accommodation Type: Room sharing (Rental amount included all
                utility bills) */}
                PAN No.: {tenantDetails?.panNo}
              </div>
            </div>
            <div className="right_details">
              {/* <div>Organization : {orgInfo?.organisationName}</div> */}
              <div>GSTIN: {orgInfo?.GST}</div>
              {/* <div>Pass Port No.B3456</div> */}
              {/* <div className="text-wrapper-51">Payment Type: Cash, EFTPOS</div> */}
              <div>Arrival: {formatDate(tenantDetails.startDate)}</div>
              <div>Departure: {formatDate(tenantDetails.endDate)}</div>
            </div>
          </div>
        </div>

        <center>
          <div className={"invoice_seperator"}></div>
        </center>

        <div className={"invoice_body"}>
          <table className={"invoice_data_table"}>
            {/* <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Payment Type</th>
                <th>Mode of Payment</th>
                <th>Charges (INR)</th>
                <th>Balance</th>
              </tr>
            </thead> */}
            {/* <tbody> */}
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Payment Type</th>
              <th>Mode of Payment</th>
              <th>Charges (INR)</th>
              <th>Balance</th>
            </tr>
            {rentDetails?.map((rentDetail, index) => (
              <tr key={index}>
                <td>{rentDetail.payment_date}</td>
                <td>{rentDetail.description}</td>
                <td>{rentDetail.payment_category}</td>
                <td>{rentDetail.payment_mode}</td>
                <td>{rentDetail.paid_amount}</td>
                <td>{rentDetail.balance_amount}</td>
              </tr>
            ))}

            <tr className={"data_summary"}>
              {/* Merge four cell */}
              <td colSpan="4">Summary of Payment</td>
              <td>{totalPaymentAmount}</td>
              <td>{totalCreditBalance}</td>
            </tr>
            <tr className={"data_summary"}>
              {/* Merge four cell */}
              <td colSpan="5">Total amount paid (Including GST)</td>
              <td>{totalPaymentAmount}</td>
            </tr>
            {/* </tbody> */}
          </table>
        </div>

        <div className={"invoice_footer"}>
          <center>
            <div className={"invoice_seperator"}></div>
          </center>

          <p className="footer_line1">
            Please contact us via Email: {orgInfo?.organisationEmail}
            {/* | Phone:
            1800 325 107 */}
          </p>
          <p className="footer_line2">
            Address: {orgInfo?.organisationAddress}
          </p>
        </div>
      </div>
    </div>
  );
};
