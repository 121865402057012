import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePage: "/admin/dashboard",
};

const currentPageSlice = createSlice({
  name: "currentPage",
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.activePage = action.payload;
    },
  },
});

export const { updateCurrentPage } = currentPageSlice.actions;
export default currentPageSlice.reducer;
