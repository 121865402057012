import { Route, Routes } from "react-router-dom";
import Approvals from "./Approvals";

const ApprovalsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Approvals />} />
    </Routes>
  );
};

export default ApprovalsRoutes;
