import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/tenants/Tenants.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";

import TotalTenants from "../../../../assets/sections/admin/tenants/total_tenants.svg";
import OnNotice from "../../../../assets/sections/admin/tenants/on_notice.svg";
import UpcomingTenants from "../../../../assets/sections/admin/tenants/upcoming_tenants.svg";
import TenantsDue from "../../../../assets/sections/admin/tenants/tenants_due.svg";
import TenantsPaid from "../../../../assets/sections/admin/tenants/tenants_paid.svg";
import StatsBoard from "../../../common/statsboard/StatsBoard";
import StatsTable from "../../../common/stats_table/StatsTable";
import ActionButton from "../../../common/action_button/ActionButton";
import SearchField from "../../../common/search_field/SearchField";
import IconButton from "../../../common/icon_button/IconButton";
import AddIcon from "../../../../assets/common/add_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { STATS_KEYS } from "../../../../utils/constants";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { formatDate } from "../../../../utils/generalFunctions";
import { useNavigate } from "react-router-dom";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { debounce } from "lodash";

const Tenants = () => {
  const stateDataTableData = useSelector((state) => state.getTableData);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const statePageStats = useSelector((state) => state.pageStats);
  const scroll = useSelector((state) => state.pageScroll);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialRender = useRef(true);

  const [tenantsTableData, setTenantsTableData] = useState([]);

  const [searchText, setSearchText] = useState("");
  // const [pageNumber, setPageNumber] = useState();
  const [isSearchUsed, setIsSearchUsed] = useState(false);

  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );

    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  // Original handleSearch function now calls the debounced version
  const handleSearch = (text) => {
    debouncedHandleSearch(text);
  };
  // Units Stats Board
  const tenants_icons = [
    TotalTenants,
    OnNotice,
    UpcomingTenants,
    TenantsDue,
    TenantsPaid,
  ];

  const tenant_details = [
    "Total Tenants",
    "On Notice",
    "Upcoming Tenants",
    "Tenants Due",
    "Tenants Paid",
  ];

  const tenants_stats_mapping = [
    "totalTenants",
    "onNotice",
    "upcomingTenants",
    "tenantsDue",
    "tenantsPaid",
  ];

  // Units Stats Table
  const tenants_statsTable_headers = [
    "Tenant Name",
    "Building",
    "Flat No",
    "Contact",
    "Joining date",
    "Rent",
    "Rent status",
    "Action",
  ];

  const tenants_tableFieldMapping = [
    "tenant_name",
    "unit",
    "flat_no",
    "contact",
    "joining_date",
    "rent",
    "rent_status",
    "action",
  ];

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData.tenant_name = item.tenantName;
      mappedData.unit = item.unitName;
      mappedData.flat_no = item.flatNo;
      mappedData.contact = item.mobile;
      mappedData.joining_date = formatDate(item.startDate);
      mappedData.rent = "₹ " + item.monthlyRent;
      // TODO: Change rent_status to Paid/Pending
      mappedData.rent_status = (
        <p
          className={
            item.rentStatus === "Paid"
              ? styles.rentStatusPaid
              : styles.rentStatusPending
          }
        >
          {item.rentStatus}
        </p>
      );
      mappedData.action = (
        <ActionButton
          buttonText={"More Details"}
          onClick={() => {
            dispatch(
              updatePageNumber({
                pageNo: 1,
              })
            );
            navigate("" + item._id);
          }}
        ></ActionButton>
      );

      mappedDataList.push(mappedData);
    });

    setTenantsTableData(mappedDataList);
  };

  useEffect(() => {
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.EMPLOYEE__TENANTS__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.EMPLOYEE__TENANTS__STATS, "");
    }
  }, [scroll.pageNo, searchText]);

  useEffect(() => {
    if (stateDataTableData.adminTenants?.length >= 0)
      processTableData(stateDataTableData.adminTenants);
  }, [stateDataTableData.adminTenants]);

  return (
    <>
      <StatsBoard
        boardName="Tenants"
        statIcons={tenants_icons}
        statNames={tenant_details}
        statValuesMapping={tenants_stats_mapping}
        statValues={statePageStats?.adminTenantStats}
      ></StatsBoard>

      <StatsTable
        tableTitle={"Tenants"}
        tableHeaders={tenants_statsTable_headers}
        tableData={tenantsTableData}
        tableFieldMapping={tenants_tableFieldMapping}
        tableActionsElement={
          <div className={styles.tenants_stats_table_actions}>
            <SearchField searchText={searchText} onSearch={handleSearch} />
            <IconButton
              buttonText={"Add Tenant"}
              buttonIcon={AddIcon}
              onClick={() => navigate("/admin/tenants/addTenant")}
            ></IconButton>
          </div>
        }
        tableActionsElementMobile={
          <div
            className={
              statsStyles.stats_title_actions_container +
              " " +
              statsStyles.stats_title_actions_container_show
            }
          >
            <div className={statsStyles.statsTable_title_container}>
              <div className={statsStyles.stats_table_title}>All Units</div>
              <div className={styles.units_stats_table_actions}>
                <IconButton
                  buttonText={"Add Tenant"}
                  buttonIcon={AddIcon}
                  onClick={() => navigate("/admin/tenants/addTenant")}
                ></IconButton>
              </div>
            </div>

            <SearchField searchText={searchText} onSearch={handleSearch} />
          </div>
        }
        // setPageNumber={setPageNumber}
        totalPages={stateDataTotalPages.adminTenantsTotalPages}
      ></StatsTable>
    </>
  );
};

export default Tenants;
