import React from "react";

import styles from "../../styles/authentication/Login.module.css";
import LoginPage_BG from "../../assets/authentication/login_bg.svg";

import RI_logo from "../../assets/authentication/RentIq_logo.svg";
import ChangePasswordForm from "./ChangePasswordForm";
import SetPasswordForm from "./SetPasswordForm";

function SetPassword() {
  return (
    <div className={styles.login}>
      <img
        src={LoginPage_BG}
        className={styles.loginPage_BG}
        alt="LoginPage_BG"
      />
      <div className={styles.form_container}>
        <div className={styles.logo}>
          <img src={RI_logo} alt="RI_logo" />
        </div>
        <div className={styles.form_section}>
          <SetPasswordForm />
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
