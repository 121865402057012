/*
Table keys are named as 
Section name - ADMIN, TENANT
Current page name - Units, Tenants, Approvals etc.
Data Table Name - All Units, Tenants, Approvals etc.

So, a All Units table on the Units page on the admin section would be names as ADMIN__UNITS__ALL_UNITS
*/

const USER_TYPE = {
  ADMIN: "ADMIN",
  TENANT: "TENANT",
};

const tableKey = {
  ADMIN__UNITS__ALL_UNITS: "EMPLOYEE__UNITS__ALL_UNITS",
  ADMIN__TENANTS__TENANTS: "EMPLOYEE__TENANTS__TENANTS",
  ADMIN__APPROVALS__APPROVALS: "EMPLOYEE__APPROVALS__APPROVALS",
  ADMIN__NOTES__NOTES: "EMPLOYEE__NOTES__NOTES",
  ADMIN__MESSAGES__MESSAGES: "EMPLOYEE__MESSAGES__MESSAGES",
  ADMIN__INVOICES__INVOICES: "EMPLOYEE__INVOICES__INVOICES",
  ADMIN__CLEANING__CLEANING: "EMPLOYEE__CLEANING__CLEANING",
  ADMIN__CLEANING__CLEANER: "EMPLOYEE__CLEANING__CLEANER",
  ADMIN__CAR_PARKING__CAR_PARKING: "EMPLOYEE__CAR_PARKING__CAR_PARKING",
  TENANT__PAYMENT_HISTORY: "TENANT__PAYMENT_HISTORY",
  ADMIN__TENANT__PAYMENT_HISTORY: "EMPLOYEE__TENANT__PAYMENT_HISTORY",
};

// Stats
const STATS_KEYS = {
  EMPLOYEE__STATS_DASHBOARD: "EMPLOYEE__STATS_DASHBOARD",
  EMPLOYEE__UNITS__STATS: "EMPLOYEE__UNITS__STATS",
  EMPLOYEE__TENANTS__STATS: "EMPLOYEE__TENANTS__STATS",
  EMPLOYEE__APPROVALS__STATS: "EMPLOYEE__APPROVALS__STATS",
  EMPLOYEE__CLEANING__STATS: "EMPLOYEE__CLEANING__STATS",
  EMPLOYEE__CAR_PARKING__STATS: "EMPLOYEE__CAR_PARKING__STATS",
  EMPLOYEE__INVOICE__STATS: "EMPLOYEE__INVOICE__STATS",

  // Internal stats
  ADMIN__UNITS__STATS_INTERNAL: "EMPLOYEE__UNITS__STATS_INTERNAL",
  EMPLOYEE__TENANTS__STATS_INTERNAL: "EMPLOYEE__TENANTS__STATS_INTERNAL",
};

const apiEndpointname = {
  // AUTH
  LOGIN: "/auth/login",
  SIGNUP: "/auth/signup",
  OTP_VERIFY: "/auth/verifyOtp",
  TENANT_SETPASSWORD: "/tenant/setPassword",
  ADMIN_SETPASSWORD: "/employee/setPassword",
  FORGOT_PASSWORD: "/forgotPassword",
  TENANT_CHANGE_PASSWORD: "/tenant/changePassword",
  ADMIN_CHANGE_PASSWORD: "/employee/changePassword",

  //orgaization
  TENANT_GET_ORGANISATION: "/tenant/getOrganisation",
  ADMIN_GET_ORGANISATION: "/employee/getOrganisation",

  // Profile
  ADMIN_PROFILE_GET: "/employee/getProfile",
  ADMIN_PROFILE_UPDATE: "/employee/updateProfile",
  TENANT_PROFILE_GET: "/tenant/getTenantInfo",
  TENANT_PROFILE_UPDATE: "/tenant/updateProfileRequest",

  TENANT_PROFILE_PHOTO_UPLOAD: "/tenant/uploadProfilePhoto",
  ADMIN_PROFILE_PHOTO_UPLOAD: "/employee/uploadProfilePhoto",

  // Documents
  TENANT_DOCUMENTS_GET: "/tenant/getAllDocuments",
  ADMIN_DOCUMENTS_GET: "/employee/getAllDocuments",
  TENANT_DOCUMENTS_UPLOAD: "/tenant/uploadDocument",
  ADMIN_DOCUMENTS_UPLOAD: "/employee/uploadDocument",

  // Stats
  EMPLOYEE_STATS: "/employee/stats",
  TENANT_STATS: "/tenant/stats",

  // Asset Download
  ASSET_DOWNLOAD: "/download",
  // TENANT_ASSET_DOWNLOAD: "/tenant/download",

  // units
  ADMIN__UNITS__ADD_UNIT: "/employee/addUnit",
  ADMIN__UNITS__GET_UNIT_DETAILS: "/employee/getUnitDetails",
  ADMIN__UNITS__UPDATE_UNIT_DETAILS: "/employee/updateUnitDetails",
  ADMIN__UNITS__GET_ALL_UNITS: "/employee/getAllUnitDetails",

  // setup expenses
  ADMIN_UNITS_SETUP_GET_EXPENSES: "/employee/unit/getAllExpenses",
  ADMIN__UNITS__SETUP_ADD_EXPENSE: "/employee/unit/addExpense",
  ADMIN__UNITS__SETUP_UPDATE_EXPENSE: "/employee/unit/updateExpense",
  ADMIN__UNITS__SETUP_DELETE_EXPENSE: "/employee/unit/deleteExpense",

  // tenants
  ADMIN__TENANTS__ADD_TENANT: "/employee/addTenant",
  ADMIN__TENANTS__RESEND_ONBOARDING_EMAIL: "/employee/resendEmail",
  ADMIN__TENANTS__UPDATE_TENANT_DETAILS: "/employee/updateTenantDetails",
  ADMIN__TENANTS__GET_TENANT_DETAILS: "/employee/getTenantDetails",
  ADMIN__TENANTS__GET_ALL_TENANTS: "/employee/getAllTenantDetails",
  ADMIN__TENANTS__GET_TENANT_PAYMENT_HISTORY:
    "/employee/payment/getPaymentHistory",
  ADMIN__TENANTS__ADD_PAYMNET_RECORD:
    "/employee/payment/shareTenantPaymentDetails",

  ADMIN__TENANTS__SEND_CONTRACT: "/employee/sendContract",

  // parking
  ADMIN__PARKING__ADD_CAR_PARKING: "/admin/parking/addParking",
  ADMIN__PARKING__UPDATE_CAR_PARKING_DETAILS: "/admin/parking/slots",
  ADMIN__PARKING__GET_ALL_CAR_PARKING: "/admin/parking/listAll",

  // approvals
  ADMIN__APPROVALS__GETALL__APPROVALS: "/employee/approvals/getApproval",
  ADMIN__APPROVALS__GET_APPROVAL_DETAILS: "/employee/getApprovalDetails",
  ADMIN__APPROVAL_ACTION: "/employee/approvals/acceptOrReject",

  // notes
  ADMIN__NOTES__ADD_NOTE: "/employee/notes/addNote",
  ADMIN__NOTES__UPDATE_NOTE: "/employee/notes/updateNote",
  ADMIN__NOTES__DELETE_NOTE: "/employee/notes/deleteNote",
  ADMIN__NOTES__GET_ONE_NOTE: "/employee/notes/getNote",
  ADMIN__NOTES__GET_ALL_NOTE: "/employee/notes/getAllNotes",

  // messages
  ADMIN__MESSAGES__GET_ALL_MESSAGES: "/admin/wa_msg/getAllMsgs",
  ADMIN__MESSAGES__GET_ONE_MESSAGE: "/admin/wa_msg/getMsg",
  ADMIN__MESSAGES__ADD_MESSAGE: "/admin/wa_msg/sendMsg",
  ADMIN__MESSAGES__UPDATE_MESSAGE: "/admin/wa_msg/updateMsg",
  ADMIN__MESSAGES__DELETE_MESSAGE: "/admin/wa_msg/deleteMsg",

  // invoices
  ADMIN__INVOICES__GET_ALL_INVOICE: "/employee/invoice/getAllInvoice",
  ADMIN__INVOICES__GET_INVOICE_DETAILS: "/employee/invoice/getInvoice",
  ADMIN__INVOICES__UPDATE_INVOICE_DETAILS: "/employee/invoice/updateInvoice",
  ADMIN__INVOICES__ADD_INVOICE: "/employee/invoice/addInvoice",
  ADMIN__INVOICES__DELETE_INVOICE: "/employee/invoice/deleteInvoice",

  // Cleaning
  ADMIN__CLEANING__GET_ALL_UNITS: "/employee/cleaning/getAllUnitDetails",
  ADMIN__CLEANING__GET_ALL_CLEANERS: "/employee/cleaning/getAllRecords",
  ADMIN__CLEANING__ADD_CLEANER: "/employee/cleaning/addCleanerRecord",
  ADMIN__CLEANING__UPDATE_CLEANER: "/employee/cleaning/updateCleanerRecord",

  // Tenants
  TENANT__HOME__COTENANTS: "/tenant/co-tenants",
  TENANT__PERSONAL__GET_DETAILS: "/tenant/getTenantDetails",
  TENANT__PERSONAL__UPDATE_DETAILS: "/tenant/profileReview",
  TENANT__SEND__SIGNED_CONTRACT: "/tenant/sendSignedContract",

  // Tenant - Payment History
  TENANT_SHARE_PAYMENT_DETAILS: "/tenant/payment/sharePaymentDetails",
  TENANT__GET_TENANT_PAYMENT_HISTORY: "/tenant/payment/getPaymentHistory",

  // Plots
  ADMIN_PLOTS: "/employee/plots/",
};

// Plot Constants
const PLOTS = {
  PLOT_DASHBOARD: "dashboard",
};

export { USER_TYPE, tableKey, apiEndpointname, STATS_KEYS, PLOTS };
