import styles from "../../../../styles/sections/tenant/request_history/RequestHistoryModal.module.css";
import PaymentForm from "../../../common/payment_form/PaymentForm";

const RequestHistoryModal = ({ state, active, header }) => {
  const new_request_form_fields = [
    {
      for: "Request",
      placeholder: "Select type of Request",
      type: "DropDown",
      options: ["Visit", "Family Visit", "Extra Bed", "Others"],
      value: "",
    },
    {
      for: "Description",
      placeholder: "Description...",
      type: "TextArea",
      value: "",
    },
  ];

  const approved_form_fields = [
    {
      for: "Type of Request",
      placeholder: "Type of Request",
      type: "text",
      value: "",
    },
    {
      for: "Description",
      placeholder: "Description",
      type: "TextArea",
      value: "",
    },
  ];

  const declined_form_fields = [
    {
      for: "Type of Request",
      placeholder: "Type of Request",
      type: "text",
      value: "",
    },
    {
      for: "Description",
      placeholder: "Description...",
      type: "TextArea",
      value: "",
    },
    {
      for: "Description",
      placeholder: "Reason For Decline",
      type: "TextArea",
      value: "",
    },
  ];

  const submitNewRequest = (rent, payment, image, request) => {
    new_request_form_fields.map((field) => {
      if (field.for === "Request") field.value = request;
      return console.log(field.value);
    });
  };

  const submitApproved = () => {
    state("Request History");
  };

  return (
    <div className={styles.back_drop}>
      <div className={styles.modal}>
        <div className={styles.back_drop_navBar}>
          {header.map((item, index) => {
            return (
              <span
                key={index}
                onClick={() => state(item)}
                className={
                  active === item || item === "Request Details"
                    ? styles.active
                    : ""
                }
              >
                {item}
              </span>
            );
          })}
        </div>
        {active === "New Request" && (
          <PaymentForm
            fields={new_request_form_fields}
            handleSubmit={submitNewRequest}
            buttonText={"Send for Approval"}
          />
        )}
        {active === "Approved" && (
          <PaymentForm
            fields={approved_form_fields}
            handleSubmit={submitApproved}
            buttonText={"Approved"}
          />
        )}
        {active === "Declined" && (
          <PaymentForm
            fields={declined_form_fields}
            handleSubmit={submitApproved}
            buttonText={"Declined"}
          />
        )}
        {active === "Waiting" && (
          <PaymentForm
            fields={approved_form_fields}
            handleSubmit={submitApproved}
            buttonText={"Update Request Details"}
          />
        )}
        <span className={styles.modal_footer}>
          Status will update once Admin approves it
        </span>
      </div>
    </div>
  );
};

export default RequestHistoryModal;
