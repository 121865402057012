import styles from "../../../styles/common/details_table/DetailsTable.module.css";
import Edit from "../../../assets/common/card_details/edit.svg";
import { useState } from "react";
import ActionButton from "../action_button/ActionButton";
import { useDispatch } from "react-redux";
import { showLoader } from "../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../generalApiCalls/postFormData";
import FileViewer from "../image_verification/FileViewer";
import { USER_TYPE } from "../../../utils/constants";
import { cleanFormObject } from "../../../utils/generalFunctions";

const DetailsTable = ({ headerImg, tableTitle, tableData, edit }) => {
  const [editState, setEditState] = useState(false);

  const dispatch = useDispatch();
  const updateDocumentHandler = async () => {
    let updatedData = {};
    updatedData["proofType"] = tableTitle;

    tableData.forEach((item) => {
      updatedData[item.id] = item.value;
    });

    updatedData = cleanFormObject(updatedData);

    console.log(updatedData);

    dispatch(showLoader(true));

    const response = await postFormData({
      form_key:
        localStorage.getItem("userType") === USER_TYPE.ADMIN
          ? "ADMIN_DOCUMENTS_UPLOAD"
          : "TENANT_DOCUMENTS_UPLOAD",
      data: updatedData,
      isFile: true,
    });

    window.location.reload();

    dispatch(showLoader(false));
  };

  return (
    <div className={styles.table_wrap}>
      <div className={styles.table_header_wrap}>
        <span className={styles.table_header}>
          <img src={headerImg} alt=""></img>
          {tableTitle}
        </span>
        {edit && (
          <img
            src={Edit}
            alt=""
            onClick={() => setEditState(!editState)}
            className={styles.edit}
          ></img>
        )}
      </div>
      <div
        className={
          styles.table_content + " " + (editState && styles.edit_table_content)
        }
      >
        {tableData.map((item, index) => {
          return (
            <div
              key={index}
              className={
                styles.table_data_wrap +
                " " +
                (editState && styles.edit_table_data_wrap)
              }
            >
              <span>{item.field_name}</span>
              {editState && item.editable !== false ? (
                item.type === "file" ? (
                  <div className={styles.fileInputWrapper}>
                    <input
                      type="file"
                      className={styles.fileInput}
                      onChange={(event) => {
                        item.value = event.target.files[0];
                      }}
                    />
                    <label className={styles.fileInputLabel}>
                      {item.value ? item.value.name : "Choose a file"}
                    </label>
                  </div>
                ) : (
                  <input
                    type={item.type ? item.type : "text"}
                    defaultValue={item.value}
                    className={styles.edit_input}
                    onChange={(event) => {
                      item.value = event.target.value;
                    }}
                    placeholder={item?.placeholder && item.placeholder}
                  ></input>
                )
              ) : item.type === "file" &&
                (item.originalDocument?.originalName ||
                  item.signedDocument?.originalName) ? (
                <FileViewer
                  key={index}
                  // header={item.field_name}
                  fileName={
                    item?.originalDocument?.originalName
                      ? item?.originalDocument?.originalName
                      : item?.signedDocument?.originalName
                  }
                  fileId={
                    item?.originalDocument?.assetId
                      ? item?.originalDocument?.assetId
                      : item?.signedDocument?.assetId
                  }
                  tenantId={
                    item?.originalDocument?.tenantId
                      ? item?.originalDocument?.tenantId
                      : item?.signedDocument?.tenantId
                  }
                  state={() => {
                    console.log("clicked");
                  }}
                />
              ) : (
                <span>{item.value}</span>
              )}
            </div>
          );
        })}
      </div>
      {editState && (
        <ActionButton
          buttonText={"Update Details"}
          onClick={updateDocumentHandler}
        />
      )}
    </div>
  );
};

export default DetailsTable;
