import React from "react";

import styles from "../../../styles/common/icon_button/IconButton.module.css";

const IconButton = ({
  buttonText,
  buttonIcon,
  onClick,
  whiteBg = false,
  transparentBg = false,
}) => {
  return (
    <div
      className={
        styles.icon_button +
        " " +
        (whiteBg && styles.icon_button_whiteBg) +
        " " +
        (transparentBg && styles.icon_button_transparentBg)
      }
      onClick={onClick}
    >
      {buttonText && buttonText.length > 0 && <span>{buttonText}</span>}
      <img src={buttonIcon} alt={buttonIcon}></img>
    </div>
  );
};

export default IconButton;
