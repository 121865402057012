import Axios from "../axios";
import { apiEndpointname } from "../utils/constants";

const postFormData = async ({
  form_key,
  data,
  urlId = null,
  method = "post",
  isFile = false,
}) => {
  const apiPath = apiEndpointname[form_key];

  let header = {
    Authorization: "Bearer " + localStorage.getItem("accessToken"),
    "content-type": "application/json",
  };

  if (isFile) {
    let tempData = data;
    data = new FormData();
    Object.keys(tempData).forEach((key) => {
      data.append(key, tempData[key]);
    });
    // adding content type to header for file
    header["Content-Type"] = "multipart/form-data";
  }

  let config = {
    method: method,
    maxBodyLength: Infinity,
    url: "api/v1" + apiPath + (urlId ? "/" + urlId : ""),
    headers: header,
    data: data,
  };

  try {
    const response = await Axios.request(config);

    if (response.status === 200 || response.status === 201) {
      return { success: true, message: response.data.message };
    } else if (response.status === 701) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userType");
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (error) {
    console.log("error  ", error);

    // Check if the error has a response with a status code
    if (error.response && error.response.status === 701) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userType");
    }

    return {
      success: false,
      message: error.response?.data?.message || "Error In Add Unit",
    };
  }
};

export { postFormData };
