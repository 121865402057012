import styles from "../../../styles/common/approval_action_modal/ApprovalActionModal.module.css";
import CrossIcon from "../../../assets/common/cross.svg";
import ApprovedIcon from "../../../assets/common/approved.svg";
import DeclinedIcon from "../../../assets/common/declined.svg";
import { useEffect, useState } from "react";
import { postFormData } from "../../../generalApiCalls/postFormData";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../../redux/reducers/loaderSlice";

const ApprovalActionModal = ({
  activeRequestId,
  clickHandler,
  actionType,
  navLinkActive,
}) => {
  const stateDataTableData = useSelector((state) => state.getTableData);

  const [reason, setReason] = useState("");
  const [autoActionsuccess, setAutoActionSuccess] = useState(false);
  const [declined, setDeclined] = useState(false);

  const dispatch = useDispatch();

  const actionButtonHandler = async (
    requestId,
    decision,
    rejectionReason = ""
  ) => {
    let approvalType = "";
    if (navLinkActive.includes("Payment")) {
      approvalType = "payment";
    } else if (navLinkActive.includes("Profile")) {
      approvalType = "profile";
    } else {
      approvalType = "document";
    }

    dispatch(showLoader(true));

    let data = {
      decision: decision,
      rejectionReason: rejectionReason,
      approvalType: approvalType,
    };

    let response = await postFormData({
      form_key: "ADMIN__APPROVAL_ACTION",
      data: data,
      urlId: requestId,
    });

    if (response.success) {
      console.log("success", response.message);
      setAutoActionSuccess(true);
      if (decision === "reject") {
        setDeclined(true);
      }
    } else {
      console.log("error", response.message);
    }
    // setAutoActionSuccess(false);
    dispatch(showLoader(false));
  };

  useEffect(() => {
    if (actionType === "Approve" && activeRequestId !== "") {
      actionButtonHandler(activeRequestId, "accept", actionType);
    }
    if (actionType === "Decline" && activeRequestId !== "") {
      // actionButtonHandler(activeRequestId, "reject", actionType);
    }
    // if (actionType === "Decline" && activeRequestId !== "") {
    //   actionButtonHandler(activeRequestId, "reject", actionType);
    // }
    console.log("activeRequestId", activeRequestId);
    console.log("actionType", actionType);
  }, [stateDataTableData?.adminApprovals]);

  return actionType === "Approve" && autoActionsuccess ? (
    <div className={styles.modal}>
      <div className={styles.modal_content_wrap}>
        <img
          className={styles.cross}
          src={CrossIcon}
          alt=""
          onClick={() => {
            clickHandler(actionType);
            // window.location.reload();
          }}
        ></img>
        {actionType === "Approve" && (
          <div className={styles.approval_content}>
            <img
              src={ApprovedIcon}
              alt=""
              className={styles.approval_icon}
            ></img>

            {navLinkActive.split(" ")[0] === "Profile" ? (
              <>
                <span className={styles.margin_30}>
                  {navLinkActive?.split(" ")[0] + " Approved"}
                </span>
              </>
            ) : (
              <span>{navLinkActive.split(" ")[0] + " Approved"}</span>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={styles.modal}>
      <div className={styles.modal_content_wrap}>
        <img
          className={styles.cross}
          src={CrossIcon}
          alt=""
          onClick={() => {
            clickHandler(actionType);
            // window.location.reload();
          }}
        ></img>

        {actionType === "Decline" && (
          <>
            <div className={styles.approval_content}>
              {!declined ? (
                <>
                  <form className={styles.decline_form}>
                    <label className={styles.form_label}>
                      Reason For Decline
                    </label>
                    <textarea
                      className={styles.form_input}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                    ></textarea>
                  </form>
                  <button
                    className={styles.modal_button}
                    onClick={() => {
                      actionButtonHandler(activeRequestId, "reject", reason);
                    }}
                  >
                    {"Notify Tenant"}
                  </button>
                </>
              ) : (
                <div className={styles.modal_content_wrap}>
                  <img
                    src={DeclinedIcon}
                    alt=""
                    className={styles.approval_icon}
                  ></img>
                  <label className={styles.form_label}>
                    {"Request Declined"}
                  </label>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ApprovalActionModal;
