import React, { useState } from "react";
import styles from "../../../styles/common/AlertBox/Alert.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../redux/reducers/AlertSlice";
import { Fragment } from "react";
import ReactDOM from "react-dom";

const AlertComponent = () => {
  const [closed, setClosed] = useState(false);

  const alertValues = useSelector((state) => state.alert);

  const alertTypeToClass = {
    success: styles.success,
    error: styles.error,
    warning: styles.warning,
    normal: styles.normal,
  };

  const alertIconHtml = {
    success: ":)",
    error: "!",
    warning: "ALERT",
    normal: "?",
  };
  const dispatch = useDispatch();

  // Function to close the alert
  const closeAlert = () => {
    setClosed(true);

    if (alertValues.onClose) {
      alertValues.onClose();
    }
    dispatch(
      setAlert({
        alertType: "",
        alertMessage: "",
        alertTitle: "",
        autoClose: false,
        alertShow: false,
      })
    );
  };

  // Auto close after a certain time
  if (alertValues.autoClose && !closed) {
    setTimeout(() => {
      closeAlert();
    }, alertValues.autoCloseTime);
  }

  return (
    !closed &&
    alertValues.alertShow && (
      <div className={styles.section}>
        <div
          className={
            styles.notification + " " + alertTypeToClass[alertValues.alertType]
          }
        >
          <span className={styles.title}>
            {alertIconHtml[alertValues.alertType]} &nbsp;&nbsp;&nbsp;&nbsp;
            {alertValues.alertTitle}
          </span>{" "}
          {alertValues.alertMessage}
          <span className={styles.close} onClick={closeAlert}>
            X
          </span>
        </div>
      </div>
    )
  );
};

const PortalElement = document.getElementById("alert_box");

const Alert = () => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<AlertComponent />, PortalElement)}
    </Fragment>
  );
};

export default Alert;
