import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/notes/Notes.module.css";

import BackArrowBlue from "../../../../assets/common/back_arrow-blue.svg";

import ThreeDotIcon from "../../../../assets/common/three-dot.svg";
import DeleteIcon from "../../../../assets/common/delete.svg";
import AddIcon from "../../../../assets/common/add_icon.svg";

import SearchField from "../../../common/search_field/SearchField";
import ActionButton from "../../../common/action_button/ActionButton";

import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../utils/generalFunctions";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import IconButton from "../../../common/icon_button/IconButton";

function Notes() {
  const [activeNoteId, setActiveNoteId] = React.useState(1);
  const [activeNoteData, setActiveNoteData] = React.useState({});
  const [deletePopup, setDeletePopup] = React.useState(0);
  const [mobileDesign, setMobileDesign] = React.useState(true);

  const [notes, setNotes] = useState([]);

  const [searchText, setSearchText] = useState("");
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const stateDataTableData = useSelector((state) => state.getTableData);

  const dispatch = useDispatch();
  const handleSearch = (text) => {
    setSearchText(text);
    // Add your search logic here, e.g., filter/search items based on 'text'
  };
  const [pageNumber, setPageNumber] = useState(1);
  const initialRender = useRef(true);

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData.id = item._id;
      mappedData.title = item.title;
      mappedData.date = formatDate(item.createdAt);
      mappedData.overview = item.shortContent;
      mappedDataList.push(mappedData);
    });

    console.log("Mapped Data", mappedDataList);

    setNotes(mappedDataList);

    // console.log("Processed Data", data, mappedData);
  };

  const deleteNote = async (id) => {
    dispatch(showLoader(true));
    const response = await postFormData({
      form_key: "ADMIN__NOTES__DELETE_NOTE",
      urlId: id,
      method: "delete",
    });
    console.log("Response", response);
    if (response) {
      setActiveNoteId(1);
      setActiveNoteData({});
      window.location.reload();
    }
    dispatch(showLoader(false));
  };

  const createAnewNote = async () => {
    dispatch(showLoader(true));
    let response = null;
    if (activeNoteId === 1) {
      response = await postFormData({
        form_key: "ADMIN__NOTES__ADD_NOTE",
        data: activeNoteData,
      });
      if (response.success === true) {
        window.location.reload();
      }
    } else {
      response = await postFormData({
        form_key: "ADMIN__NOTES__UPDATE_NOTE",
        data: activeNoteData,
        urlId: activeNoteId,
        method: "put",
      });
      if (response.success === true) {
        window.location.reload();
      }
    }
    console.log("Response", response);
    if (response) {
      // setActiveNoteId(response.id);
    }
    dispatch(showLoader(false));
  };

  useEffect(() => {
    // if seachText is not same as previous searchText then reset the pageNumber
    if (searchText !== "") {
      setPageNumber(1);
    }

    if (initialRender.current) {
      initialRender.current = false;
      dispatch(resetTableData());

      fetchTableData(dispatch, "ADMIN__NOTES__NOTES", pageNumber, searchText);
    }
  }, [pageNumber, searchText]);

  useEffect(() => {
    if (stateDataTableData.adminNotes?.length > 0)
      processTableData(stateDataTableData.adminNotes);
  }, [stateDataTableData.adminNotes]);

  const getNoteData = async (id) => {
    const response = await getSingleData({
      API_NAME: "ADMIN__NOTES__GET_ONE_NOTE",
      oneId: id,
    });

    setActiveNoteData(response);
  };

  useEffect(() => {
    if (activeNoteId !== 1) {
      getNoteData(activeNoteId);
    }
  }, [activeNoteId]);

  // Quill Editor

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const handleProcedureContentChange = (content) => {
    setActiveNoteData((prevState) => ({
      ...prevState,
      content,
    }));
  };

  return (
    <div className={styles.admin_notes_content}>
      <div className={styles.notes_container}>
        {/* <div className={styles.notes_list_container}> */}
        <div
          className={
            styles.notes_list +
            " " +
            (mobileDesign ? styles.mobile_design : styles.mobile_design_hide)
          }
        >
          <div className={styles.notes_list_header}>
            <div className={styles.title}>Notes</div>
            {/* <div className={styles.search}>
                <SearchField
                  extraStyle={styles.search}
                  onSearch={(value) => console.log(value)}
                />
              </div> */}
          </div>
          {notes.map((note, index) => (
            <div
              className={
                styles.notes_list_card +
                " " +
                (activeNoteId === note.id && styles.notes_list_card_active)
              }
              onClick={() => {
                setDeletePopup(0);
                setActiveNoteId(note.id);
                setMobileDesign(false);
              }}
              key={index}
            >
              <div className={styles.notes_list_item}>
                <div className={styles.row}>
                  <div className={styles.title}>{note.title}</div>
                  <div className={styles.date}>{note.date}</div>
                </div>
                {/* <div className={styles.overview}>{note.overview}</div> */}
              </div>
              <div className={styles.item_action}>
                <img
                  src={ThreeDotIcon}
                  className={styles.item_action_icon}
                  alt="action_button"
                  onClick={(e) => {
                    e.stopPropagation();
                    deletePopup === note.id
                      ? setDeletePopup(0)
                      : setDeletePopup(note.id);
                  }}
                />
                {deletePopup === note.id && (
                  <div
                    className={styles.delete_popup}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteNote(note.id);
                    }}
                  >
                    <img src={DeleteIcon} alt="Delete" />
                    <span>Delete</span>
                  </div>
                )}
              </div>
            </div>
          ))}
          <button
            className={styles.notes_list_footer_button}
            onClick={() => {
              setActiveNoteId(1);
              setActiveNoteData({});
              document.getElementById("note_title").value = "";
              setMobileDesign(false);
            }}
          >
            <img src={AddIcon} alt="Add Icon" />
          </button>
        </div>
        {/* </div> */}
        <div
          className={
            styles.text_editor_container +
            " " +
            (!mobileDesign ? styles.mobile_design : styles.mobile_design_hide)
          }
        >
          <div className={styles.header}>
            <div className={styles.title}>
              {/* <IconButton
                  icon={AddIcon}
                  onClick={() => {
                    setMobileDesign(true);
                  }}
                  
                /> */}
              <img
                src={BackArrowBlue}
                alt=""
                onClick={() => {
                  setMobileDesign(true);
                }}
              ></img>
              <input
                className={styles.title_input}
                type="text"
                id="note_title"
                value={activeNoteData?.title}
                placeholder="Enter Title...."
                onChange={(e) => {
                  setActiveNoteData((prevState) => ({
                    ...prevState,
                    title: e.target.value,
                  }));
                }}
              />
            </div>
            <ActionButton
              buttonText={activeNoteId === 1 ? "Create Note" : "Update Note"}
              icon={AddIcon}
              onClick={() => {
                createAnewNote();
              }}
            />
          </div>
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            value={activeNoteData?.content}
            placeholder="write your content ...."
            onChange={handleProcedureContentChange}
            style={{ height: "calc(100% - 150px)" }}
          ></ReactQuill>
        </div>
      </div>
    </div>
  );
}

export default Notes;
