import styles from "../../../styles/common/drop_down/DropDown.module.css";
import { useState } from "react";
import DropDown from "../../../assets/common/drop_down/arrow_down.svg";

const FormDropDown = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [state, setState] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSelect = (option) => {
    setSelectedOption(option); // Set the name property if it's an object
    props.selected(option);
  };

  return (
    <div
      className={
        selectedOption
          ? `${styles.select_active} ${styles.select}`
          : `${styles.select_inactive} ${styles.select}`
      }
      onClick={() => setState(!state)}
    >
      <div className={styles.main_secion}>
        {state ? (
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={`${styles.searchInput} ${
              props.type === "Approved" && styles.approved
            } ${props.type === "Declined" && styles.declined}`}
          />
        ) : (
          <span>
            {selectedOption.name || selectedOption || (
              <span className={styles.placeholder}>
                Please Select an option
              </span>
            )}
          </span>
        )}
        <img
          src={DropDown}
          alt=""
          className={state ? styles.drop_down_icon_active : ""}
          onClick={() => setState(!state)}
        ></img>
      </div>
      {state && (
        <div className={styles.option_wrap}>
          <div className={styles.option_wrap_inner}>
            {props.options.map((option, index) => (
              <span
                key={index}
                onClick={() => handleSelect(option)}
                className={styles.choices}
              >
                {option.name || option}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormDropDown;
