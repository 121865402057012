import styles from "../../../../styles/sections/tenant/payments/FormModal.module.css";
import CircleActive from "../../../../assets/common/back_drop/circle_active.svg";
import CircleInactive from "../../../../assets/common/back_drop/circle_inactive.svg";
import { useState } from "react";
import PaymentForm from "../../../common/payment_form/PaymentForm";

const FormModal = ({ state, active, subHeader }) => {
  const [sectionActive, setSectionActive] = useState("Share Paid Details");

  const paid_details_form_fields = [
    {
      for: "Rent",
      placeholder: "Rent",
      type: "DropDown",
      options: ["Rent", "Rent + Electricity", "Electricity", "Other"],
      value: "",
    },
    {
      for: "Payment",
      placeholder: "",
      type: "DropDown",
      options: ["Cash", "Card", "UPI"],
      value: "",
    },
    {
      for: "amount",
      placeholder: "Enter your amount",
      type: "number",
      value: "",
    },
    {
      for: "description",
      placeholder: "Description...",
      type: "text",
      value: "",
    },
    {
      for: "image",
      placeholder: "",
      type: "file",
      value: "",
    },
  ];

  const submitPaidDetails = (rent, payment, image) => {
    paid_details_form_fields.map((field) => {
      if (field.for === "Rent") field.value = rent;
      else if (field.for === "Payment") field.value = payment;
      else if (field.for === "image") field.value = image;
      return console.log(field.value);
    });
  };

  const pay_online_form_fields = [
    {
      for: "Rent",
      placeholder: "",
      type: "DropDown",
      options: ["Rent", "Rent + Electric", "Electricity", "Other"],
      value: "",
    },
    {
      for: "amount",
      placeholder: "Enter your amount",
      type: "number",
      value: "",
    },
    {
      for: "description",
      placeholder: "Description...",
      type: "text",
      value: "",
    },
  ];

  const handlePayRent = (rent) => {
    pay_online_form_fields.map((field) => {
      if (field.for === "Rent") field.value = rent;
      return console.log(field.value);
    });
  };

  return (
    <div className={styles.back_drop}>
      <div className={styles.modal}>
        <div className={styles.back_drop_navBar}>
          <span
          // className={styles.active}
          >
            {active}
          </span>

          <span onClick={() => state("Payment History")}>
            <button className={styles.close_handler}>X</button>
          </span>
        </div>
        {/* <div className={styles.form_navbar}>
          {subHeader.map((navLink, index) => {
            return (
              <span key={index} onClick={() => setSectionActive(navLink)}>
                <img
                  src={
                    navLink === sectionActive ? CircleActive : CircleInactive
                  }
                  alt=""
                ></img>
                {navLink}
              </span>
            );
          })}
        </div> */}
        {sectionActive === "Share Paid Details" && (
          <PaymentForm
            fields={paid_details_form_fields}
            handleSubmit={submitPaidDetails}
            buttonText={"Send for Approval"}
          />
        )}
        {sectionActive === "Pay Online" && (
          <PaymentForm
            fields={pay_online_form_fields}
            handleSubmit={handlePayRent}
            buttonText={"Pay Rent"}
          />
        )}
        <span className={styles.modal_footer}>
          Payment will only be visible in payment history once Admin approves it
        </span>
      </div>
    </div>
  );
};

export default FormModal;
