import { useState } from "react";
import styles from "../../../../styles/sections/tenant/request_history/RequestHistory.module.css";
import StatsTable from "../../../common/stats_table/StatsTable";
import RequestHistoryModal from "./RequestHistoryModal";

const RequestHistory = () => {
  const [sectionActive, setSectionActive] = useState("Request History");

  const request_statsTable_headers = [
    "Sr No.",
    "Request Date",
    "Request Type",
    "Status",
  ];

  const request_tableFieldMapping = [
    "sr_no",
    "request_date",
    "request_type",
    "status",
  ];

  const request_history_data = [
    {
      sr_no: "01",
      request_date: "19/9/2022",
      request_type: "Visit",
      status: "Approved",
    },
    {
      sr_no: "02",
      request_date: "19/9/2022",
      request_type: "Other",
      status: "Declined",
    },
    {
      sr_no: "03",
      request_date: "19/9/2022",
      request_type: "Extra Bed",
      status: "Waiting",
    },
    {
      sr_no: "04",
      request_date: "19/9/2022",
      request_type: "Family Visit",
      status: "Approved",
    },
    {
      sr_no: "05",
      request_date: "19/9/2022",
      request_type: "Other",
      status: "Declined",
    },
    {
      sr_no: "06",
      request_date: "19/9/2022",
      request_type: "Other",
      status: "Waiting",
    },
    {
      sr_no: "07",
      request_date: "19/9/2022",
      request_type: "Other",
      status: "Declined",
    },
    {
      sr_no: "08",
      request_date: "19/9/2022",
      request_type: "Other",
      status: "Waiting",
    },
    {
      sr_no: "09",
      request_date: "19/9/2022",
      request_type: "Other",
      status: "Waiting",
    },
  ];

  return (
    <div className={styles.request_history}>
      <div className={styles.header}>
        <span>Request History</span>
        <button
          onClick={() => setSectionActive("Approved")}
          className={styles.new_request}
        >
          New Request
        </button>
      </div>
      <StatsTable
        tableTitle={""}
        tableHeaders={request_statsTable_headers}
        tableData={request_history_data}
        tableFieldMapping={request_tableFieldMapping}
        state={setSectionActive}
      ></StatsTable>
      {sectionActive === "New Request" && (
        <RequestHistoryModal
          state={setSectionActive}
          active={sectionActive}
          header={["Request History", "New Request"]}
        />
      )}
      {sectionActive === "Approved" && (
        <RequestHistoryModal
          state={setSectionActive}
          active={sectionActive}
          header={["Request History", "Request Details"]}
        />
      )}
      {sectionActive === "Declined" && (
        <RequestHistoryModal
          state={setSectionActive}
          active={sectionActive}
          header={["Request History", "Request Details"]}
        />
      )}
      {sectionActive === "Waiting" && (
        <RequestHistoryModal
          state={setSectionActive}
          active={sectionActive}
          header={["Request History", "Request Details"]}
        />
      )}
    </div>
  );
};

export default RequestHistory;
