import Axios from "../axios";
import { updatePageStats } from "../redux/reducers/admin/PageStatsSlice";
import { showLoader } from "../redux/reducers/loaderSlice";
import { USER_TYPE, apiEndpointname } from "../utils/constants";

const getPageStats = async ({ statsKey }) => {
  let userType = localStorage.getItem("userType");

  let apiPath =
    userType === USER_TYPE.ADMIN
      ? apiEndpointname.EMPLOYEE_STATS
      : apiEndpointname.TENANT_STATS;

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "api/v1" + apiPath + `?statsKey=${statsKey}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  try {
    const response = await Axios.request(config);

    if (
      (response.status === 200 || response.status === 201) &&
      response.data.success === true
    ) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    console.log(
      "error getPageStats ",
      `${apiPath}?statsKey=${statsKey}`,
      error
    );
    // Check if the error has a response with a status code
    if (error.response && error.response.status === 701) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userType");
    }
    return [];
  }
};

const getInternalPageStats = async ({ statsKey, statsParameterId }) => {
  let userType = localStorage.getItem("userType");

  let apiPath =
    userType === USER_TYPE.ADMIN
      ? apiEndpointname.ADMIN_STATS
      : apiEndpointname.TENANT_STATS;

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "api/v1" + apiPath + `/${statsParameterId}?statsKey=${statsKey}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  try {
    const response = await Axios.request(config);

    if (
      (response.status === 200 || response.status === 201) &&
      response.data.success === true
    ) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    console.log(
      "error getInternalPageStats ",
      `${apiPath}/${statsParameterId}?statsKey=${statsKey}`,
      error
    );
    // Check if the error has a response with a status code
    if (error.response && error.response.status === 701) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userType");
    }
    return [];
  }
};

const fetchPageStats = async (dispatch, statsKey, statsParameterId) => {
  try {
    dispatch(showLoader(true));

    let response = "";

    if (statsParameterId) {
      response = await getInternalPageStats({
        statsKey: statsKey,
        statsParameterId: statsParameterId,
      });
    } else {
      response = await getPageStats({
        statsKey: statsKey,
      });
    }

    const responseData = response?.data?.data;

    dispatch(
      updatePageStats({
        statsKey: statsKey,
        statsData: responseData,
      })
    );

    dispatch(showLoader(false));
  } catch (err) {
    console.log("Error - fetchTableData - Parking", err);
    dispatch(showLoader(false));
  }
};

export { fetchPageStats };
