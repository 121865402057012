import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/car_parking/CarParking.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";

import TotalTenants from "../../../../assets/sections/admin/tenants/total_tenants.svg";
import TenantsPaid from "../../../../assets/sections/admin/tenants/tenants_paid.svg";

import EditIcon from "../../../../assets/common/edit.svg";
import deleteIcon from "../../../../assets/common/delete_red.svg";

import StatsBoard from "../../../common/statsboard/StatsBoard";
import StatsTable from "../../../common/stats_table/StatsTable";
import ActionButton from "../../../common/action_button/ActionButton";
import SearchField from "../../../common/search_field/SearchField";
import AddIcon from "../../../../assets/common/add_icon.svg";
import IconButton from "../../../common/icon_button/IconButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { STATS_KEYS } from "../../../../utils/constants";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { formatDate } from "../../../../utils/generalFunctions";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { debounce } from "lodash";
import { postFormData } from "../../../../generalApiCalls/postFormData";

const Invoices = () => {
  const stateDataTableData = useSelector((state) => state.getTableData);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const statePageStats = useSelector((state) => state.pageStats);
  const scroll = useSelector((state) => state.pageScroll);
  const [isSearchUsed, setIsSearchUsed] = useState(false);

  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialRender = useRef(true);

  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );

    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  // Original handleSearch function now calls the debounced version
  const handleSearch = (text) => {
    debouncedHandleSearch(text);
  };

  // Invoice Stats Board
  const stats_icons = [TotalTenants, TenantsPaid];

  const stats_names = ["Total Invoice", "Total Amount"];

  const invoice_stats_mapping = ["totalDocuments", "totalCharges"];

  // Invoice Stats Table
  const invoice_table_headers = [
    "Sr No.",
    "Date",
    "Customer Name",
    "Arrival Time",
    "Departure Time",
    "Amount",
    "Details",
    "Action",
  ];

  const invoice_tableFieldMapping = [
    "sr_no",
    "invoiceDate",
    "customerName",
    "arrivalTime",
    "departureTime",
    "amount",
    "details",
    "delete",
  ];

  const [invoiceTableData, setInvoiceTableData] = useState([]);

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};
      const amount = item.paymentData.reduce(
        (total, payment) => total + parseFloat(payment.charges),
        0
      );

      mappedData._id = item._id;
      mappedData.sr_no = index + 1;
      mappedData.invoiceDate = formatDate(item.invoiceDate);
      mappedData.customerName = item.customerName;
      mappedData.arrivalTime = formatDate(item.arrivalDate);
      mappedData.departureTime = formatDate(item.departureDate);
      mappedData.amount = amount;
      mappedData.details = (
        <ActionButton
          buttonText={"More Details"}
          onClick={() => {
            dispatch(
              updatePageNumber({
                pageNo: 1,
              })
            );
            navigate(`/admin/invoices/addInvoice/${item._id}`);
          }}
        ></ActionButton>
      );

      mappedData.delete = (
        <IconButton
          buttonIcon={deleteIcon}
          onClick={() => {
            deleteInvoice(item._id);
          }}
          whiteBg={true}
          transparentBg={true}
        ></IconButton>
      );

      mappedDataList.push(mappedData);
    });

    setInvoiceTableData(mappedDataList);
  };

  const deleteInvoice = async (id) => {
    dispatch(showLoader(true));
    if (window.confirm("Are you sure ? you want to delete invoice?")) {
      const response = await postFormData({
        form_key: "ADMIN__INVOICES__DELETE_INVOICE",
        urlId: id,
        method: "delete",
      });
      if (response) {
        window.location.reload();
      }
    }

    dispatch(showLoader(false));
  };

  useEffect(() => {
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__INVOICES__INVOICES",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.EMPLOYEE__INVOICE__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__INVOICES__INVOICES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__INVOICES__INVOICES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__INVOICES__INVOICES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__INVOICES__INVOICES",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.EMPLOYEE__INVOICE__STATS, "");
    }
  }, [scroll.pageNo, searchText]);

  useEffect(() => {
    if (stateDataTableData.adminInvoice?.length >= 0)
      processTableData(stateDataTableData.adminInvoice);
  }, [stateDataTableData.adminInvoice]);

  return (
    <>
      <StatsBoard
        boardName="Invoices"
        statIcons={stats_icons}
        statNames={stats_names}
        statValuesMapping={invoice_stats_mapping}
        statValues={statePageStats?.adminInvoiceStats}
      ></StatsBoard>

      <StatsTable
        tableTitle={"Invoices"}
        tableHeaders={invoice_table_headers}
        tableData={invoiceTableData}
        tableFieldMapping={invoice_tableFieldMapping}
        tableActionsElement={
          <div className={styles.parking_stats_table_actions}>
            <SearchField searchText={searchText} onSearch={handleSearch} />
            <ActionButton
              buttonText="Add New Invoice"
              icon={AddIcon}
              onClick={() => {
                navigate(`/admin/invoices/addInvoice`);
              }}
            />
          </div>
        }
        tableActionsElementMobile={
          <div
            className={
              statsStyles.stats_title_actions_container +
              " " +
              statsStyles.stats_title_actions_container_show
            }
          >
            <div className={statsStyles.statsTable_title_container}>
              <div className={statsStyles.stats_table_title}>Invoices</div>
              <div className={styles.units_stats_table_actions}>
                <IconButton
                  buttonText={"Add New Invoice"}
                  buttonIcon={AddIcon}
                  onClick={() => navigate("/admin/invoices/addInvoice")}
                ></IconButton>
              </div>
            </div>

            <SearchField searchText={searchText} onSearch={handleSearch} />
          </div>
        }
        totalPages={stateDataTotalPages.adminInvoiceTotalPages}
      ></StatsTable>
    </>
  );
};

export default Invoices;
