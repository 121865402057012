import React, { useEffect, useState } from "react";
import styles from "../../../../styles/sections/tenant/tenant_navbar/TenantNavigationBar.module.css";
import RentIqLogo from "../../../../assets/common/rentIq_logo.svg";
import RentIqLogoMobile from "../../../../assets/common/rentIq_logo_mobile.svg";
import expandIcon from "../../../../assets/sections/admin/sidebar/expand.svg";
import collapseIcon from "../../../../assets/sections/admin/sidebar/collapse.svg";
import DashboardIcon from "../../../../assets/sections/admin/sidebar/dashboard.svg";
import Unit from "../../../../assets/sections/admin/sidebar/unit.svg";
import Tenant from "../../../../assets/sections/admin/sidebar/tenant.svg";
import Approvals from "../../../../assets/sections/admin/sidebar/approvals.svg";
import Notes from "../../../../assets/sections/admin/sidebar/notes.svg";
import Cleaning from "../../../../assets/sections/admin/sidebar/cleaning.svg";
import CarParking from "../../../../assets/sections/admin/sidebar/car_parking.svg";
import InvoiceLogo from "../../../../assets/sections/admin/sidebar/invoice.svg";
import BroadcastLogo from "../../../../assets/sections/admin/sidebar/broadcast.svg";
import EmployeesLogo from "../../../../assets/sections/admin/sidebar/employees.svg";
import AnalyticsLogo from "../../../../assets/sections/admin/sidebar/analytics.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPage } from "../../../../redux/reducers/CurrentPageSlice";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";

const NavigationBar = ({ state, setState }) => {
  const location = useLocation();
  const stateData = useSelector((state) => state.currentPage);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dashboardItems = [
    {
      sectionName: "Dashboard",
      url: "/admin/dashboard",
      sectionIcon: DashboardIcon,
    },
    { sectionName: "Units", url: "/admin/units", sectionIcon: Unit },
    { sectionName: "Tenants", url: "/admin/tenants", sectionIcon: Tenant },
    {
      sectionName: "Approvals",
      url: "/admin/approvals",
      sectionIcon: Approvals,
    },
    { sectionName: "Notes", url: "/admin/notes", sectionIcon: Notes },
    { sectionName: "Cleaning", url: "/admin/cleaning", sectionIcon: Cleaning },
    // {
    //   sectionName: "Car Parking",
    //   url: "/admin/car_parking",
    //   sectionIcon: CarParking,
    // },
    {
      sectionName: "Invoices",
      url: "/admin/invoices",
      sectionIcon: InvoiceLogo,
    },
    {
      sectionName: "Broadcast",
      url: "/admin/broadcasts",
      sectionIcon: BroadcastLogo,
    },
    // {
    //   sectionName: "Employees",
    //   url: "/admin/employees",
    //   sectionIcon: EmployeesLogo,
    // },
    // {
    //   sectionName: "Analytics",
    //   url: "/admin/analytics",
    //   sectionIcon: AnalyticsLogo,
    // },
  ];

  const [collapsed, setCollapsed] = useState(true);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    dispatch(updateCurrentPage(location.pathname));
  }, [location]);

  return (
    <div
      className={`${
        collapsed
          ? styles.admin_home_sidebar_collpased
          : styles.admin_home_sidebar
      } ${state ? styles.admin_home_sidebar_mobile : ""}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {collapsed && !state ? (
        <img
          className={styles.loyal_partners_logo_icon}
          src={RentIqLogoMobile}
          alt="LoyalPartnersLogoMobile"
        ></img>
      ) : (
        <img
          className={styles.loyal_partners_logo}
          src={RentIqLogo}
          alt="LoyalPartnersLogo"
        ></img>
      )}

      {!collapsed || state ? (
        <div className={styles.admin_dashboard_wrapper}>
          {dashboardItems.map((item, index) => (
            <div
              className={
                styles.dashboard_section +
                " " +
                (stateData.activePage?.includes(item.url)
                  ? styles.dashboard_section_active
                  : "")
              }
              key={index}
              onClick={() => {
                dispatch(
                  updatePageNumber({
                    pageNo: 1,
                  })
                );
                navigate(item.url);
                dispatch(updateCurrentPage(item.url));
              }}
            >
              <img src={item.sectionIcon} alt={item.sectionIcon}></img>
              <span>{item.sectionName}</span>
            </div>
          ))}
          <div
            className={styles.dashboard_section}
            onClick={() => {
              setCollapsed(!collapsed);
              state && setState(!state);
            }}
          >
            <img src={collapseIcon} alt={"collapseIcon"}></img>
            <span>Collapse Sidebar</span>
          </div>
        </div>
      ) : (
        <div className={styles.admin_dashboard_wrapper_collapsed}>
          {dashboardItems.map((item, index) => (
            <div
              className={
                styles.dashboard_section +
                " " +
                (stateData.activePage?.includes(item.url)
                  ? styles.dashboard_section_active
                  : "")
              }
              key={index}
              onClick={(event) => {
                event.preventDefault();
                dispatch(
                  updatePageNumber({
                    pageNo: 1,
                  })
                );
                navigate(item.url);
                dispatch(updateCurrentPage(item.url));
              }}
            >
              <img src={item.sectionIcon} alt={item.sectionIcon}></img>
            </div>
          ))}
          <div
            className={
              styles.dashboard_section + " " + styles.navbar_collapse_button
            }
            onClick={toggleSidebar}
          >
            <img src={expandIcon} alt={"expandIcon"}></img>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationBar;
