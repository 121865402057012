import styles from "../../../../styles/sections/admin/car_parking/CarParkingForm.module.css";
import { useState } from "react";
import FormDropDown from "../../../common/form_dropdown/FormDropDown";
import ActionButton from "../../../common/action_button/ActionButton";
import AddIcon from "../../../../assets/common/add_icon.svg";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { contractFormValidationSchema } from "../../../../utils/validators";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const SendContractForm = ({ setSendContractForm, tenantId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let form_fields = [
    {
      field_name: "Agreement File",
      field_id: "asset",
      placeholder: "Upload Contract File",
      type: "file",
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return form_fields.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newPayment = {
      tenantId: tenantId,
    };
    formFields.map((item) => {
      newPayment[item.field_id] = item.value;
    });

    newPayment = cleanFormObject(newPayment);

    // Validate the form data
    const { error } = contractFormValidationSchema.validate(newPayment);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // add
      response = await postFormData({
        form_key: "ADMIN__TENANTS__SEND_CONTRACT",
        data: newPayment,
        isFile: true,
      });

      if (response.success) {
        // navigate("/tenant/payment");
        window.location.reload();
        // alert(response.message);
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  return (
    <div className={styles.form_modal}>
      <form className={styles.form_container}>
        <div className={styles.header}>
          <div className={styles.title}>Agreement Sharing</div>
          <button
            onClick={(e) => {
              e.preventDefault();
              setSendContractForm(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>
        </div>
        <ProfileUpdateForm
          form_fields={form_fields}
          handleFieldChange={handleFieldChange}
          submit="Send Agreement"
          formHandler={formHandler}
        />
      </form>
    </div>
  );
};

export default SendContractForm;
