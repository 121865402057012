import styles from "../../../styles/common/status_button/StatusButton.module.css";

const StatusButton = ({ buttonText, buttonImage, click }) => {
  return (
    <div
      className={`${styles.status_button} ${
        buttonText === "Declined" && styles.declined
      } ${buttonText === "Waiting" && styles.waiting}`}
    >
      <span
        className={`${styles.button_text} ${
          buttonText === "Declined" && styles.button_text_declined
        } ${buttonText === "Waiting" && styles.button_text_waiting}`}
      >
        {buttonText}
      </span>
      <hr
        className={`${styles.horizontal_line} ${
          buttonText === "Declined" && styles.declined
        } ${buttonText === "Waiting" && styles.waiting}`}
      ></hr>
      <img src={buttonImage} alt="" onClick={() => click(buttonText)}></img>
    </div>
  );
};

export default StatusButton;
