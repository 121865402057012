import React, { useState } from "react";
import styles from "../../../../styles/sections/tenant/tenant_navbar/TenantNavigationBar.module.css";
import RentIqLogo from "../../../../assets/common/rentIq_logo.svg";
import RentIqLogoMobile from "../../../../assets/common/rentIq_logo_mobile.svg";
import DashboardCollapse from "../../../../assets/sections/tenant/tenant_navbar/collapse.svg";
import DashboardExpand from "../../../../assets/sections/tenant/tenant_navbar/expand.svg";
import HomeIcon from "../../../../assets/sections/tenant/tenant_navbar/home.svg";
import PaymentIcon from "../../../../assets/sections/tenant/tenant_navbar/payments.svg";
import RequestIcon from "../../../../assets/sections/tenant/tenant_navbar/request.svg";
import SupportIcon from "../../../../assets/sections/tenant/tenant_navbar/support.svg";
import { useNavigate } from "react-router-dom";

const TenantNavigationBar = ({ state, setState }) => {
  const [currentSection, setCurrentSection] = useState("Home");
  const navigate = useNavigate();

  const dashboardItems = [
    {
      sectionName: "Home",
      url: "/tenant/home",
      sectionIcon: HomeIcon,
    },
    {
      sectionName: "Payment",
      url: "/tenant/payment",
      sectionIcon: PaymentIcon,
    },
    {
      sectionName: "Request",
      url: "/tenant/Request",
      sectionIcon: RequestIcon,
    },
    {
      sectionName: "Support and Help",
      url: "/tenant/support",
      sectionIcon: SupportIcon,
    },
  ];

  const [collapsed, setCollapsed] = useState(true);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={`${
        collapsed
          ? styles.admin_home_sidebar_collpased
          : styles.admin_home_sidebar
      } ${state ? styles.admin_home_sidebar_mobile : ""}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {collapsed && !state ? (
        <img
          className={styles.loyal_partners_logo_icon}
          src={RentIqLogoMobile}
          alt="LoyalPartnersLogoMobile"
        ></img>
      ) : (
        <img
          className={styles.loyal_partners_logo}
          src={RentIqLogo}
          alt="LoyalPartnersLogo"
        ></img>
      )}

      {!collapsed || state ? (
        <div className={styles.admin_dashboard_wrapper}>
          {dashboardItems.map((item, index) => (
            <div
              className={
                styles.dashboard_section +
                " " +
                (currentSection === item.sectionName
                  ? styles.dashboard_section_active
                  : "")
              }
              key={index}
              onClick={() => {
                navigate(item.url);
                setCurrentSection(item.sectionName);
                state && setState(!state);
              }}
            >
              <img src={item.sectionIcon} alt={item.sectionIcon}></img>
              <span>{item.sectionName}</span>
            </div>
          ))}
          <div
            className={styles.dashboard_section}
            onClick={() => {
              setCollapsed(!collapsed);
              state && setState(!state);
            }}
          >
            <img src={DashboardCollapse} alt={DashboardCollapse}></img>
            <span>Collapse Sidebar</span>
          </div>
        </div>
      ) : (
        <div className={styles.admin_dashboard_wrapper_collapsed}>
          {dashboardItems.map((item, index) => (
            <div
              className={
                styles.dashboard_section +
                " " +
                (currentSection === item.sectionName
                  ? styles.dashboard_section_active
                  : "")
              }
              key={index}
              onClick={(event) => {
                event.preventDefault();
                navigate(item.url);
                setCurrentSection(item.sectionName);
              }}
            >
              <img src={item.sectionIcon} alt={item.sectionIcon}></img>
            </div>
          ))}
          <div
            className={
              styles.dashboard_section + " " + styles.navbar_collapse_button
            }
            onClick={(e) => {
              toggleSidebar();
            }}
          >
            <img src={DashboardExpand} alt={"Dashboard Expand"}></img>
          </div>
        </div>
      )}
    </div>
  );
};

export default TenantNavigationBar;
