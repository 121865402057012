import React, { useEffect, useRef } from "react";
import NavigationBar from "./navigation_bar/NavigationBar";
import styles from "../../../styles/sections/admin/Admin.module.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../common/header/Header";

// routes
import Dashboard from "./dashboard/Dashboard";
import Notes from "./notes/Notes";
import UnitsRoutes from "./units/UnitsRoutes";
import { updatePageNumber } from "../../../redux/reducers/PageScrollSlice";
import TenantsRoutes from "./tenants/TenantsRoutes";
import CleaningRoutes from "./cleaning/CleaningRoutes";
import InvoiceRoutes from "./invoices/InvoiceRoutes";
import BroadcastRoutes from "./broadcasts/BroadcastRoutes";
import Profile from "../admin/profile/Profile";
import ApprovalsRoutes from "./approvals/ApprovalsRoutes";

function Admin() {
  const stateDataTotalPages = useSelector((state) => state.totalPages);

  useEffect(() => {
    // document.title = "Receipt";
  }, []);

  const adminFeatures = [
    {
      sectionName: "Dashboard",
      url: "/dashboard",
      component: <Dashboard />,
    },
    {
      sectionName: "Units",
      url: "/units/*",
      component: <UnitsRoutes />,
    },
    {
      sectionName: "Tenants",
      url: "/tenants/*",
      component: <TenantsRoutes />,
    },
    {
      sectionName: "Approvals",
      url: "/approvals/*",
      component: <ApprovalsRoutes />,
    },
    {
      sectionName: "Notes",
      url: "/notes",
      component: <Notes />,
    },
    {
      sectionName: "Cleaning",
      url: "/cleaning/*",
      component: <CleaningRoutes />,
    },
    {
      sectionName: "Invoices",
      url: "/invoices/*",
      component: <InvoiceRoutes />,
    },
    {
      sectionName: "Broadcasts",
      url: "/broadcasts/*",
      component: <BroadcastRoutes />,
    },
    {
      sectionName: "Profile",
      url: "/profile",
      component: <Profile />,
    },
  ];

  const containerRef = useRef(null);
  const dispatch = useDispatch();

  const scroll = useSelector((state) => state.pageScroll);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      if (
        // if less than 2px then scrolled to the bottom
        container.scrollTop + container.clientHeight + 2 >=
        container.scrollHeight
      ) {
        // User has scrolled to the bottom
        console.log("I reached last, calling the next API");
        // You can call your next API here
        if (scroll.pageNo < stateDataTotalPages.adminUnitsTotalPages) {
          console.log("I am calling the next API");
          dispatch(
            updatePageNumber({
              pageNo: scroll.pageNo + 1,
            })
          );
        }
      }
    }
  };

  const RediredtToHome = () => {
    return <Navigate to="/admin/dashboard" />;
  };

  return (
    <div
      className={styles.admin_dashboard}
      onScroll={handleScroll}
      ref={containerRef}
    >
      <NavigationBar></NavigationBar>
      <div className={styles.header_mainContent}>
        <Header></Header>
        <Routes>
          <Route path="/" element={<RediredtToHome />} />

          {adminFeatures.map((feature, index) => {
            return (
              <Route
                key={index}
                path={feature.url}
                element={feature.component}
              />
            );
          })}
        </Routes>
      </div>
    </div>
  );
}

export default Admin;
