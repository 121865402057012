import { configureStore } from "@reduxjs/toolkit";
import currentPageReducer from "./reducers/CurrentPageSlice";
import getTableDataReducer from "./reducers/admin/GetTableDataSlice";
import loaderReducer from "./reducers/loaderSlice";
import alertReducer from "./reducers/AlertSlice";
import totalPagesReducer from "./reducers/admin/TotalPagesSlice";
import updatePageStatsReducer from "./reducers/admin/PageStatsSlice";
import pageScrollReducer from "./reducers/PageScrollSlice";
import userInfoReducer from "./reducers/UserSlicer";
import broadCastReducer from "./reducers/admin/BroadcastsSlice";
import plotsReducer from "./reducers/admin/PlotsSlice";

export const store = configureStore({
  reducer: {
    currentPage: currentPageReducer,
    getTableData: getTableDataReducer,
    loader: loaderReducer,
    totalPages: totalPagesReducer,
    pageStats: updatePageStatsReducer,
    pageScroll: pageScrollReducer,
    userInfo: userInfoReducer,
    broadCastInfo: broadCastReducer,
    alert: alertReducer,
    plots: plotsReducer,
  },
});
