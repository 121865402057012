import Axios from "../axios";
import { updateTableData } from "../redux/reducers/admin/GetTableDataSlice";
import { updateTotalPages } from "../redux/reducers/admin/TotalPagesSlice";
import { showLoader } from "../redux/reducers/loaderSlice";
import { apiEndpointname, tableKey } from "../utils/constants";

const getApiEndpoint = (currentTableKey) => {
  switch (currentTableKey) {
    case tableKey.ADMIN__UNITS__ALL_UNITS:
      return apiEndpointname.ADMIN__UNITS__GET_ALL_UNITS;
    case tableKey.ADMIN__TENANTS__TENANTS:
      return apiEndpointname.ADMIN__TENANTS__GET_ALL_TENANTS;
    case tableKey.ADMIN__APPROVALS__APPROVALS:
      return apiEndpointname.ADMIN__APPROVALS__GETALL__APPROVALS;
    case tableKey.ADMIN__NOTES__NOTES:
      return apiEndpointname.ADMIN__NOTES__GET_ALL_NOTE;
    case tableKey.ADMIN__MESSAGES__MESSAGES:
      return apiEndpointname.ADMIN__MESSAGES__GET_ALL_MESSAGES;
    case tableKey.ADMIN__CLEANING__CLEANING:
      return apiEndpointname.ADMIN__CLEANING__GET_ALL_UNITS;
    case tableKey.ADMIN__INVOICES__INVOICES:
      return apiEndpointname.ADMIN__INVOICES__GET_ALL_INVOICE;
    case tableKey.ADMIN__CLEANING__CLEANER:
      return apiEndpointname.ADMIN__CLEANING__GET_ALL_CLEANERS;
    case tableKey.ADMIN__CAR_PARKING__CAR_PARKING:
      return apiEndpointname.ADMIN__PARKING__GET_ALL_CAR_PARKING;
    case tableKey.TENANT__PAYMENT_HISTORY:
      return apiEndpointname.TENANT__GET_TENANT_PAYMENT_HISTORY;
    default:
      // Handle the default case if needed
      break;
  }
};

const getTableData = async ({
  currentTableKey,
  page,
  search = "",
  limit = 30,
  apiEndpointKey = "",
  additionalData = {},
}) => {
  let data = {
    page: page,
    limit: limit,
  };

  if (Object.keys(additionalData).length > 0) {
    data = { ...data, ...additionalData };
  }

  if (search !== "") {
    data.search = search;
  }

  let apiPath = "";

  if (apiEndpointKey.length > 0) {
    apiPath = apiEndpointname[apiEndpointKey];
  } else {
    apiPath = getApiEndpoint(currentTableKey);
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "api/v1" + apiPath,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
    params: data,
  };

  try {
    const response = await Axios.request(config);

    if (
      (response.status === 200 || response.status === 201) &&
      response.data.success === true
    ) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error getTableData ", apiPath, error);

    // Check if the error has a response with a status code
    if (error.response && error.response.status === 701) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userType");
    }

    return [];
  }
};

const fetchTableData = async (
  dispatch,
  table_key,
  pageNumber,
  searchText,
  additionalData
) => {
  try {
    console.log(
      "fetchTableData",
      table_key,
      pageNumber,
      searchText,
      additionalData
    );

    dispatch(showLoader(true));
    let response = await getTableData({
      currentTableKey: tableKey[table_key],
      page: pageNumber,
      search: searchText,
      additionalData: additionalData,
    });

    const responseData = response?.data?.data;

    dispatch(
      updateTotalPages({
        currentTableKey: tableKey[table_key],
        currentTotalPages: response?.data?.totalPages,
      })
    );

    dispatch(
      updateTableData({
        currentTableKey: tableKey[table_key],
        currentTableData: responseData || [],
      })
    );
    dispatch(showLoader(false));
  } catch (err) {
    console.log("Error - fetchTableData", err);
    dispatch(showLoader(false));
  }
};

export { getTableData, fetchTableData };
