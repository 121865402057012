import { createSlice } from "@reduxjs/toolkit";
import { tableKey } from "../../../utils/constants";

const initialState = {
  adminUnits: [],
  adminTenants: [],
  adminApprovals: [],
  adminNotes: [],
  adminMessages: [],
  adminInvoice: [],
  adminCleaning: [],
  cleanCleaner: [],
  adminCarParking: [],
  tenantPaymentHistory: [],
};

const getTableDataSlice = createSlice({
  name: "getTableData",
  initialState,
  reducers: {
    updateTableData: (state, action) => {
      const { currentTableKey, currentTableData } = action.payload;

      const isDuplicate = (existingItem, newItem) =>
        existingItem._id === newItem._id;

      switch (currentTableKey) {
        case tableKey.ADMIN__UNITS__ALL_UNITS:
          state.adminUnits = [
            ...state.adminUnits.filter(
              (item) =>
                !currentTableData.some((newItem) => isDuplicate(item, newItem))
            ),
            ...currentTableData,
          ];
          break;
        case tableKey.ADMIN__TENANTS__TENANTS:
          state.adminTenants = [
            ...state.adminTenants.filter(
              (item) =>
                !currentTableData.some((newItem) => isDuplicate(item, newItem))
            ),
            ...currentTableData,
          ];
          break;
        case tableKey.ADMIN__APPROVALS__APPROVALS:
          state.adminApprovals = [
            ...state.adminApprovals.filter(
              (item) =>
                !currentTableData.some((newItem) => isDuplicate(item, newItem))
            ),
            ...currentTableData,
          ];
          break;
        case tableKey.ADMIN__NOTES__NOTES:
          state.adminNotes = [
            ...state.adminNotes.filter(
              (item) =>
                !currentTableData.some((newItem) => isDuplicate(item, newItem))
            ),
            ...currentTableData,
          ];
          break;
        case tableKey.ADMIN__MESSAGES__MESSAGES:
          state.adminMessages = [
            ...state.adminMessages.filter(
              (item) =>
                !currentTableData.some((newItem) => isDuplicate(item, newItem))
            ),
            ...currentTableData,
          ];
          break;
        case tableKey.ADMIN__INVOICES__INVOICES:
          state.adminInvoice = [
            ...state.adminInvoice.filter(
              (item) =>
                !currentTableData.some((newItem) => isDuplicate(item, newItem))
            ),
            ...currentTableData,
          ];
          break;
        case tableKey.ADMIN__CLEANING__CLEANING:
          state.adminCleaning = [
            ...state.adminCleaning.filter(
              (item) =>
                !currentTableData.some((newItem) => isDuplicate(item, newItem))
            ),
            ...currentTableData,
          ];
          break;
        case tableKey.ADMIN__CLEANING__CLEANER:
          state.cleanCleaner = [
            ...state.cleanCleaner.filter(
              (item) =>
                !currentTableData.some((newItem) => isDuplicate(item, newItem))
            ),
            ...currentTableData,
          ];
          break;
        case tableKey.TENANT__PAYMENT_HISTORY:
          state.tenantPaymentHistory = [
            ...state.tenantPaymentHistory.filter(
              (item) =>
                !currentTableData.some((newItem) => isDuplicate(item, newItem))
            ),
            ...currentTableData,
          ];
          break;
        case tableKey.ADMIN__CAR_PARKING__CAR_PARKING:
          state.adminCarParking = [
            ...state.adminCarParking.filter(
              (item) =>
                !currentTableData.some((newItem) => isDuplicate(item, newItem))
            ),
            ...currentTableData,
          ];
          break;
        default:
          // Handle the default case if needed
          break;
      }
    },

    resetTableData: (state, action) => {
      state.adminUnits = [];
      state.adminTenants = [];
      state.adminApprovals = [];
      state.adminNotes = [];
      state.adminInvoice = [];
      state.adminCleaning = [];
      state.cleanCleaner = [];
      state.adminCarParking = [];
      state.tenantPaymentHistory = [];
    },
  },
});

export const { updateTableData, resetTableData } = getTableDataSlice.actions;
export default getTableDataSlice.reducer;
