import styles from "../../../../styles/sections/admin/tenants/TenantDetails.module.css";
import { useEffect, useState } from "react";

import RentStatus from "../../../../assets/sections/admin/tenant_details/rent_status.svg";
import LatestPaymentDate from "../../../../assets/sections/admin/tenant_details/latest_payment_date.svg";
import NextDueDate from "../../../../assets/sections/admin/tenant_details/next_due_date.svg";
import StayDuration from "../../../../assets/sections/admin/tenant_details/stay_duration.svg";
import EndDate from "../../../../assets/sections/admin/tenant_details/end_date.svg";
import StatsBoard from "../../../common/statsboard/StatsBoard";
import NavBar from "../../../common/nav_bar/NavBar";
import CustomTableV2 from "../../../common/custom_table_v2/CustomTableV2";
import StatsTable from "../../../common/stats_table/StatsTable";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import EditLogo from "../../../../assets/common/edit.svg";

import PhotoId from "../../../../assets/sections/tenant/profile/photo_id.svg";
import AddressInfo from "../../../../assets/sections/tenant/profile/address_proof.svg";
import TaxInfo from "../../../../assets/sections/tenant/profile/tax_information.svg";

import { useNavigate, useParams } from "react-router-dom";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, getKeyByValue } from "../../../../utils/generalFunctions";
import FileViewer from "../../../common/image_verification/FileViewer";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS } from "../../../../utils/constants";
import ActionButton from "../../../common/action_button/ActionButton";
import SendContractForm from "./SendContractForm";
import AddPaymentRecordForm from "./AddPaymentRecordForm";
import { PaymentInvoice } from "./PaymentInvoice/PaymentInvoice";
import { createPortal } from "react-dom";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { setAlert } from "../../../../redux/reducers/AlertSlice";
import DetailsTable from "../../../common/details_table/DetailsTable";

const TenantDetails = () => {
  const statePageStats = useSelector((state) => state.pageStats);
  const stateDataTotalPages = useSelector((state) => state.totalPages);

  const navSections = ["Tenant Details", "Payment History", "Documents"];
  const sectionIdToName = {
    tenant_details: "Tenant Details",
    payment_history: "Payment History",
    documents: "Documents",
  };
  const [sendContractForm, setSendContractForm] = useState(false);
  const [addPaymentRecordForm, setAddPaymentRecordForm] = useState(false);
  const [onboarded, setOnboarded] = useState(true);

  const navigate = useNavigate();
  const tenantId = useParams().tenantId;
  const sectionId = useParams().sectionId;

  const [section, setSection] = useState("Tenant Details");
  const dispatch = useDispatch();

  //stats board data

  const tenant_icons = [
    RentStatus,
    LatestPaymentDate,
    NextDueDate,
    StayDuration,
    EndDate,
  ];

  const tenant_details = [
    "Rent Status",
    "Latest Payment Date",
    "Next Due Date",
    "Stay Duration",
    "End Date",
  ];

  const tenants_stats_mapping = [
    "rentStatus",
    "latestPaymentDate",
    "nextDueDate",
    "stayDuration",
    "endDate",
  ];

  const [tenantDetails, setTenantDetails] = useState([
    {
      data_type: "Tenant Name",
      data_value_type: "text",
      value: "",
      id: "tenantName",
    },
    {
      data_type: "Is Active",
      data_value_type: "boolean",
      value: "",
      id: "isActive",
    },
    {
      data_type: "Building",
      data_value_type: "text",
      value: "",
      id: "unitName",
    },
    {
      data_type: "Flat No",
      data_value_type: "text",
      value: "",
      id: "flatNo",
    },
    {
      data_type: "Email",
      data_value_type: "email",
      value: "",
      id: "email",
    },
    {
      data_type: "Mobile",
      data_value_type: "number",
      value: "",
      id: "mobile",
    },
    {
      data_type: "Whatsapp",
      data_value_type: "number",
      value: "",
      id: "whatsapp",
    },
    // {
    //   data_type: "Emergency Contact",
    //   data_value_type: "number",
    //   value: "",
    //   id: "emergencyContact",
    // },
    {
      data_type: "Rent Amount (Monthly)",
      data_value_type: "currency",
      value: "",
      id: "monthlyRent",
    },
    {
      data_type: "Security Deposit",
      data_value_type: "currency",
      value: "",
      id: "bondAmount",
    },
    {
      data_type: "Increment Percentage",
      data_value_type: "text",
      value: "",
      id: "incrementPercent",
    },
    {
      data_type: "Start Date",
      data_value_type: "date",
      value: "",
      id: "startDate",
    },
    {
      data_type: "End Date",
      data_value_type: "date",
      value: "",
      id: "endDate",
    },
    {
      data_type: "Tentative Duration (Months)",
      data_value_type: "text",
      value: "",
      id: "tentativeStayDuration",
    },

    // {
    //   data_type: "Company",
    //   data_value_type: "text",
    //   value: "",
    //   id: "companyName",
    // },
    // {
    //   data_type: "Country",
    //   data_value_type: "text",
    //   value: "",
    //   id: "country",
    // },
    // {
    //   data_type: "State",
    //   data_value_type: "text",
    //   value: "",
    //   id: "state",
    // },
    {
      data_type: "Permanent Address",
      data_value_type: "text",
      value: "",
      id: "permanentAddress",
    },
  ]);

  const [emergencyContactDetails, setEmergencyContactDetails] = useState([
    {
      data_type: "Name",
      data_value_type: "text",
      value: "",
      id: "name",
    },
    {
      data_type: "relationship",
      data_value_type: "text",
      value: "",
      id: "relation",
    },
    {
      data_type: "Permanent Address",
      data_value_type: "text",
      value: "",
      id: "permanentAddress",
    },
    {
      data_type: "Mobile Number",
      data_value_type: "number",
      value: "",
      id: "mobile",
    },
    {
      data_type: "Whatsapp Number",
      data_value_type: "number",
      value: "",
      id: "whatsapp",
    },
  ]);

  //payment history

  const dues_statsTable_headers = [
    "Sr No.",
    "Payment Category",
    "Payment Date",
    "Paid Amount",
    "Balance Amount",
    "Payment Mode",
    "Description",
  ];

  const dues_tableFieldMapping = [
    "sr_no",
    "payment_category",
    "payment_date",
    "paid_amount",
    "balance_amount",
    "payment_mode",
    "description",
  ];

  // const [documents, setDocuments] = useState([
  //   {
  //     field_name: "Original Agreement",
  //     field_id: "contractUrl",
  //     type: "file",
  //     fileName: "No File uploaded",
  //     fileId: "",
  //   },
  //   {
  //     field_name: "Signed Agreement",
  //     field_id: "signedContractUrl",
  //     type: "file",
  //     fileName: "No File uploaded",
  //     fileId: "",
  //   },
  // ]);

  const [photo_id_details, set_photo_id_details] = useState([
    {
      field_name: "Type of Document",
      value: "",
      id: "documentType",
    },
    {
      field_name: "Document Id",
      value: "",
      id: "documentId",
    },
    {
      field_name: "Issue Place",
      value: "",
      id: "issuerPlace",
    },
    {
      field_name: "Issuer",
      value: "",
      id: "issuer",
    },
    {
      field_name: "Valid up to",
      value: "",
      id: "validUpto",
    },
    {
      field_name: "Document Proof",
      value: "",
      id: "asset",
      type: "file",
    },
  ]);

  const [address_proof_details, set_address_proof_details] = useState([
    {
      field_name: "Type of Document",
      value: "",
      id: "documentType",
    },
    {
      field_name: "Document Id",
      value: "",
      id: "documentId",
    },
    {
      field_name: "Issue Place",
      value: "",
      id: "issuerPlace",
    },
    {
      field_name: "Issuer",
      value: "",
      id: "issuer",
    },
    {
      field_name: "Valid up to",
      value: "",
      id: "validUpto",
    },
    {
      field_name: "Document Proof",
      value: "",
      id: "asset",
      type: "file",
    },
  ]);
  const [tax_info, set_tax_info] = useState([
    {
      field_name: "Type of Document",
      value: "",
      id: "documentType",
    },
    {
      field_name: "Document Id",
      value: "",
      id: "documentId",
    },
    {
      field_name: "Issue Place",
      value: "",
      id: "issuerPlace",
    },
    {
      field_name: "Issuer",
      value: "",
      id: "issuer",
    },
    {
      field_name: "Valid up to",
      value: "",
      id: "validUpto",
    },
    {
      field_name: "Document Proof",
      value: "",
      id: "asset",
      type: "file",
    },
  ]);

  const [agreement_info, set_agreement_info] = useState([
    {
      field_name: "Type of Document",
      value: "Agreement",
      id: "documentType",
      editable: false,
    },
    {
      field_name: "Document Id",
      value: "NA",
      id: "documentId",
      editable: false,
    },
    {
      field_name: "Issue Place",
      value: "",
      id: "issuerPlace",
    },
    {
      field_name: "Issuer",
      value: "",
      id: "issuer",
    },
    {
      field_name: "Valid up to",
      value: "",
      id: "validUpto",
    },
    {
      field_name: "Document Proof",
      value: "",
      id: "asset",
      type: "file",
    },
  ]);

  const [paymentHistoryData, setPaymentHistoryData] = useState([]);

  const handleGetMoreDetails = async (unitId) => {
    dispatch(showLoader(true));

    const response = await getSingleData({
      API_NAME: "ADMIN__TENANTS__GET_TENANT_DETAILS",
      oneId: unitId,
    });

    setTenantDetails((prev) => {
      return prev.map((item) => {
        if (item.id in response) {
          if (item.data_value_type === "boolean") {
            return { ...item, value: response[item.id] ? "Yes" : "No" };
          }
          return { ...item, value: response[item.id] };
        } else {
          return item;
        }
      });
    });

    setEmergencyContactDetails((prev) => {
      return prev.map((item) => {
        if (response.emergencyContact && item.id in response.emergencyContact) {
          return {
            ...item,
            value: response.emergencyContact[item.id],
          };
        }
        return item;
      });
    });

    setOnboarded(response.onboarded);

    // // fetching documents details
    // documents.map(async (item) => {
    //   if (item.field_id in response) {
    //     setDocuments((prev) => {
    //       return prev.map((item) => {
    //         if (item.field_id in response) {
    //           return {
    //             ...item,
    //             fileName: response[item.field_id].originalName,
    //             fileId: response[item.field_id].assetId,
    //           };
    //         } else {
    //           return item;
    //         }
    //       });
    //     });
    //   }
    // });

    const responsePayment = await getSingleData({
      API_NAME: "ADMIN__TENANTS__GET_TENANT_PAYMENT_HISTORY",
      oneId: unitId,
      additionData: {
        limit: 5000,
      },
    });

    setPaymentHistoryData(
      responsePayment.map((item, index) => {
        return {
          sr_no: index + 1,
          payment_category: item.paymentCategory,
          payment_date: formatDate(item.actualPaymentDate),
          paid_amount: item.paymentAmount,
          balance_amount: item.balance,
          payment_mode: item.paymentMode,
          description: item.description,
        };
      })
    );

    const documents = await getSingleData({
      API_NAME: "ADMIN_DOCUMENTS_GET",
      additionData: {
        tenantId: tenantId,
      },
    });

    // console.log("documents", documents);

    if (documents) {
      documents?.map((item) => {
        let setterFunction = null;
        if (item?.proofType === "Photo ID") {
          setterFunction = set_photo_id_details;
        }
        if (item?.proofType === "Address Proof") {
          setterFunction = set_address_proof_details;
        }
        if (item?.proofType === "Tax Information") {
          setterFunction = set_tax_info;
        }
        if (item?.proofType === "Agreement Info") {
          setterFunction = set_agreement_info;
        }

        if (setterFunction) {
          setterFunction((prev) => {
            return prev.map((item1) => {
              if (item1.id === "asset") {
                if (item?.originalDocument) {
                  item.originalDocument.tenantId = tenantId;
                }
                if (item?.signedDocument) {
                  item.signedDocument.tenantId = tenantId;
                }
                return {
                  ...item1,
                  originalDocument: item?.originalDocument,
                  signedDocument: item?.signedDocument,
                };
              } else if (item1.id in item) {
                return { ...item1, value: item[item1.id] };
              } else {
                return item1;
              }
            });
          });
        }

        console.log(photo_id_details);
      });
    }

    dispatch(showLoader(false));
  };
  useEffect(() => {
    if (section) {
      let sectionIdToRedirect = getKeyByValue(sectionIdToName, section);
      navigate("/admin/tenants/" + tenantId + "/" + sectionIdToRedirect);
    }
  }, [section]);

  useEffect(() => {
    if (sectionId) {
      setSection(sectionIdToName[sectionId]);
    }

    handleGetMoreDetails(tenantId);
    fetchPageStats(
      dispatch,
      STATS_KEYS.EMPLOYEE__TENANTS__STATS_INTERNAL,
      tenantId
    );
  }, [tenantId]);

  const [printStart, setPrintStart] = useState(false);

  const Print = () => {
    setPrintStart(true);
    setTimeout(() => {
      document.getElementById("root").style.display = "none";
      window.print();
      document.getElementById("root").style.display = "block";
      setPrintStart(false);
    }, 500);
  };

  const resendOnBoardingEmail = async () => {
    dispatch(showLoader(true));

    let data = {
      tenantId: tenantId,
    };

    const response = await postFormData({
      form_key: "ADMIN__TENANTS__RESEND_ONBOARDING_EMAIL",
      data: data,
    });

    if (response.success === true) {
      dispatch(
        setAlert({
          alertType: "success",
          alertMessage: response.message,
          alertTitle: "Success",
          autoClose: false,
          alertShow: true,
        })
      );
    }

    dispatch(showLoader(false));
  };

  return (
    <>
      <StatsBoard
        boardName="Tenants"
        statIcons={tenant_icons}
        statNames={tenant_details}
        statValuesMapping={tenants_stats_mapping}
        statValues={statePageStats?.adminTenantStatsInternal}
      ></StatsBoard>
      <div className={styles.tenant_details}>
        <div className={styles.details_header}>
          <div className={styles.detailed_section}>
            <img src={BackArrow} alt="" onClick={() => navigate(-1)}></img>{" "}
            Tenant Details
          </div>
          {/* <button className={styles.send_contract_button}>Send Contract</button> */}

          {section === "Payment History" && (
            <div className={styles.paymentButtonGroup}>
              <ActionButton
                buttonText={"Add Payment Record"}
                onClick={() => {
                  setAddPaymentRecordForm(true);
                }}
              ></ActionButton>
              <ActionButton
                buttonText={"Download Ledger"}
                onClick={() => {
                  // print
                  Print();
                }}
              ></ActionButton>
            </div>
          )}
          {section === "Tenant Details" && (
            <div className={styles.paymentButtonGroup}>
              <ActionButton
                buttonText={"Send Agreement"}
                onClick={() => {
                  setSendContractForm(true);
                }}
              ></ActionButton>
              {!onboarded && (
                <ActionButton
                  buttonText={"Resend Email"}
                  onClick={() => {
                    resendOnBoardingEmail();
                  }}
                ></ActionButton>
              )}
            </div>
          )}
        </div>
        <NavBar state={setSection} active={section} navLinks={navSections} />
        {section === "Tenant Details" && (
          <>
            <CustomTableV2
              data={tenantDetails}
              header="Edit Tenant Details"
              logo={EditLogo}
              handleClick={() =>
                navigate("/admin/tenants/addTenant/" + tenantId)
              }
            />

            {section === "Tenant Details" && (
              <>
                <div className={styles.header_wrap}>
                  <span className={styles.title + " " + styles.mid_table_title}>
                    Emergency Contact Details
                  </span>
                </div>

                <CustomTableV2 data={emergencyContactDetails} />
              </>
            )}
            {/* 
            {documents.map((item, index) => {
              return (
                <FileViewer
                  key={index}
                  header={item.field_name}
                  fileName={item.fileName}
                  fileId={item.fileId}
                  tenantId={tenantId}
                  state={() => {
                    console.log("clicked");
                  }}
                />
              );
            })} */}
          </>
        )}
        {section === "Payment History" && (
          <StatsTable
            tableTitle={""}
            tableHeaders={dues_statsTable_headers}
            tableData={paymentHistoryData}
            tableFieldMapping={dues_tableFieldMapping}
            totalPages={stateDataTotalPages.tenantPaymentHistoryTotalPages}
            removePadding={true}
          ></StatsTable>
        )}

        {section === "Documents" && (
          <>
            <div className={styles.documents_wrap}>
              <div className={styles.content_wrap}>
                <DetailsTable
                  headerImg={PhotoId}
                  tableTitle={"Photo ID"}
                  tableData={photo_id_details}
                />
                <DetailsTable
                  headerImg={AddressInfo}
                  tableTitle={"Address Proof"}
                  tableData={address_proof_details}
                />
              </div>
              <div className={styles.content_wrap}>
                <DetailsTable
                  headerImg={TaxInfo}
                  tableTitle={"Tax Information"}
                  tableData={tax_info}
                />
              </div>
            </div>
          </>
        )}

        {sendContractForm && (
          <SendContractForm
            setSendContractForm={setSendContractForm}
            tenantId={tenantId}
          />
        )}
        {addPaymentRecordForm && (
          <AddPaymentRecordForm
            setSendContractForm={setAddPaymentRecordForm}
            tenantId={tenantId}
          />
        )}
        {/* {printStart && (
         {
          createportal(

          )
         }
        )} */}
        {printStart &&
          createPortal(
            <div
              id="tenants_invoice_warapper"
              className={styles.tenants_invoice_warapper}
            >
              <PaymentInvoice
                rentDetails={paymentHistoryData}
                tenantDetailsData={tenantDetails}
              />
            </div>,
            document.body
          )}
      </div>
    </>
  );
};

export default TenantDetails;
