import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/approvals/Approvals.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";
import TotalApprovalsPending from "../../../../assets/sections/admin/approvals/total_approvals_pending.svg";
import PaymentApprovalsPending from "../../../../assets/sections/admin/approvals/payment_approvals_pending.svg";
import ProfileApprovalsPending from "../../../../assets/sections/admin/approvals/profile_approvals_pending.svg";
import ViewReceipt from "../../../../assets/sections/admin/approvals/view_receipt.svg";
import ViewHistory from "../../../../assets/sections/admin/approvals/view_history.svg";
import StatsBoard from "../../../common/statsboard/StatsBoard";
import StatsTable from "../../../common/stats_table/StatsTable";
import ApprovalActionButton from "../../../common/approval_action_button/ApprovalActionButton";
import SearchField from "../../../common/search_field/SearchField";
import NavBar from "../../../common/nav_bar/NavBar";
import ApprovalActionModal from "../../../common/approval_action_modal/ApprovalActionModal";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../utils/generalFunctions";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS } from "../../../../utils/constants";
import ApprovalsDetails from "./ApprovalsDetails";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { debounce } from "lodash";

const Approvals = () => {
  const stateDataTableData = useSelector((state) => state.getTableData);
  const statePageStats = useSelector((state) => state.pageStats);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const scroll = useSelector((state) => state.pageScroll);

  const [searchText, setSearchText] = useState("");
  const [sectionActive, setSectionActive] = useState("Approval");
  const [isDetailsActive, setIsDetailsActive] = useState(false);
  // const [pageNumber, setPageNumber] = useState(1);
  const [activeRequestId, setActiveRequestId] = useState("");
  const [actionType, setActionType] = useState("");
  const initialRender = useRef(true);
  const dispatch = useDispatch();

  const [isSearchUsed, setIsSearchUsed] = useState(false);
  const [declineModalState, setDeclineModalState] = useState(false);
  const [approvedModalState, setApprovedModalState] = useState(false);

  const [navLinkActive, setNavLinkActive] = useState("Payment Approvals");
  const navSections = [
    "Payment Approvals",
    "Profile Approvals",
    "Document Approvals",
  ];

  const sectionIdToName = {
    payment_approvals: "Payment Approvals",
    profile_approvals: "Profile Approvals",
    document_approvals: "Document Approvals",
  };

  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );

    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  const handleSearch = (text) => {
    debouncedHandleSearch(text);
    // Add your search logic here, e.g., filter/search items based on 'text'
  };

  const onViewHistory = () => {
    setSectionActive("History");
    if (navLinkActive === "Payment Approvals") {
      setNavLinkActive("Payment Approvals History");
    } else if (navLinkActive === "Profile Approvals") {
      setNavLinkActive("Profile Approvals History");
    } else {
      setNavLinkActive("Document Approvals History");
    }
  };

  // const handleAddUnit = () => {};

  // Units Stats Board
  const units_icons = [
    TotalApprovalsPending,
    PaymentApprovalsPending,
    ProfileApprovalsPending,
  ];
  const unit_names = [
    "Total Approvals Pending",
    "Payment Approvals Pending",
    "Profile Approvals Pending",
  ];

  const approval_stats_mapping = [
    "totalApprovals",
    "paymentApprovalsPending",
    "profileApprovalsPending",
  ];

  // Units Stats Table
  const units_statsTable_headers = [
    "Request Date",
    "Requester",
    "Unit Address",
    "Rent Paid",
    "View Details",
    "Action",
  ];

  const units_tableFieldMapping = [
    "request_date",
    "requestor",
    "unit_address",
    "rent_paid",
    "view_receipt",
    "action",
  ];

  const [approvalTableData, setApprovalTableData] = useState([]);

  const handleGetMoreDetails = (actionType) => {
    if (actionType === "Decline") {
      setDeclineModalState(!declineModalState);
    }
    if (actionType === "Approve") {
      setApprovedModalState(!approvedModalState);
    }
  };

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData._id = item._id;
      mappedData.request_date = formatDate(item.createdAt);
      mappedData.requestor = item.tenantName;
      mappedData.unit_address = item.unitName + ", " + item.unitAddress;
      mappedData.rent_paid = "₹ " + item.paymentAmount;
      mappedData.view_receipt = (
        <img
          src={ViewReceipt}
          alt={ViewReceipt}
          onClick={() => {
            setActiveRequestId(item._id);
            setSectionActive("Details");
            setIsDetailsActive(true);
          }}
        ></img>
      );

      mappedData.action = (
        <div className={styles.action_buttons_wrapper}>
          {item.isApproved === false && item.isRejected === false && (
            <>
              <ApprovalActionButton
                buttonText={"Approve"}
                click={() => {
                  setActiveRequestId(item._id);
                  setActionType("Approve");
                  setApprovedModalState(!approvedModalState);
                }}
              ></ApprovalActionButton>
              <ApprovalActionButton
                buttonText={"Decline"}
                click={() => {
                  setActiveRequestId(item._id);
                  setActionType("Decline");
                  setDeclineModalState(!declineModalState);
                }}
              ></ApprovalActionButton>
            </>
          )}
          {item.isApproved === true && (
            <ApprovalActionButton
              buttonText={"Approved"}
              click={() => {}}
            ></ApprovalActionButton>
          )}
          {item.isRejected === true && (
            <ApprovalActionButton
              buttonText={"Rejected"}
              click={() => {}}
            ></ApprovalActionButton>
          )}
        </div>
      );

      mappedDataList.push(mappedData);
    });

    setApprovalTableData(mappedDataList);
  };

  const getApiAdditionalParams = () => {
    let approvalType = "";
    if (navLinkActive.includes("Payment")) {
      approvalType = "payment";
    } else if (navLinkActive.includes("Profile")) {
      approvalType = "profile";
    } else {
      approvalType = "document";
    }

    let history = false;
    if (sectionActive.includes("History")) {
      history = true;
    }

    return { approvalType: approvalType, history: history };
  };

  useEffect(() => {
    // console.log("I got triggered -1");

    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__APPROVALS__APPROVALS",
        scroll.pageNo,
        searchText,
        getApiAdditionalParams()
      );
      fetchPageStats(dispatch, STATS_KEYS.EMPLOYEE__APPROVALS__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__APPROVALS__APPROVALS",
        scroll.pageNo,
        searchText,
        getApiAdditionalParams()
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__APPROVALS__APPROVALS",
        scroll.pageNo,
        searchText,
        getApiAdditionalParams()
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__APPROVALS__APPROVALS",
        scroll.pageNo,
        searchText,
        getApiAdditionalParams()
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__APPROVALS__APPROVALS",
        scroll.pageNo,
        searchText,
        getApiAdditionalParams()
      );
      fetchPageStats(dispatch, STATS_KEYS.EMPLOYEE__APPROVALS__STATS, "");
    }
  }, [scroll.pageNo, searchText, navLinkActive, isDetailsActive]);

  useEffect(() => {
    if (stateDataTableData.adminApprovals?.length >= 0)
      processTableData(stateDataTableData.adminApprovals);
  }, [stateDataTableData.adminApprovals]);

  //profile Approval
  const profile_approval_header = [
    "Request Date",
    "Requester",
    "Unit Address",
    "View Details",
    "Action",
  ];

  const profile_approval_field_mapping = [
    "request_date",
    "requestor",
    "unit_address",
    "view_receipt",
    "action",
  ];

  return (
    <div className={styles.admin_approvals_content}>
      <StatsBoard
        boardName="Approvals"
        statIcons={units_icons}
        statNames={unit_names}
        statValuesMapping={approval_stats_mapping}
        statValues={statePageStats?.adminApprovalStats}
      ></StatsBoard>

      {sectionActive === "Approval" && (
        <StatsTable
          tableTitle={"Approvals"}
          tableHeaders={
            navLinkActive === "Payment Approvals"
              ? units_statsTable_headers
              : navLinkActive === "Profile Approvals"
              ? profile_approval_header
              : profile_approval_header
          }
          tableData={approvalTableData}
          tableFieldMapping={
            navLinkActive === "Payment Approvals"
              ? units_tableFieldMapping
              : navLinkActive === "Profile Approvals"
              ? profile_approval_field_mapping
              : profile_approval_field_mapping
          }
          tableActionsElement={
            <div className={styles.approvals_stats_table_actions}>
              <SearchField searchText={searchText} onSearch={handleSearch} />
              <div
                className={styles.view_history_button}
                onClick={() => {
                  onViewHistory();
                  dispatch(resetTableData());
                  initialRender.current = true;
                  dispatch(
                    updatePageNumber({
                      pageNo: 1,
                    })
                  );
                }}
              >
                <img src={ViewHistory} alt={ViewHistory}></img>
                <span>History</span>
              </div>
            </div>
          }
          tableActionsElementMobile={
            <div
              className={
                statsStyles.stats_title_actions_container +
                " " +
                statsStyles.stats_title_actions_container_show
              }
            >
              <div className={statsStyles.statsTable_title_container}>
                <div className={statsStyles.stats_table_title}>Approvals</div>
                <div className={styles.units_stats_table_actions}>
                  <div
                    className={styles.view_history_button}
                    onClick={() => {
                      onViewHistory();
                      dispatch(resetTableData());
                      initialRender.current = true;
                      dispatch(
                        updatePageNumber({
                          pageNo: 1,
                        })
                      );
                    }}
                  >
                    <img src={ViewHistory} alt={ViewHistory}></img>
                    <span>History</span>
                  </div>
                </div>
              </div>
              <SearchField searchText={searchText} onSearch={handleSearch} />
            </div>
          }
          tableNavBar={
            <NavBar
              state={(navLink) => {
                setNavLinkActive(navLink);
                dispatch(resetTableData());
                initialRender.current = true;
                dispatch(
                  updatePageNumber({
                    pageNo: 1,
                  })
                );
              }}
              active={navLinkActive}
              navLinks={navSections}
              extraClass={styles.approvals_navbar}
            />
          }
          totalPages={stateDataTotalPages.adminApprovalsTotalPages}
        ></StatsTable>
      )}

      {sectionActive === "History" && (
        <StatsTable
          tableTitle={navLinkActive}
          tableHeaders={
            navLinkActive === "Payment Approvals History"
              ? units_statsTable_headers
              : navLinkActive === "Profile Approvals History"
              ? profile_approval_header
              : profile_approval_header
          }
          tableData={approvalTableData}
          tableFieldMapping={
            navLinkActive === "Payment Approvals History"
              ? units_tableFieldMapping
              : navLinkActive === "Profile Approvals History"
              ? profile_approval_field_mapping
              : profile_approval_field_mapping
          }
          tableActionsElement={
            <div className={styles.approvals_stats_table_actions}>
              <SearchField searchText={searchText} onSearch={handleSearch} />
            </div>
          }
          tableIcon={BackArrow}
          state={() => {
            dispatch(resetTableData());
            initialRender.current = true;
            dispatch(
              updatePageNumber({
                pageNo: 1,
              })
            );
            setSectionActive("Approval");
            navLinkActive === "Payment Approvals History"
              ? setNavLinkActive("Payment Approvals")
              : navLinkActive === "Profile Approvals History"
              ? setNavLinkActive("Profile Approvals")
              : setNavLinkActive("Document Approvals");
          }}
          totalPages={stateDataTotalPages.adminApprovalsTotalPages}
        ></StatsTable>
      )}

      {sectionActive === "Details" && (
        <ApprovalsDetails
          state={() => {
            console.log("I got triggered Details Back");
            dispatch(resetTableData());
            initialRender.current = true;
            dispatch(
              updatePageNumber({
                pageNo: 1,
              })
            );
            navLinkActive.includes("History")
              ? setSectionActive("History")
              : setSectionActive("Approval");
            setIsDetailsActive(false);
          }}
          header={
            navLinkActive === "Payment Approvals" ||
            navLinkActive === "Payment Approvals History"
              ? "Approvals - Rent Payment Details"
              : navLinkActive === "Profile Approvals" ||
                navLinkActive === "Profile Approvals History"
              ? "Approvals - Profile Details"
              : "Approvals - Document Details"
          }
          activeRequestId={activeRequestId}
          navLinkActive={navLinkActive}
        />
      )}

      {(declineModalState || approvedModalState) && (
        <ApprovalActionModal
          activeRequestId={activeRequestId}
          clickHandler={handleGetMoreDetails}
          actionType={actionType}
          navLinkActive={navLinkActive}
        />
      )}
    </div>
  );
};

export default Approvals;
