import { useState } from "react";
import styles from "../../../styles/common/payment_form/PaymentForm.module.css";
import DropDown from "../drop_down/DropDown";

const PaymentForm = ({ fields, handleSubmit, buttonText }) => {
  const [image, setImage] = useState([]);
  const [rentOptionSelected, setRentOptionSelected] = useState();
  const [paymentOptionSelected, setPaymentOptionSelected] = useState();
  const [requestOptionSelected, setRrequestOptionSelected] = useState();

  return (
    <div className={styles.payment_form}>
      {fields.map((field, index) => {
        return (
          <div key={index}>
            {field.type !== "file" && field.type !== "DropDown" && (
              <input
                type={field.type}
                className={`${styles.field} ${styles.input_field} ${
                  buttonText === "Approved" && styles.approved
                } ${buttonText === "Declined" && styles.declined}`}
                onChange={(event) => (field.value = event.target.value)}
                placeholder={field.placeholder}
              ></input>
            )}
            {field.type === "file" && (
              <input
                type="file"
                onChange={({ target: { files } }) => {
                  if (files) {
                    setImage([...image, files[0]]);
                  }
                }}
                className={` ${styles.field} ${styles.upload_button} ${
                  buttonText === "Approved" && styles.approved
                } ${buttonText === "Declined" && styles.declined}`}
              />
            )}
            {field.type === "DropDown" && (
              <DropDown
                options={field.options}
                selected={
                  field.for === "Payment"
                    ? setPaymentOptionSelected
                    : field.for === "Rent"
                    ? setRentOptionSelected
                    : setRrequestOptionSelected
                }
                type={buttonText}
              />
            )}
            {field.type === "TextArea" && (
              <textarea
                placeholder={field.placeholder}
                className={`${styles.field} ${styles.text_area} ${
                  buttonText === "Approved" && styles.approved
                } ${buttonText === "Declined" && styles.text_area_declined}`}
                onChange={(event) => (field.value = event.target.value)}
              ></textarea>
            )}
          </div>
        );
      })}
      <button
        className={`${styles.form_submit_button} ${
          buttonText === "Approved" && styles.approved
        } ${buttonText === "Declined" && styles.declined}`}
        onClick={() =>
          handleSubmit(
            rentOptionSelected,
            paymentOptionSelected,
            image,
            requestOptionSelected
          )
        }
      >
        {buttonText}
      </button>
    </div>
  );
};

export default PaymentForm;
