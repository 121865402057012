// const Joi = require("joi");
import Joi from "joi";

const indianPhoneNumberPattern = /^[6789]\d{9}$/; // Valid Indian mobile numbers start with 6, 7, 8, or 9
const australianPhoneNumberPattern = /^(?:\+61|0)[2378]\d{8}$/; // Valid Australian phone numbers start with +61 or 0

//Organisation Schema

const organisationSchema = Joi.object({
  organisationName: Joi.string().required(),
  organisationAddress: Joi.string(),
  organisationEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  GST: Joi.string(),
}).options({ abortEarly: false });

// Admin Signup Schema
const employeeSchema = Joi.object({
  name: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  mobile: Joi.string().required(),
  address: Joi.string().required(),
  whatsapp: Joi.string(),
}).options({ abortEarly: false });

// Login Validation
const loginValidation = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required()
    .trim()
    .custom((value, helpers) => {
      if (!value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
        return helpers.message(
          "Invalid email format. Please enter a valid email address."
        );
      }
      return value;
    })
    .messages({
      "string.email": "Email must be a valid email address.",
      "any.required": "Email is required.",
      "string.empty": "Email cannot be empty.",
    }),
  password: Joi.string()
    .min(8)
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^_&*])/)
    .messages({
      "string.pattern.base":
        "Password must include at least one lowercase, uppercase, digit, and special character.",
      "string.min": "Password must be at least 8 characters long",
    }),
}).options({ abortEarly: false });

//Change password validation
const setPasswordValidation = Joi.object({
  password: Joi.string()
    .min(8)
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^_&*])/)
    .messages({
      "string.pattern.base":
        "Password must include at least one lowercase, uppercase, digit, and special character.",
      "string.min": "Password must be at least 8 characters long",
    })
    .required(),
  confirmPassword: Joi.string().valid(Joi.ref("password")).required().messages({
    "any.only": "Confirm password must match password",
    "any.required": "Confirm password is required",
  }),
}).options({ abortEarly: false });

//Change password validation
const changePasswordValidation = Joi.object({
  currentPassword: Joi.string().required(),
  newPassword: Joi.string()
    .required()
    .min(8)
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^_&*])/)
    .messages({
      "string.pattern.base":
        "Password must include at least one lowercase, uppercase, digit, and special character.",
      "string.min": "Password must be at least 8 characters long",
    }),
  rePassword: Joi.string().valid(Joi.ref("newPassword")).required().messages({
    "any.only": "Passwords must match",
    "any.required": "Confirm password is required",
  }),
}).options({ abortEarly: false });

/** *******************************
 * ADD NEW TENANT DATA ADMIN-FORM *
 ********************************** */

const tenantSchema = Joi.object({
  tenantName: Joi.string().required(),
  unitId: Joi.string().required(),
  // unitName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  mobile: Joi.string(),
  whatsapp: Joi.string(),
  // .pattern(australianPhoneNumberPattern)
  // .message("Invalid Australian contact number format."),
  monthlyRent: Joi.number().required().min(1),
  bondAmount: Joi.number().min(1),
  incrementPercent: Joi.number().required().allow(null),
  startDate: Joi.date().iso().required(),
  endDate: Joi.date().iso(),
  tentativeStayDuration: Joi.number().required().min(1), // Allow null if it's optional
  isActive: Joi.boolean(),
  // startweekPaymentDate: Joi.date().iso(),
}).options({ abortEarly: false });

const tenantUpdateProfileSchema = Joi.object({
  tenantName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  mobile: Joi.string().required(),
}).options({ abortEarly: false });
/** *******************************
 * TENANT FORM II DATA VALIDATION *
 ********************************** */

// Define schema for personal details
const tenantPersonalSchema = Joi.object({
  tenantName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  mobile: Joi.string().required(),
  whatsapp: Joi.string(),
  // country: Joi.string().required(),
  permanentAddress: Joi.string(),
}).options({ abortEarly: false });

// Define schema for emergency contact details
const tenantEmergencyContactSchema = Joi.object({
  name: Joi.string(),
  mobile: Joi.string(),
  whatsapp: Joi.string(),
  permanentAddress: Joi.string(),
  relation: Joi.string(),
}).options({ abortEarly: false });

/** *****************************
 * ADD NEW UNIT DATA ADMIN-FORM *
 ******************************** */

const unitSchema = Joi.object({
  unitName: Joi.string().required(),
  flatNo: Joi.string(),
  unitAddress: Joi.string().required(),
  noOfPosition: Joi.number().integer().min(1).required(),
  availablePosition: Joi.number(),
  unitType: Joi.string().valid("Male", "Female", "Family", "All").required(),
  nextCleaningDate: Joi.date().iso(),
  cleaningFrequency: Joi.number(),
  leased: Joi.boolean().required(),
  agentName: Joi.when("leased", {
    is: Joi.exist().valid(true),
    then: Joi.string().required(),
    otherwise: Joi.string(),
  }),
  leaseTakenOn: Joi.when("leased", {
    is: Joi.exist().valid(true),
    then: Joi.date().required(),
    otherwise: Joi.date(),
  }),
  leaseExpiresOn: Joi.date(),
  monthlyRent: Joi.when("leased", {
    is: Joi.exist().valid(true),
    then: Joi.number().min(1).required(),
    otherwise: Joi.number().min(1),
  }),
  bondAmount: Joi.number().min(1),
  contactEmail: Joi.string().email({ tlds: { allow: false } }),
  contactLandline: Joi.number(),
}).options({ abortEarly: false });

const setupExpenseSchema = Joi.object({
  date: Joi.date().iso().required(),
  description: Joi.string().required(),
  amount: Joi.number().required().min(1),
}).options({ abortEarly: false });

/** *****************************
 * paymentValidationSchema form *
 ******************************** */

const paymentValidationSchema = Joi.object({
  paymentCategory: Joi.string().valid("Bond", "Rent", "Other").required(),
  paymentMode: Joi.string()
    .valid("CREDIT CARD", "CASH", "NET BANKING")
    .required(),
  paymentAmount: Joi.number().required().min(1),
  // balance: Joi.number(),
  // bankAccountNo: Joi.string().required(),
  // bankName: Joi.string(),
  // startPaymentDate: Joi.date().iso(),
  // endPaymentDate: Joi.date().iso(),
  actualPaymentDate: Joi.date().iso().required(),
  description: Joi.string(),
  // tenantId: Joi.string(),
  // tenantName: Joi.string(),
  // isPaymentApproved: Joi.boolean(),
  asset: Joi.object().required(),
}).options({ abortEarly: false });

const adminPaymentValidationSchema = Joi.object({
  paymentCategory: Joi.string().valid("Bond", "Rent", "Other").required(),
  paymentMode: Joi.string()
    .valid("CREDIT CARD", "CASH", "NET BANKING")
    .required(),
  rentAmount: Joi.number().min(1),
  paymentAmount: Joi.number().required().min(1),
  // balance: Joi.number().min(0),
  actualPaymentDate: Joi.date().iso().required(),
  description: Joi.string(),
  rentFrequency: Joi.number(),
}).options({ abortEarly: false });

const contractFormValidationSchema = Joi.object({
  asset: Joi.object().required(),
  tenantId: Joi.string().required(),
});

const signedContractFormValidationSchema = Joi.object({
  asset: Joi.object().required(),
  requestId: Joi.string().required(),
});

//PARKING SLOT SCHEMA
const parkingSlotValidationSchema = Joi.object({
  parkingSlot: Joi.string().required(),
  provider: Joi.string().allow("", null),
  vehicleNumber: Joi.string().allow("", null),
  contactName: Joi.string().allow("", null),
  rent: Joi.number().min(1).allow(0, null),
  contactNumber: Joi.string(),
  isOccupied: Joi.boolean(),
});

//CLEANING SCHEMA
const objectIdSchemaPattern = /^[0-9a-fA-F]{24}$/;
const cleaningSchema = Joi.object({
  cleanerName: Joi.string().required(),
  fee: Joi.number().required(),
  cleaningDate: Joi.date(),
  cleaningType: Joi.string()
    .valid("Regular", "Deep", "Special")
    .default("Regular"),
});

export {
  organisationSchema,
  employeeSchema,
  tenantSchema,
  unitSchema,
  setupExpenseSchema,
  tenantPersonalSchema,
  tenantEmergencyContactSchema,
  tenantUpdateProfileSchema,
  loginValidation,
  setPasswordValidation,
  changePasswordValidation,
  paymentValidationSchema,
  adminPaymentValidationSchema,
  parkingSlotValidationSchema,
  cleaningSchema,
  contractFormValidationSchema,
  signedContractFormValidationSchema,
};
