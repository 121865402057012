import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import styles from "../../../../styles/sections/admin/dashboard/EarningsGraph.module.css";
import { fetchPlotDataPoints } from "../../../../generalApiCalls/getPlotDataPoints";
import { PLOTS } from "../../../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const EarningGraph = () => {
  const dispatch = useDispatch();
  const statePlotData = useSelector((state) => state.plots.dashboardPlot);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
        intersect: false,
        mode: "index",
      },
      legend: {
        labels: {
          // Configure the legend labels
          usePointStyle: true, // Utilize point style instead of rectangles
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        grid: {
          display: true,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };

  useEffect(() => {
    fetchPlotDataPoints(dispatch, PLOTS.PLOT_DASHBOARD);
  }, []);

  const createDataObject = (dataArray) => {
    return dataArray.reduce((result, item) => {
      result[item.month] = item.amount;
      return result;
    }, {});
  };

  const expenseData = createDataObject(statePlotData.expense);
  const earningData = createDataObject(statePlotData.earning);
  const profitData = createDataObject(statePlotData.profit);

  const months = Object.keys(expenseData);
  const expensePoints = months.map((month) => expenseData[month]);
  const earningPoints = months.map((month) => earningData[month]);
  const profitPoints = months.map((month) => profitData[month]);

  console.log("Plot data", profitData, expenseData, earningData);

  const data = {
    labels: months,
    datasets: [
      {
        label: "Earnings",
        data: earningPoints,
        borderColor: "#FFBD80",
        backgroundColor: "#FFBD80", // Set the fill color for the line
        pointStyle: "circle",
        pointBackgroundColor: "#FFBD80", // Set the point color to be filled
      },
      {
        label: "Expenses",
        data: expensePoints,
        borderColor: "#98BEFF",
        backgroundColor: "#98BEFF", // Set the fill color for the line
        pointStyle: "circle",
        pointBackgroundColor: "#98BEFF", // Set the point color to be filled
      },

      {
        label: "Profit",
        data: profitPoints,
        borderColor: "#80C1B0",
        backgroundColor: "#80C1B0", // Set the fill color for the line
        pointStyle: "circle",
        pointBackgroundColor: "#80C1B0", // Set the point color to be filled
      },
    ],
  };

  return (
    <div className={styles.admin_earnings_plot_wrapper}>
      <span>Earnings</span>
      <div className={styles.mark_area_container}>
        <div className={styles.mark_area_plot}>
          <Line
            options={options}
            // options={options}
            data={data}
            redraw={true}
            updateMode="resize"
          />
        </div>
      </div>
    </div>
  );
};

export default EarningGraph;
