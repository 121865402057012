// import styles from "../../../styles/sections/tenant/Tenant.module.css";
import styles from "../../../styles/sections/admin/Admin.module.css";

import Header from "../../common/header/Header";
import Home from "./home/Home";
import TenantNavigationBar from "./tenant_navbar/TenantNavigationBar";
import { Route, Routes, Navigate } from "react-router-dom";
import Payments from "./payments/Payments";
import Profile from "./profile/Profile";
import RequestHistory from "./request_history/RequestHistory";
import SupportAndHelp from "./support_and_help/SupportAndHelp";

const Tenant = () => {
  const tenantFeatures = [
    {
      sectionName: "Home",
      url: "/home",
      component: <Home />,
    },

    {
      sectionName: "Payment",
      url: "/payment",
      component: <Payments />,
    },
    {
      sectionName: "Request",
      url: "/request",
      component: <RequestHistory />,
    },
    {
      sectionName: "Support and Help",
      url: "/support",
      component: <SupportAndHelp />,
    },
    {
      sectionName: "Profile",
      url: "/profile",
      component: <Profile />,
    },
    {
      sectionName: "Notification",
      url: "/notification",
      component: <div>Notification</div>,
    },
  ];

  const RediredtToHome = () => {
    return <Navigate to="/tenant/home" />;
  };

  return (
    <div className={styles.admin_dashboard}>
      <TenantNavigationBar />
      <div className={styles.header_mainContent}>
        <Header />
        <Routes>
          <Route path="/" element={<RediredtToHome />} />
          {tenantFeatures.map((feature, index) => {
            return (
              <Route
                key={index}
                path={feature.url}
                element={feature.component}
              />
            );
          })}
        </Routes>
      </div>
    </div>
  );
};

export default Tenant;
