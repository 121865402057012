import styles from "../../../styles/common/profile_update_form/ProfileUpdateForm.module.css";
import { useState } from "react";
import FormDropDown from "../form_dropdown/FormDropDown";

const ProfileUpdateForm = ({
  form_fields,
  handleFieldChange,
  submit = null,
  formHandler = null,
}) => {
  const [options, setOptions] = useState([]);
  const [image, setImage] = useState([]);

  const handleSubmit = () => {
    form_fields.map((item) => {
      return item.value && console.log(item.value);
    });
  };

  const handleSelect = (id, option) => {
    handleFieldChange(id, option, false, "");
    setOptions([...options, { [`${id}`]: option }]);
  };

  return (
    <div className={styles.update_form}>
      <form className={styles.profile_update_form}>
        {form_fields.map((item, index) => {
          return (
            <div className={styles.input_wrap} key={index}>
              <label className={styles.input_name}>{item.field_name}</label>
              {(item.type === "text" ||
                item.type === "number" ||
                item.type === "date" ||
                item.type === "email" ||
                item.type === "address") && (
                <input
                  className={
                    `${styles.input_field}  ${styles.field}` +
                    " " +
                    (item.error ? styles.field_error : "")
                  }
                  type={item.type}
                  // onChange={(event) => {
                  //   item.value = event.target.value;
                  // }}
                  onChange={(event) => {
                    handleFieldChange(
                      item.field_id,
                      event.target.value,
                      false,
                      ""
                    );
                  }}
                  placeholder={item.placeholder}
                  value={item.value}
                />
              )}
              {item.type === "file" && (
                <input
                  type="file"
                  onChange={({ target: { files } }) => {
                    console.log(item.field_id, files[0]);
                    handleFieldChange(item.field_id, files[0], false, "");
                  }}
                  className={`${styles.upload_button}  ${styles.field}`}
                />
              )}
              {item.type === "dropdown" && (
                <FormDropDown
                  options={item.options}
                  name={item.field_name}
                  id={item.field_id}
                  selectHandler={handleSelect}
                  value={item.value}
                />
              )}
              {/* {item.type === "address" && (
                <input
                  className={`${styles.input_field}  ${styles.field}`}
                  type={item.type}
                  onChange={(event) => {
                    item.value = event.target.value;
                  }}
                  placeholder={item.placeholder}
                ></input>
              )} */}
              {item.error && (
                <p className={styles.error_message}>{item.error_message}</p>
              )}
            </div>
          );
        })}
      </form>
      {submit && (
        <button
          className={styles.form_submit_button}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            formHandler();
          }}
        >
          {submit}
        </button>
      )}
    </div>
  );
};

export default ProfileUpdateForm;
