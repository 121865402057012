import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alertType: "success",
  alertTitle: "Success",
  alertMessage: "This is a success alert",
  autoClose: false,
  autoCloseTime: 3000,
  onClose: false,
  alertShow: false,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.alertType = action.payload.alertType;
      state.alertTitle = action.payload.alertTitle;
      state.alertMessage = action.payload.alertMessage;
      state.autoClose = action.payload.autoClose;
      state.autoCloseTime = action.payload.autoCloseTime;
      state.onClose = action.payload.onClose;
      state.alertShow = action.payload.alertShow;
    },
  },
});

export const { setAlert } = alertSlice.actions;
export default alertSlice.reducer;
