import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardPlot: {
    expense: [],
    earning: [],
    profit: [],
  },
};

const plotsSlice = createSlice({
  name: "plots",
  initialState,
  reducers: {
    updateDashboardPlot: (state, action) => {
      const { expense, earning, profit } = action.payload;

      state.dashboardPlot.expense = expense;
      state.dashboardPlot.earning = earning;
      state.dashboardPlot.profit = profit;
    },
  },
});

export const { updateDashboardPlot } = plotsSlice.actions;
export default plotsSlice.reducer;
