import styles from "../../../../styles/sections/admin/units/UnitDetails.module.css";
import { useEffect, useState } from "react";
import AvailableBeds from "../../../../assets/sections/admin/dashboard/available_beds.svg";
import TotalBeds from "../../../../assets/sections/admin/units/total_beds.svg";
import RentPaid from "../../../../assets/sections/admin/units/rent_paid.svg";
import TotalRevenue from "../../../../assets/sections/admin/units/total_revenue.svg";
import UtilityBills from "../../../../assets/sections/admin/units/utility_bills.svg";
import StatsBoard from "../../../common/statsboard/StatsBoard";
import NavBar from "../../../common/nav_bar/NavBar";
import CustomTableV2 from "../../../common/custom_table_v2/CustomTableV2";
import EditLogo from "../../../../assets/common/edit.svg";
import DeleteLogo from "../../../../assets/common/delete_red.svg";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanFormObject,
  getKeyByValue,
} from "../../../../utils/generalFunctions";
import { setupExpenseSchema } from "../../../../utils/validators";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import SetupExpenseForm from "./SetupExpenseForm";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS } from "../../../../utils/constants";
import StatsTable from "../../../common/stats_table/StatsTable";
import ActionButton from "../../../common/action_button/ActionButton";

const MoreDetails = () => {
  const statePageStats = useSelector((state) => state.pageStats);
  const stateDataTotalPages = useSelector((state) => state.totalPages);

  const navSections = ["Unit Details", "Tenants", "Expenses"];

  const sectionIdToName = {
    unit_details: "Unit Details",
    tenants: "Tenants",
    expenses: "Expenses",
  };

  const [section, setSection] = useState("Unit Details");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const unitId = useParams().unitId;
  const sectionId = useParams().sectionId;

  //unit details data

  const [unitDetails, setUnitDetails] = useState([
    {
      data_type: "Building Name",
      data_value_type: "text",
      value: "",
      id: "unitName",
    },
    {
      data_type: "Flat No",
      data_value_type: "text",
      value: "",
      id: "flatNo",
    },
    {
      data_type: "Building Address",
      data_value_type: "text",
      value: "",
      id: "unitAddress",
    },

    {
      data_type: "No. of Position",
      data_value_type: "number",
      value: "",
      id: "noOfPosition",
    },
    {
      data_type: "No. of Position Available",
      data_value_type: "number",
      value: "",
      id: "availablePosition",
    },

    {
      data_type: "Unit Type",
      data_value_type: "text",
      value: "",
      id: "unitType",
    },
    {
      data_type: "Next Cleaning Date",
      data_value_type: "date",
      value: "",
      id: "nextCleaningDate",
    },
    {
      data_type: "Cleaning Frequency",
      data_value_type: "number",
      value: "",
      id: "cleaningFrequency",
    },
    {
      data_type: "Agent Name",
      data_value_type: "text",
      value: "",
      id: "agentName",
    },
    {
      data_type: "Lease Taken On",
      data_value_type: "date",
      value: "",
      id: "leaseTakenOn",
    },
    {
      data_type: "Monthly Rent ",
      data_value_type: "currency",
      value: "",
      id: "monthlyRent",
    },
    {
      data_type: "Bond Amount",
      data_value_type: "currency",
      value: "",
      id: "bondAmount",
    },

    {
      data_type: "Contact Email",
      data_value_type: "email",
      value: "",
      id: "contactEmail",
    },
    {
      data_type: "Contact Landline",
      data_value_type: "number",
      value: "",
      id: "contactLandline",
    },

    // {
    //   data_type: "Cleaner",
    //   value: "",
    // },
    // {
    //   data_type: "Miscellaneous",
    //   value: "",
    // },
    // {
    //   data_type: "Employee Salary",
    //   value: "",
    // },
    // {
    //   data_type: "Total Expenses",
    //   value: "Rent+Elec+Gas+Int+clean+Mis+Emp",
    // },
    // {
    //   data_type: "Profit To LP",
    //   value: "",
    // },
  ]);

  //stats board data

  const units_icons = [
    AvailableBeds,
    TotalBeds,
    RentPaid,
    TotalRevenue,
    UtilityBills,
  ];

  const unit_details = [
    "Available Beds",
    "Total Beds",
    "Rent Paid",
    "Total Revenue",
    "Planned Bills",
  ];

  const units_stats_mapping = [
    "availableBeds",
    "totalBeds",
    "rentPaid",
    "totalRevenue",
    "plannedBills",
  ];

  //Tenants data

  const [tenantDetails, setTenantDetails] = useState([]);

  const detailsHeader = [
    "Sr No.",
    "Name",
    "Contact Number",
    "Date of Joining",
    "Monthly Rent",
    "Active",
  ];

  const details_tableFieldMapping = [
    "sr_no",
    "tenantName",
    "mobile",
    "startDate",
    "monthlyRent",
    "isActive",
  ];

  //Setup expenses data

  const [currentSetupExpenseId, setCurrentSetupExpenseId] = useState(0);
  const [openSetupModal, setOpenSetupModal] = useState(false);

  const addExpenseFormController = [
    {
      field_name: "Expense Date",
      field_id: "date",
      placeholder: "Enter Expense Date",
      type: "date",
    },
    {
      field_name: "Description",
      field_id: "description",
      placeholder: "Enter a description",
      type: "text",
    },
    {
      field_name: "Amount",
      field_id: "amount",
      placeholder: "Enter Amount",
      type: "number",
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return addExpenseFormController.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  useEffect(() => {
    if (currentSetupExpenseId === 0) {
      handleFieldChange("name", "", false, "");
      handleFieldChange("price", "", false, "");
    }
    if (currentSetupExpenseId !== 0) {
      const currentSetuExpenseRecord = expenseTableData.find(
        (item) => item.id === currentSetupExpenseId
      );

      console.log(currentSetuExpenseRecord);

      setFormFields((prevFields) => {
        const updatedFormFields = [...prevFields];

        updatedFormFields[0] = {
          ...updatedFormFields[0],
          value: currentSetuExpenseRecord.date,
        };

        updatedFormFields[1] = {
          ...updatedFormFields[1],
          value: currentSetuExpenseRecord.description,
        };
        updatedFormFields[2] = {
          ...updatedFormFields[2],
          value: currentSetuExpenseRecord.amount.replace("₹ ", ""),
        };

        return updatedFormFields;
      });

      console.log(formFields);
    }
  }, [currentSetupExpenseId]);

  const deleteSetupExpenseRecord = async (expenseId) => {
    dispatch(showLoader(true));
    const response = await postFormData({
      form_key: "ADMIN__UNITS__SETUP_DELETE_EXPENSE",
      urlId: unitId,
      data: {
        expenseId: expenseId,
      },
      method: "delete",
    });
    if (response.success) {
      // window.location.reload();
    } else {
      alert(response.message);
    }
    dispatch(showLoader(false));
  };

  const formHandler = async () => {
    // Form data creation
    let newSetupExpense = {};
    formFields.map((item) => {
      newSetupExpense[item.field_id] = item.value;
    });
    newSetupExpense = cleanFormObject(newSetupExpense);

    // Validate the form data
    const { error } = setupExpenseSchema.validate(newSetupExpense);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;
        let errorFieldName = item.path[0];
        console.log(errorFieldName);
        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;
        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data
      let response = null;

      // upadte
      if (currentSetupExpenseId !== 0) {
        newSetupExpense["expenseId"] = currentSetupExpenseId;
        response = await postFormData({
          form_key: "ADMIN__UNITS__SETUP_UPDATE_EXPENSE",
          data: newSetupExpense,
          urlId: unitId,
          method: "put",
        });
      } else {
        // add
        response = await postFormData({
          form_key: "ADMIN__UNITS__SETUP_ADD_EXPENSE",
          data: newSetupExpense,
          urlId: unitId,
        });
      }

      if (response.success) {
        // window.location.reload();
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const [expenseTableData, setExpenseTableData] = useState([]);

  const expenses_table_headers = [
    "Sr No.",
    "Date",
    "Description",
    "Amount",
    "Action",
  ];

  const expenses_tableFieldMapping = [
    "sr_no",
    "date",
    "description",
    "amount",
    "action",
  ];

  const handleGetMoreDetails = async (unitId) => {
    dispatch(showLoader(true));

    const response = await getSingleData({
      API_NAME: "ADMIN__UNITS__GET_UNIT_DETAILS",
      oneId: unitId,
    });

    const expenseResponse = await getSingleData({
      API_NAME: "ADMIN_UNITS_SETUP_GET_EXPENSES",
      oneId: unitId,
    });

    setUnitDetails((prev) => {
      return prev.map((item) => {
        if (item.id in response) {
          return { ...item, value: response[item.id] };
        } else {
          return item;
        }
      });
    });

    setTenantDetails(
      response.tenantData.map((item, index) => {
        return {
          sr_no: index + 1,
          tenantName: item.tenantName,
          mobile: item.mobile,
          startDate: item.startDate.substring(0, 10),
          monthlyRent: "₹ " + item.monthlyRent,
          isActive: item.isActive ? "Yes" : "No",
        };
      })
    );

    setExpenseTableData(
      expenseResponse?.map((item, index) => {
        return {
          id: item._id,
          sr_no: index + 1,
          date: item.date.substring(0, 10),
          description: item.description,
          amount: "₹ " + item.amount,
          action: (
            <div className={styles.action_buttons}>
              <img
                src={EditLogo}
                alt="Edit"
                onClick={() => {
                  setOpenSetupModal(true);
                  setCurrentSetupExpenseId(item._id);
                }}
              ></img>
              <img
                src={DeleteLogo}
                alt="Delete"
                onClick={() => {
                  deleteSetupExpenseRecord(item._id);
                }}
              ></img>
            </div>
          ),
        };
      })
    );

    dispatch(showLoader(false));
  };

  useEffect(() => {
    if (section) {
      let sectionIdToRedirect = getKeyByValue(sectionIdToName, section);
      navigate("/admin/units/" + unitId + "/" + sectionIdToRedirect);
    }
  }, [section]);

  useEffect(() => {
    if (sectionId) {
      setSection(sectionIdToName[sectionId]);
    }
    handleGetMoreDetails(unitId);
    fetchPageStats(
      dispatch,
      STATS_KEYS.EMPLOYEE__UNITS__STATS_INTERNAL,
      unitId
    );
  }, [unitId]);

  return (
    <>
      <StatsBoard
        boardName="Units"
        statIcons={units_icons}
        statNames={unit_details}
        statValuesMapping={units_stats_mapping}
        statValues={statePageStats?.adminUnitStatsInternal}
      ></StatsBoard>
      <div className={styles.body}>
        <div className={styles.details_header}>
          <div className={styles.detailed_section}>
            <img src={BackArrow} alt="" onClick={() => navigate(-1)}></img>
            Details
          </div>
          {section === "Expenses" && (
            <div className={styles.paymentButtonGroup}>
              <ActionButton
                buttonText={"Add Expense"}
                // buttonIcon={AddIcon}
                onClick={() => {
                  setOpenSetupModal(true);
                  setCurrentSetupExpenseId(0);
                }}
              ></ActionButton>
            </div>
          )}
        </div>

        <NavBar state={setSection} active={section} navLinks={navSections} />
        {section === "Unit Details" && (
          <CustomTableV2
            data={unitDetails}
            header="Edit Unit Details"
            logo={EditLogo}
            handleClick={() => navigate("/admin/units/addunit/" + unitId)}
          />
        )}

        {section === "Tenants" && (
          <StatsTable
            tableTitle={""}
            tableHeaders={detailsHeader}
            tableData={tenantDetails}
            tableFieldMapping={details_tableFieldMapping}
            totalPages={stateDataTotalPages.tenantPaymentHistoryTotalPages}
            removePadding={true}
          ></StatsTable>
        )}
        {section === "Expenses" && (
          <StatsTable
            tableTitle={""}
            tableHeaders={expenses_table_headers}
            tableData={expenseTableData}
            tableFieldMapping={expenses_tableFieldMapping}
            totalPages={stateDataTotalPages.tenantPaymentHistoryTotalPages}
            removePadding={true}
          ></StatsTable>
        )}
        {openSetupModal && (
          <SetupExpenseForm
            form_fields={formFields}
            handleFieldChange={handleFieldChange}
            setOpenFormModal={setOpenSetupModal}
            formHandler={formHandler}
            currentExpenseId={currentSetupExpenseId}
          />
        )}
      </div>
    </>
  );
};

export default MoreDetails;
