import { Fragment } from "react";
import ReactDOM from "react-dom";
import styles from "../../../styles/common/loader/Loader.module.css";
import { useSelector } from "react-redux";

const LoaderComponent = () => {
  const stateData = useSelector((state) => state.loader);

  return (
    stateData.isLoading && (
      <div className={styles.lds_roller_main}>
        {/* <div className={styles.lds_roller}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}
        <div className={styles.center}>
          <div className={styles.wave}></div>
          <div className={styles.wave}></div>
          <div className={styles.wave}></div>
          <div className={styles.wave}></div>
          <div className={styles.wave}></div>
          <div className={styles.wave}></div>
          <div className={styles.wave}></div>
          <div className={styles.wave}></div>
          <div className={styles.wave}></div>
          <div className={styles.wave}></div>
        </div>
      </div>
    )
  );
};

const PortalElement = document.getElementById("overlays");

const Loader = () => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<LoaderComponent />, PortalElement)}
    </Fragment>
  );
};

export default Loader;
