import React, { useRef, useState } from "react";

import styles from "../../styles/authentication/Login.module.css";

import { useNavigate } from "react-router-dom";
import Axios from "../../axios";
import { setAlert } from "../../redux/reducers/AlertSlice";
import { useDispatch } from "react-redux";
import { USER_TYPE } from "../../utils/constants";

function OtpAuthentication({ email }) {
  const [error, setError] = React.useState("");

  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [num3, setNum3] = useState("");
  const [num4, setNum4] = useState("");
  const [num5, setNum5] = useState("");
  const [num6, setNum6] = useState("");

  let numbersRef = [
    { ref: useRef(), num: num1, setter: setNum1 },
    { ref: useRef(), num: num2, setter: setNum2 },
    { ref: useRef(), num: num3, setter: setNum3 },
    { ref: useRef(), num: num4, setter: setNum4 },
    { ref: useRef(), num: num5, setter: setNum5 },
    { ref: useRef(), num: num6, setter: setNum6 },
  ];

  // const [otp, setOtp] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //TODO: Add login functionality
  //TODO: Integrate with JOI Validation
  const handleOtpAuthentication = async () => {
    var otp = num1 + num2 + num3 + num4 + num5 + num6;

    if (otp !== "" && otp !== null && otp !== undefined) {
      let loginData = {
        userType: USER_TYPE.ADMIN,
        email: email,
        otp: otp,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/v1/auth/verifyOTP",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(loginData),
      };

      try {
        const response = await Axios.request(config);

        if (response.status === 200 || response.status === 201) {
          if (response.data.success === true) {
            // alert("Logged In Successfully");

            const accessToken = response.headers.authorization.split(" ")[1];
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("userType", USER_TYPE.ADMIN);
            window.open("/admin/dashboard", "_self");
          }
        }
      } catch (error) {
        if (error.response?.data?.message) {
          // setError(error.response.data.message);
          dispatch(
            setAlert({
              alertType: "error",
              alertMessage: error.response.data.message,
              alertTitle: "Error",
              autoClose: false,
              alertShow: true,
            })
          );
        }

        // Check if the error has a response with a status code
        if (error.response && error.response.status === 701) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userType");
        }

        console.log("Error in Otp Auth handleOtpAuthentication", error);
      }
    } else {
      // setError("Please fill all the fields");
      dispatch(
        setAlert({
          alertType: "error",
          alertMessage: "Please fill the form correctly",
          alertTitle: "Error",
          autoClose: false,
          alertShow: true,
        })
      );
    }

    // else {
    //   setError("Incorrect OTP entered....");
    // }
  };

  const handleOtpChange = (index, value) => {
    setError("");
    if (value.length > 0) {
      if (Number(value) > 9) {
        value = "9";
      }
      if (Number(value) < 0) {
        value = "0";
      }
      numbersRef[index].setter(value);
    }

    if (index < 5 && value !== "") {
      numbersRef[index + 1].ref.current.focus();
    }
    if (index >= 0 && value === "") {
      if (index === 0) {
        numbersRef[index].setter("");
      } else {
        numbersRef[index - 1].ref.current.focus();
      }

      for (let i = index; i < 6; i++) {
        numbersRef[i].setter("");
        // otp.slice(0, index);
      }
    }
  };

  const handleResendOtp = async () => {
    if (countDown === 0) {
      setCountDown(90);
      let data = {
        email: email,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/v1/auth/resendOtp",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      try {
        const response = await Axios.request(config);

        if (response.status === 200 || response.status === 201) {
          if (response.data.success === true) {
            // alert("OTP has been sent to your email/number");
            dispatch(
              setAlert({
                alertType: "success",
                alertMessage: "OTP has been sent to your email/number",
                alertTitle: "Success",
                autoClose: false,
                alertShow: true,
              })
            );
          } else {
            // setError(response.data.message || "Something went wrong");
            dispatch(
              setAlert({
                alertType: "error",
                alertMessage: response.data.message || "Something went wrong",
                alertTitle: "Error",
                autoClose: false,
                alertShow: true,
              })
            );
          }
        }
      } catch (error) {
        if (error.response?.data?.message) {
          setError(error.response.data.message);
        }

        // Check if the error has a response with a status code
        if (error.response && error.response.status === 701) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userType");
        }

        console.log(error);
      }
    }
  };

  const [countDown, setCountDown] = React.useState(90);

  React.useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    }
  }, [countDown]);

  return (
    <div className={styles.form}>
      <div className={styles.form_title}>OTP Verification</div>
      <div className={styles.form_components}>
        <div className={styles.form_component}>
          <div className={styles.title}>
            Enter 6 digit OTP sent on your Email/Number
          </div>
          <div className={styles.otp_container}>
            {numbersRef.map((obj, index) => {
              return (
                <input
                  key={index}
                  type="number"
                  min={0}
                  max={9}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  ref={obj.ref}
                  value={obj.num}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleOtpAuthentication();
                    }
                  }}
                />
              );
            })}
          </div>
        </div>

        {<div className={styles.error_msg}>{error}</div>}
        <div>
          <button
            className={styles.submit_button}
            onClick={handleOtpAuthentication}
          >
            Verify
          </button>
        </div>
        <div className={styles.additional_inf}>
          <div className={styles.account_creation}>
            Didn’t Receive OTP?{" "}
            <span
              onClick={() => {
                handleResendOtp();
              }}
              className={styles.highlight}
            >
              Resend {countDown > 0 && <>in {countDown}sec</>}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpAuthentication;
