import styles from "../../../../styles/common/view_details/ViewDetails.module.css";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import { useEffect, useState } from "react";
import ApprovalActionButton from "../../../common/approval_action_button/ApprovalActionButton";
import CustomTableV2 from "../../../common/custom_table_v2/CustomTableV2";
import ImageVerification from "../../../common/image_verification/ImageVerification";
import BackDrop from "../../../common/back_drop/BackDrop";
import ApprovalActionModal from "../../../common/approval_action_modal/ApprovalActionModal";
import { useDispatch } from "react-redux";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import FileViewer from "../../../common/image_verification/FileViewer";
import DetailsTable from "../../../common/details_table/DetailsTable";
import PhotoId from "../../../../assets/sections/tenant/profile/photo_id.svg";
import Edit from "../../../../assets/common/card_details/edit.svg";

const ApprovalsDetails = ({
  state,
  header,
  activeRequestId,
  navLinkActive,
  details = [],
}) => {
  const [declineModalState, setDeclineModalState] = useState(false);
  const [approvedModalState, setApprovedModalState] = useState(false);
  const [previewState, setPreviewState] = useState(false);
  const [approvalsDecision, setApprovalsDecision] = useState("");
  const [tenantId, setTenantId] = useState("");
  const dispatch = useDispatch();

  const clickHandler = () => setPreviewState(!previewState);

  const handleGetMoreDetails = (actionType) => {
    if (actionType === "Decline") {
      setDeclineModalState(!declineModalState);
    }
    if (actionType === "Approve") {
      setApprovedModalState(!approvedModalState);
    }
  };

  const [actionType, setActionType] = useState("");

  const [tenantDetails, setTenantDetails] = useState([
    {
      data_type: "Tenant Name",
      data_value_type: "text",
      value: "",
      id: "tenantName",
    },
    {
      data_type: "Building Name",
      data_value_type: "text",
      value: "",
      id: "unitName",
    },
    {
      data_type: "Flat No",
      data_value_type: "text",
      value: "",
      id: "flatNo",
    },
    {
      data_type: "Building Address",
      data_value_type: "text",
      value: "",
      id: "unitAddress",
    },
    {
      data_type: "Email",
      data_value_type: "text",
      value: "",
      id: "email",
    },
    {
      data_type: "Mobile Number",
      data_value_type: "number",
      value: "",
      id: "mobile",
    },
    {
      data_type: "Whatsapp Number",
      data_value_type: "number",
      value: "",
      id: "whatsapp",
    },
    {
      data_type: "Permanent Address",
      data_value_type: "text",
      value: "",
      id: "permanentAddress",
    },
  ]);

  const [emergencyContactDetails, setEmergencyContactDetails] = useState([
    {
      data_type: "Name",
      data_value_type: "text",
      value: "",
      id: "name",
    },
    {
      data_type: "RelationShip",
      data_value_type: "text",
      value: "",
      id: "relation",
    },
    {
      data_type: "Permanent Address",
      data_value_type: "text",
      value: "",
      id: "permanentAddress",
    },
    {
      data_type: "Mobile Number",
      data_value_type: "number",
      value: "",
      id: "mobile",
    },
    {
      data_type: "Whatsapp Number",
      data_value_type: "number",
      value: "",
      id: "whatsapp",
    },
  ]);

  // const [documents, setDocuments] = useState([
  //   {
  //     field_name: "Passport Photo",
  //     field_id: "passportUrl",
  //     type: "file",
  //     fileName: "No File uploaded",
  //     fileId: "",
  //   },
  //   {
  //     field_name: "Drivers Liscence",
  //     field_id: "identificationDocUrl",
  //     type: "file",
  //     fileName: "No File uploaded",
  //     fileId: "",
  //   },
  // ]);

  const [paymentDoc, setPaymentDoc] = useState([
    {
      field_name: "Receipt Copy",
      field_id: "paymentUrl",
      type: "file",
      fileName: "No File uploaded",
      fileId: "",
    },
  ]);

  const [paymentDetails, setPaymentDetails] = useState([
    {
      data_type: "Tenant Name",
      data_value_type: "text",
      value: "",
      id: "tenantName",
    },
    {
      data_type: "Unit Name",
      data_value_type: "text",
      value: "",
      id: "unitName",
    },
    {
      data_type: "Payment Amount",
      data_value_type: "number",
      value: "",
      id: "paymentAmount",
    },
    {
      data_type: "Payment Date",
      data_value_type: "date",
      value: "",
      id: "actualPaymentDate",
    },
    {
      data_type: "Note",
      data_value_type: "text",
      value: "",
      id: "description",
    },
  ]);

  const [signedContractDetails, setSignedContractDetails] = useState([
    {
      data_type: "Tenant Name",
      data_value_type: "text",
      value: "",
      id: "tenantName",
    },
    {
      data_type: "Building Name",
      data_value_type: "text",
      value: "",
      id: "unitName",
    },
    {
      data_type: "Flat No",
      data_value_type: "text",
      value: "",
      id: "flatNo",
    },
    {
      data_type: "Building Address",
      data_value_type: "text",
      value: "",
      id: "unitAddress",
    },
  ]);

  const [documentDetails, setDocumentDetails] = useState([
    {
      data_type: "Tenant Name",
      data_value_type: "text",
      value: "",
      id: "proofType",
    },
    {
      data_type: "Building Name",
      data_value_type: "text",
      value: "",
      id: "documentType",
    },
    {
      data_type: "Flat No",
      data_value_type: "text",
      value: "",
      id: "documentId",
    },
    {
      data_type: "Building Address",
      data_value_type: "text",
      value: "",
      id: "issuer",
    },
    {
      data_type: "Flat No",
      data_value_type: "text",
      value: "",
      id: "issuerPlace",
    },
    {
      data_type: "Flat No",
      data_value_type: "text",
      value: "",
      id: "validUpto",
    },
  ]);

  const [contractDocuments, setContractDocuments] = useState([
    {
      field_name: "Document Proof",
      field_id: "originalContract",
      type: "file",
      fileName: "No File uploaded",
      fileId: "",
    },
    // {
    //   field_name: "Signed Contract",
    //   field_id: "signedContractUrl",
    //   type: "file",
    //   fileName: "No File uploaded",
    //   fileId: "",
    // },
  ]);
  const [document_details, set_document_details] = useState([
    {
      field_name: "Type of Document",
      value: "",
      id: "documentType",
    },
    {
      field_name: "Document Id",
      value: "",
      id: "documentId",
    },
    {
      field_name: "Issue Place",
      value: "",
      id: "issuerPlace",
    },
    {
      field_name: "Issuer",
      value: "",
      id: "issuer",
    },
    {
      field_name: "Valid up to",
      value: "",
      id: "validUpto",
    },
    {
      field_name: "Document Proof",
      value: "",
      id: "asset",
      type: "file",
      originalDocument: "",
      signedDocument: "",
    },
  ]);
  const getRequestData = async () => {
    let approvalType = "";
    if (navLinkActive.includes("Payment")) {
      approvalType = "payment";
    } else if (navLinkActive.includes("Profile")) {
      approvalType = "profile";
    } else {
      approvalType = "document";
    }

    dispatch(showLoader(true));

    let data = {
      requestId: activeRequestId,
      approvalType: approvalType,
    };

    let response = await getSingleData({
      API_NAME: "ADMIN__APPROVALS__GET_APPROVAL_DETAILS",
      additionData: data,
      // urlId: requestId,
    });

    setTenantId(response.tenantId);

    if (response.isApproved === false && response.isRejected === false) {
      setApprovalsDecision("");
    } else if (response.isApproved === true) {
      setApprovalsDecision("Approved");
    } else if (response.isRejected === true) {
      setApprovalsDecision("Rejected");
    }

    if (navLinkActive.includes("Payment")) {
      setPaymentDetails((prev) => {
        return prev.map((item) => {
          if (item.id in response) {
            return { ...item, value: response[item.id] };
          } else {
            return item;
          }
        });
      });

      paymentDoc.map(async (item) => {
        if (item.field_id in response) {
          setPaymentDoc((prev) => {
            return prev.map((item) => {
              if (item.field_id in response) {
                return {
                  ...item,
                  fileName: response[item.field_id].originalName,
                  fileId: response[item.field_id].assetId,
                };
              } else {
                return item;
              }
            });
          });
        }
      });
    } else if (navLinkActive.includes("Profile")) {
      setTenantDetails((prev) => {
        return prev.map((item) => {
          if (item.id in response) {
            return { ...item, value: response[item.id] };
          } else {
            return item;
          }
        });
      });

      // documents.map(async (item) => {
      //   if (item.field_id in response) {
      //     setDocuments((prev) => {
      //       return prev.map((item) => {
      //         if (item.field_id in response) {
      //           return {
      //             ...item,
      //             fileName: response[item.field_id].originalName,
      //             fileId: response[item.field_id].assetId,
      //           };
      //         } else {
      //           return item;
      //         }
      //       });
      //     });
      //   }
      // });

      emergencyContactDetails.map(async (item) => {
        if (
          response?.emergencyContact &&
          item.id in response?.emergencyContact
        ) {
          setEmergencyContactDetails((prev) => {
            return prev.map((item) => {
              if (item.id in response?.emergencyContact) {
                return {
                  ...item,
                  value: response?.emergencyContact[item.id],
                };
              } else {
                return item;
              }
            });
          });
        }
      });
    } else {
      setSignedContractDetails((prev) => {
        return prev.map((item) => {
          if (item.id in response) {
            return { ...item, value: response[item.id] };
          } else {
            return item;
          }
        });
      });

      // contractDocuments.map(async (item) => {
      //   if (item.field_id in response) {
      //     setContractDocuments((prev) => {
      //       return prev.map((item) => {
      //         if (item.field_id in response) {
      //           return {
      //             ...item,
      //             fileName: response[item.field_id].originalName,
      //             fileId: response[item.field_id].assetId,
      //           };
      //         } else {
      //           return item;
      //         }
      //       });
      //     });
      //   }
      // });

      document_details.map(async (item) => {
        if (item.id in response) {
          set_document_details((prev) => {
            return prev.map((item) => {
              if (item.id in response) {
                return {
                  ...item,
                  value: response[item.id],
                };
              }
              if ("originalDocument" in response) {
                response["originalDocument"].tenantId = response?.userId;
                return {
                  ...item,
                  originalDocument: response["originalDocument"],
                };
              }
              if ("signedDocument" in response) {
                response["signedDocument"].tenantId = response?.userId;
                return {
                  ...item,
                  signedDocument: response["signedDocument"],
                };
              } else {
                return item;
              }
            });
          });
        }
      });
    }

    // setAutoActionSuccess(false);
    dispatch(showLoader(false));
  };

  useEffect(() => {
    getRequestData();
  }, []);

  return (
    <div className={styles.view_details}>
      <div className={styles.header_wrap}>
        <span className={styles.title}>
          <img src={BackArrow} alt="" onClick={() => state("Approval")}></img>
          {header}
        </span>
        <div className={styles.header_button}>
          {approvalsDecision === "" && (
            <>
              <ApprovalActionButton
                buttonText={"Approve"}
                click={() => {
                  setActionType("Approve");
                  setApprovedModalState(!approvedModalState);
                }}
              ></ApprovalActionButton>
              <ApprovalActionButton
                buttonText={"Decline"}
                click={() => {
                  setActionType("Decline");
                  setDeclineModalState(!declineModalState);
                }}
              ></ApprovalActionButton>
            </>
          )}
          {approvalsDecision === "Approved" && (
            <ApprovalActionButton
              buttonText={"Approved"}
              click={() => {}}
            ></ApprovalActionButton>
          )}
          {approvalsDecision === "Rejected" && (
            <ApprovalActionButton
              buttonText={"Rejected"}
              click={() => {}}
            ></ApprovalActionButton>
          )}
        </div>
      </div>
      <CustomTableV2
        data={
          navLinkActive.includes("Payment")
            ? paymentDetails
            : navLinkActive.includes("Profile")
            ? tenantDetails
            : signedContractDetails
        }
      />

      {/* {navLinkActive.includes("Profile") &&
        documents.map((item, index) => {
          return (
            <FileViewer
              key={index}
              header={item.field_name}
              fileName={item.fileName}
              fileId={item.fileId}
              tenantId={tenantId}
              state={() => {
                console.log("clicked");
              }}
            />
          );
        })} */}

      {navLinkActive.includes("Profile") && (
        <>
          <div className={styles.header_wrap}>
            <span className={styles.title + " " + styles.mid_table_title}>
              Emergency Contact Details
            </span>
          </div>

          <CustomTableV2 data={emergencyContactDetails} />
        </>
      )}

      {navLinkActive.includes("Payment") &&
        paymentDoc.map((item, index) => {
          return (
            <FileViewer
              key={index}
              header={item.field_name}
              fileName={item.fileName}
              fileId={item.fileId}
              tenantId={tenantId}
              state={() => {
                console.log("clicked");
              }}
            />
          );
        })}

      {navLinkActive.includes("Document") &&
        contractDocuments.map((item, index) => {
          return (
            // <FileViewer
            //   key={index}
            //   header={item.field_name}
            //   fileName={item.fileName}
            //   fileId={item.fileId}
            //   tenantId={tenantId}
            //   state={() => {
            //     console.log("clicked");
            //   }}
            // />
            <div className={styles.documents_wrap}>
              <div className={styles.content_wrap}>
                <DetailsTable
                  headerImg={PhotoId}
                  tableTitle={"Photo ID"}
                  tableData={document_details}
                  // edit={Edit}
                />
              </div>
            </div>
          );
        })}

      {declineModalState && (
        <ApprovalActionModal
          clickHandler={handleGetMoreDetails}
          modalType="Decline"
          buttonText="Send Report"
        />
      )}
      {(declineModalState || approvedModalState) && (
        <ApprovalActionModal
          // clickHandler={handleGetMoreDetails}
          // modalType="Approve"
          // buttonText="Upload Contract"
          activeRequestId={activeRequestId}
          clickHandler={handleGetMoreDetails}
          actionType={actionType}
          navLinkActive={navLinkActive}
        />
      )}
    </div>
  );
};

export default ApprovalsDetails;
