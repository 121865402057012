import styles from "../../../../styles/sections/admin/units/AddUnit.module.css";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import Expenses from "../../../common/expenses/Expenses";
import { unitSchema } from "../../../../utils/validators";
import { useState } from "react";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { useEffect } from "react";

const AddUnit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const unitId = useParams().unitId || null;
  const [leased, setLeased] = useState(false);

  const addUnitDetailsForm = [
    {
      field_name: "Building Name",
      field_id: "unitName",
      placeholder: "Enter Unit Name",
      type: "text",
    },
    {
      field_name: "Flat No",
      field_id: "flatNo",
      placeholder: "Enter Flat No",
      type: "text",
    },
    {
      field_name: "Building Address",
      field_id: "unitAddress",
      placeholder: "Enter Unit Address",
      type: "address",
    },
    {
      field_name: "Number Of Position (Bed)",
      field_id: "noOfPosition",
      placeholder: "Enter Number Of Position",
      type: "number",
    },

    {
      field_name: "Tenant Preferred",
      field_id: "unitType",
      type: "dropdown",
      options: ["Male", "Female", "Family", "All"],
    },
    {
      field_name: "Next Cleaning Date",
      field_id: "nextCleaningDate",
      placeholder: "Enter Next Cleaning Date",
      type: "date",
    },
    {
      field_name: "Cleaning Frequency (Weeks)",
      field_id: "cleaningFrequency",
      placeholder: "Enter Cleaning Frequency",
      type: "number",
    },
  ];

  const leaseDetails = [
    {
      field_name: "Lease Contractor Name",
      field_id: "agentName",
      placeholder: "Enter Contractor Name",
      type: "text",
    },
    {
      field_name: "Lease Taken On",
      field_id: "leaseTakenOn",
      placeholder: "Enter Lease Taken On",
      type: "date",
    },
    {
      field_name: "Lease Expires On",
      field_id: "leaseExpiresOn",
      placeholder: "Enter Lease Expires On",
      type: "date",
    },
    {
      field_name: "Monthly Rent",
      field_id: "monthlyRent",
      placeholder: "Enter Monthly rent",
      type: "number",
    },
    {
      field_name: "Bond Amount",
      field_id: "bondAmount",
      placeholder: "Enter Bond Amount",
      type: "number",
    },
    {
      field_name: "Contractor Email",
      field_id: "contactEmail",
      placeholder: "Enter Contractor Email",
      type: "email",
    },

    {
      field_name: "Contractor Mobile",
      field_id: "contactLandline",
      placeholder: "Enter Contractor Mobile",
      type: "text",
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return addUnitDetailsForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      if (index !== -1) {
        updatedFormFields[index] = {
          ...updatedFormFields[index],
          value,
          error,
          error_message: errorMessage,
        };
      } else {
        // Field not found, you may want to handle this scenario
        console.error(`Field with fieldId "${fieldId}" not found.`);
      }

      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newUnit = {
      leased: leased,
    };
    formFields.map((item) => {
      newUnit[item.field_id] = item.value;
    });

    newUnit = cleanFormObject(newUnit);

    // Validate the form data
    const { error } = unitSchema.validate(newUnit);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        console.log(errorFieldName);

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // upadte
      if (unitId) {
        response = await postFormData({
          form_key: "ADMIN__UNITS__UPDATE_UNIT_DETAILS",
          data: newUnit,
          urlId: unitId,
          method: "put",
        });
      } else {
        // add

        newUnit["availablePosition"] = newUnit["noOfPosition"];
        response = await postFormData({
          form_key: "ADMIN__UNITS__ADD_UNIT",
          data: newUnit,
        });
      }

      if (response.success) {
        // navigate("/admin/units");
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const handleGetMoreDetails = async (unitId) => {
    dispatch(showLoader(true));

    const response = await getSingleData({
      API_NAME: "ADMIN__UNITS__GET_UNIT_DETAILS",
      oneId: unitId,
    });

    Object.keys(response).map((item) => {
      if (item === "leaseTakenOn")
        response[item] = response[item].substring(0, 10);

      handleFieldChange(item, response[item], false, "");
    });

    dispatch(showLoader(false));
  };

  useEffect(() => {
    if (unitId) handleGetMoreDetails(unitId);
  }, [unitId]);

  useEffect(() => {
    if (leased) {
      let newFormFields = [...addUnitDetailsForm, ...leaseDetails];
      setFormFields(newFormFields);
    } else {
      setFormFields(addUnitDetailsForm);
    }
  }, [leased]);

  return (
    <div className={styles.add_unit}>
      <div className={styles.title}>
        <img
          src={BackArrow}
          alt=""
          onClick={() => navigate("/admin/units")}
        ></img>
        {unitId ? "Update Unit Details" : "Add New Unit"}
      </div>
      <ProfileUpdateForm
        form_fields={formFields.slice(0, 7)}
        handleFieldChange={handleFieldChange}
      />

      {/* switch to check if this property is leased */}

      {/* if leased then show the lease details */}

      <div className={styles.title}>
        Lease Details :
        <div className={styles.switch_container}>
          <label className={styles.switch}> I Own this Property </label>
          <input
            type="radio"
            defaultChecked={leased === false}
            name="leased"
            onChange={(e) => setLeased(e.target.value === true ? true : false)}
          />
          <label className={styles.switch}> I Leased this Property </label>
          <input
            type="radio"
            defaultChecked={leased === true}
            name="leased"
            onChange={(e) => setLeased(e.target.value === true ? false : true)}
          />
        </div>
      </div>

      {leased && (
        <ProfileUpdateForm
          form_fields={formFields.slice(7)}
          handleFieldChange={handleFieldChange}
        />
      )}

      <div className={styles.submit_button_container}>
        <button className={styles.submit_button} onClick={formHandler}>
          Add Unit
        </button>
      </div>
    </div>
  );
};

export default AddUnit;
