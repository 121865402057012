import styles from "../../../../styles/sections/tenant/payments/Payments.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";

import StatsBoard from "../../../common/statsboard/StatsBoard";
import RentStatus from "../../../../assets/sections/tenant/home/upcoming_rent.svg";
import LatestPaymentDate from "../../../../assets/sections/tenant/home/last_rent_paid.svg";
import NextDueDate from "../../../../assets/sections/tenant/home/rent_amount.svg";
import EndDate from "../../../../assets/sections/tenant/home/due_amount.svg";
import Approve from "../../../../assets/sections/tenant/request/approve.svg";
import Decline from "../../../../assets/sections/tenant/request/decline.svg";
import Waiting from "../../../../assets/sections/tenant/request/waiting.svg";

import StatsTable from "../../../common/stats_table/StatsTable";
import NavBar from "../../../common/nav_bar/NavBar";
import DownloadLogo from "../../../../assets/sections/tenant/payments/download.svg";
import FormModal from "./FormModal";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../utils/generalFunctions";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import IconButton from "../../../common/icon_button/IconButton";
import { createPortal } from "react-dom";
import { PaymentInvoice } from "../../admin/tenants/PaymentInvoice/PaymentInvoice";
import StatusButton from "../../../common/status_button/StatusButton";
import ActionButton from "../../../common/action_button/ActionButton";
import SearchField from "../../../common/search_field/SearchField";

const Payments = () => {
  const dispatch = useDispatch();
  const [tenantDetailsData, setTenantDetailsData] = useState([]);
  const tenantData = useSelector((state) => state?.userInfo?.tenantInfo);
  const orgInfo = useSelector((state) => state?.userInfo?.organisationInfo);
  const stateDataTotalPages = useSelector((state) => state.totalPages);

  const tenant_icons = [RentStatus, LatestPaymentDate, NextDueDate, EndDate];

  const tenant_details = [
    "Rent Status",
    "Latest Payment Date",
    "Next Due Date",
    "End Date",
  ];

  const tenants_stats_mapping = [
    "rentStatus",
    "latestPaymentDate",
    "nextDueDate",
    "endDate",
  ];

  const dashboard_details_values = {
    rentStatus: "",
    latestPaymentDate: "",
    nextDueDate: "",
    endDate: "",
  };

  const [section, setSection] = useState();

  const payment_statsTable_headers = [
    "Sr No.",
    "Payment Date",
    "Paid Amount",
    "Balance Amount",
    "Payment Mode",
    "Description",
    "Action",
  ];

  const payment_tableFieldMapping = [
    "sr_no",
    "payment_date",
    "paid_amount",
    "balance_amount",
    "payment_mode",
    "description",
    "status",
  ];

  const back_drop_navBar_sub_header = ["Share Paid Details", "Pay Online"];

  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const initialRender = useRef(true);
  const stateDataTableData = useSelector((state) => state.getTableData);

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      // mappedData.unitName = item.unitName;
      // mappedData.unitAddress = item.unitAddress;
      // mappedData.nextCleaningDate = formatDate(item.nextCleaningDate);

      mappedData.sr_no = index + 1;
      mappedData.payment_date = formatDate(item.actualPaymentDate);
      mappedData.payment_category = item.paymentCategory;
      mappedData.payment_mode = item.paymentMode;
      mappedData.paid_amount = item.paymentAmount;
      mappedData.balance_amount = item.balance;
      mappedData.description = item.description;
      mappedData.isApproved = item.isApproved;
      mappedData.status = (
        <StatusButton
          buttonText={
            item.isApproved === true
              ? "Approved"
              : item.isRejected === true
              ? "Declined"
              : "Waiting"
          }
          buttonImage={
            item.isApproved === true
              ? Approve
              : item.isRejected === true
              ? Decline
              : Waiting
          }
          click={() => {}}
        />
      );

      mappedDataList.push(mappedData);
    });

    setPaymentHistoryData(mappedDataList);
  };

  useEffect(() => {
    // if seachText is not same as previous searchText then reset the pageNumber
    if (searchText !== "") {
      setPageNumber(1);
    }

    if (initialRender.current) {
      initialRender.current = false;
      dispatch(resetTableData());
    }
    fetchTableData(
      dispatch,
      "TENANT__PAYMENT_HISTORY",
      pageNumber,
      searchText,
      {
        limit: 5000,
      }
    );
  }, [pageNumber, searchText]);

  useEffect(() => {
    if (stateDataTableData.tenantPaymentHistory?.length > 0)
      processTableData(stateDataTableData.tenantPaymentHistory);
  }, [stateDataTableData.tenantPaymentHistory]);

  useEffect(() => {
    let tenantDetails = [];
    // console.log("tenantData", tenantData);
    Object.keys(tenantData)?.map((item) => {
      tenantDetails.push({
        id: item,
        value: tenantData[item],
      });
    });
    console.log(tenantDetails);
    setTenantDetailsData(tenantDetails);
  }, [tenantData]);

  // useEffect(() => {
  //   handleGetMoreDetails();
  // }, []);

  const [printStart, setPrintStart] = useState(false);

  const Print = () => {
    setPrintStart(true);
    // setTimeout(() => {
    //   window.print();
    //   setPrintStart(false);
    // }, 500);
    setTimeout(() => {
      document.getElementById("root").style.display = "none";
      window.print();
      document.getElementById("root").style.display = "block";
      setPrintStart(false);
    }, 500);
  };

  return (
    <div className={styles.payments}>
      <StatsBoard
        boardName=""
        statIcons={tenant_icons}
        statNames={tenant_details}
        statValues={dashboard_details_values}
        statValuesMapping={tenants_stats_mapping}
      ></StatsBoard>

      <div
        className={
          styles.units_stats_table_actions + " " + styles.hideInDesktop
        }
      >
        <IconButton
          buttonText={"Download Ledger"}
          onClick={() => {
            // print
            Print();
          }}
          buttonIcon={DownloadLogo}
          transparentBg={true}
        ></IconButton>

        <ActionButton
          buttonText={"Pay Rent"}
          onClick={() => {
            setSection("Pay Rent");
          }}
        ></ActionButton>
      </div>
      <StatsTable
        tableTitle={"Payment History"}
        tableHeaders={payment_statsTable_headers}
        tableData={paymentHistoryData}
        tableFieldMapping={payment_tableFieldMapping}
        tableActionsElement={
          <div className={styles.units_stats_table_actions}>
            <IconButton
              buttonText={"Download Ledger"}
              onClick={() => {
                // print
                Print();
              }}
              buttonIcon={DownloadLogo}
              transparentBg={true}
            ></IconButton>

            <ActionButton
              buttonText={"Pay Rent"}
              onClick={() => {
                setSection("Pay Rent");
              }}
            ></ActionButton>
          </div>
        }
        tableActionsElementMobile={
          <div
            className={
              statsStyles.stats_title_actions_container +
              " " +
              statsStyles.stats_title_actions_container_show
            }
          >
            <div className={statsStyles.statsTable_title_container}>
              <div className={statsStyles.stats_table_title}>
                Payment History
              </div>
            </div>
          </div>
        }
        // setPageNumber={setPageNumber}
        totalPages={stateDataTotalPages.adminUnitsTotalPages}
      ></StatsTable>
      {section === "Pay Rent" && (
        <FormModal
          // header={navSections}
          state={setSection}
          active={section}
          // subHeader={back_drop_navBar_sub_header}
        />
      )}
      {printStart &&
        createPortal(
          <PaymentInvoice
            // pass all records where isApproved is true
            rentDetails={paymentHistoryData.filter(
              (item) => item.isApproved === true
            )}
            tenantDetailsData={tenantDetailsData}
            orgInfo={orgInfo}
          />,
          document.body
        )}
    </div>
  );
};

export default Payments;
