import styles from "../../../styles/common/details_card/DetailsCard.module.css";
import Edit from "../../../assets/common/card_details/edit.svg";
import { useState } from "react";
import ActionButton from "../action_button/ActionButton";

const DetailsCard = ({
  details,
  setDetails,
  header,
  image,
  updateDetailsHandler,
  editState,
  setEditState,
}) => {
  return (
    <div className={styles.details_card_wrap}>
      <div className={styles.title_wrap}>
        <span className={styles.card_title}>
          <img src={image} alt="" className={styles.home}></img>
          {header}
        </span>
        <img
          src={Edit}
          alt=""
          onClick={() => setEditState(!editState)}
          className={styles.edit}
        ></img>
      </div>
      <div className={styles.table_content}>
        {details.map((detail, index) => {
          return (
            <div key={index} className={styles.table_data_wrap}>
              <span className={styles.field_label}>{detail.filed_name}</span>
              {editState ? (
                <div>
                  <input
                    type={detail.type}
                    defaultValue={detail.value}
                    placeholder={detail.placeholder}
                    className={
                      styles.edit_input +
                      " " +
                      (detail.error ? styles.field_error : "")
                    }
                    onChange={(event) => {
                      // detail.new_value = event.target.value;
                      // detail.error = false;
                      setDetails(
                        details.map((item) => {
                          if (item.id === detail.id) {
                            item.new_value = event.target.value;
                            item.error = false;
                            item.error_message = "";
                          }
                          return item;
                        })
                      );
                    }}
                  ></input>
                  {detail.error && (
                    <span className={styles.error_message}>
                      {detail.error_message}
                    </span>
                  )}
                </div>
              ) : (
                <span className={styles.field_value}>{detail.value}</span>
              )}
            </div>
          );
        })}
        {editState && (
          <ActionButton
            buttonText={"Update Details"}
            onClick={updateDetailsHandler}
          />
        )}
      </div>
    </div>
  );
};

export default DetailsCard;
