import styles from "../../../../styles/sections/tenant/profile/Profile.module.css";
import BackArrow from "../../../../assets/sections/tenant/profile/back_arrow.svg";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../common/nav_bar/NavBar";
import { useEffect, useState } from "react";
import DetailsCard from "../../../common/details_card/DetailsCard";
import ProfilePic from "../../../../assets/sections/tenant/profile/profile.svg";
import DetailsTable from "../../../common/details_table/DetailsTable";
import PhotoId from "../../../../assets/sections/tenant/profile/photo_id.svg";
import AddressInfo from "../../../../assets/sections/tenant/profile/address_proof.svg";
import TaxInfo from "../../../../assets/sections/tenant/profile/tax_information.svg";
import contract_details_icon from "../../../../assets/sections/tenant/profile/contract_details.svg";
import PersonalDetails from "../../../../assets/sections/tenant/profile/personal_details.svg";
import EmergencyContact from "../../../../assets/sections/tenant/profile/emergency_contact_details.svg";
import Edit from "../../../../assets/common/card_details/edit.svg";
import UploadIcon from "../../../../assets/common/upload.svg";
import { useDispatch } from "react-redux";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import {
  tenantEmergencyContactSchema,
  tenantPersonalSchema,
} from "../../../../utils/validators";
import { setAlert } from "../../../../redux/reducers/AlertSlice";
import { apiEndpointname } from "../../../../utils/constants";
import Axios from "../../../../axios";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { getFileData } from "../../../../generalApiCalls/getFileData";

const Profile = () => {
  const navigate = useNavigate();
  const [section, setSection] = useState("Basic Details");
  const navSections = ["Basic Details", "Documents"];
  const [profileImage, setProfileImage] = useState(null);

  const [editPersonalDetailsForm, setEditPersonalDetailsForm] = useState(false);
  const [editCompanyDetailsForm, setEditCompanyDetailsForm] = useState(false);

  const [personalDetailsForm, setPersonalDetailsForm] = useState([
    {
      id: "tenantName",
      filed_name: "Name",
      placeholder: "Enter Name",
      type: "text",
      value: "",
      new_value: "",
      error: false,
      error_message: "",
    },

    {
      id: "email",
      filed_name: "Email",
      placeholder: "Enter Email",
      type: "email",
      value: "",
      new_value: "",
      error: false,
      error_message: "",
    },
    // {
    //   id: "home_town",
    //   filed_name: "Home Town",
    //   placeholder: "Enter Home Town",
    //   type: "text",
    //   value: "",
    //   new_value: "",
    //   error: false,
    //   error_message: "",
    // },
    {
      id: "permanentAddress",
      filed_name: "Permanent Address",
      placeholder: "Enter Permanent Address",
      type: "text",
      value: "",
      new_value: "",
      error: false,
      error_message: "",
    },
    {
      id: "mobile",
      filed_name: "Mobile",
      placeholder: "Enter Mobile",
      type: "number",
      value: "",
      new_value: "",
      error: false,
      error_message: "",
    },
    {
      id: "whatsapp",
      filed_name: "Whatsapp",
      placeholder: "Enter Whatsapp",
      type: "number",
      value: "",
      new_value: "",
      error: false,
      error_message: "",
    },
  ]);

  const [emergencyDetailsForm, setEmergencyDetailsForm] = useState([
    {
      id: "name",
      filed_name: "Name",
      placeholder: "Enter Name",
      type: "text",
      value: "",
      new_value: "",
      error: false,
      error_message: "",
    },
    {
      id: "relation",
      filed_name: "RelationShip",
      placeholder: "Enter RelationShip",
      type: "text",
      value: "",
      new_value: "",
      error: false,
      error_message: "",
    },
    {
      id: "permanentAddress",
      filed_name: "Permanent Address",
      placeholder: "Enter Permanent Address",
      type: "text",
      value: "",
      new_value: "",
      error: false,
      error_message: "",
    },
    {
      id: "mobile",
      filed_name: "Mobile",
      placeholder: "Enter Mobile",
      type: "number",
      value: "",
      new_value: "",
      error: false,
      error_message: "",
    },
    {
      id: "whatsapp",
      filed_name: "Whatsapp",
      placeholder: "Enter Whatsapp",
      type: "number",
      value: "",
      new_value: "",
      error: false,
      error_message: "",
    },
  ]);

  const [photo_id_details, set_photo_id_details] = useState([
    {
      field_name: "Type of Document",
      value: "",
      id: "documentType",
      placeholder: "Enter Document Type",
    },
    {
      field_name: "Document Id",
      value: "",
      id: "documentId",
      placeholder: "Enter Document Id",
    },
    {
      field_name: "Issue Place",
      value: "",
      id: "issuerPlace",
      placeholder: "Enter Issue Place",
    },
    {
      field_name: "Issuer",
      value: "",
      id: "issuer",
      placeholder: "Enter Issuer",
    },
    {
      field_name: "Valid up to",
      value: "",
      id: "validUpto",
      placeholder: "Enter Valid Upto",
    },
    {
      field_name: "Document Proof",
      value: "",
      id: "asset",
      type: "file",
      originalDocument: "",
    },
  ]);

  const [address_proof_details, set_address_proof_details] = useState([
    {
      field_name: "Type of Document",
      value: "",
      id: "documentType",
      placeholder: "Enter Document Type",
    },
    {
      field_name: "Document Id",
      value: "",
      id: "documentId",
      placeholder: "Enter Document Id",
    },
    {
      field_name: "Issue Place",
      value: "",
      id: "issuerPlace",
      placeholder: "Enter Issue Place",
    },
    {
      field_name: "Issuer",
      value: "",
      id: "issuer",
      placeholder: "Enter Issuer",
    },
    {
      field_name: "Valid up to",
      value: "",
      id: "validUpto",
      placeholder: "Enter Valid Upto",
    },
    {
      field_name: "Document Proof",
      value: "",
      id: "asset",
      type: "file",
      originalDocument: "",
    },
  ]);
  const [tax_info, set_tax_info] = useState([
    {
      field_name: "Type of Document",
      value: "",
      id: "documentType",
      placeholder: "Enter Document Type",
    },
    {
      field_name: "Document Id",
      value: "",
      id: "documentId",
      placeholder: "Enter Document Id",
    },
    {
      field_name: "Issue Place",
      value: "",
      id: "issuerPlace",
      placeholder: "Enter Issue Place",
    },
    {
      field_name: "Issuer",
      value: "",
      id: "issuer",
      placeholder: "Enter Issuer",
    },
    {
      field_name: "Valid up to",
      value: "",
      id: "validUpto",
      placeholder: "Enter Valid Upto",
    },
    {
      field_name: "Document Proof",
      value: "",
      id: "asset",
      type: "file",
      originalDocument: "",
    },
  ]);

  const [agreement_info, set_agreement_info] = useState([
    {
      field_name: "Type of Document",
      value: "Agreement",
      id: "documentType",
      editable: false,
      placeholder: "Enter Document Type",
    },
    {
      field_name: "Document Id",
      value: "NA",
      id: "documentId",
      editable: false,
    },
    {
      field_name: "Issue Place",
      value: "",
      id: "issuerPlace",
      placeholder: "Enter Issue Place",
    },
    {
      field_name: "Issuer",
      value: "",
      id: "issuer",
      placeholder: "Enter Issuer",
    },
    {
      field_name: "Valid up to",
      value: "",
      id: "validUpto",
      placeholder: "Enter Valid Upto",
    },
  ]);

  const dispatch = useDispatch();

  const tagErrorToFields = (error, formName) => {
    error?.details.map((item) => {
      let errorMessage = item.message;

      let errorFieldName = item.path[0];

      if (formName === "personal") {
        setPersonalDetailsForm((prevFormJson) =>
          prevFormJson.map((c) =>
            c.id === errorFieldName
              ? {
                  ...c,
                  error: true,
                  error_message: errorMessage
                    .replace(/"/g, "")
                    .replace(errorFieldName, c.filed_name),
                }
              : c
          )
        );
      }

      if (formName === "emergency") {
        setEmergencyDetailsForm((prevFormJson) =>
          prevFormJson.map((c) =>
            c.id === errorFieldName
              ? {
                  ...c,
                  error: true,
                  error_message: errorMessage
                    .replace(/"/g, "")
                    .replace(errorFieldName, c.filed_name),
                }
              : c
          )
        );
      }
    });
  };

  const updateDetails = async () => {
    // convert new_value to value and make new_value empty
    // setPersonalDetailsForm((prev) => {
    //   return prev.map((item) => {
    //     return {
    //       ...item,
    //       value: item.new_value ? item.new_value : item.value,
    //       new_value: "",
    //     };
    //   });
    // });

    // setEmergencyDetailsForm((prev) => {
    //   return prev.map((item) => {
    //     return {
    //       ...item,
    //       value: item.new_value ? item.new_value : item.value,
    //       new_value: "",
    //     };
    //   });
    // });
    dispatch(
      setAlert({
        alertType: "success",
        alertMessage: "Sent For Approval",
        alertTitle: "Success",
        autoClose: false,
        alertShow: true,
      })
    );
    setEditCompanyDetailsForm(false);
    setEditPersonalDetailsForm(false);
  };

  const updateDetailsHandler = async (event) => {
    event.preventDefault();
    // console.log(formJson);

    dispatch(showLoader(true));

    let personalData = {};

    let emergencyData = {};

    personalDetailsForm.forEach((element) => {
      personalData[element.id] = element.new_value
        ? element.new_value
        : element.value;
    });

    emergencyDetailsForm.forEach((element) => {
      emergencyData[element.id] = element.new_value
        ? element.new_value
        : element.value;
    });

    personalData = cleanFormObject(personalData);
    emergencyData = cleanFormObject(emergencyData);

    console.log("personalData", personalData, emergencyData);

    const personalDataError = tenantPersonalSchema.validate(personalData);
    const emergencyDataError =
      tenantEmergencyContactSchema.validate(emergencyData);

    tagErrorToFields(personalDataError.error, "personal");
    tagErrorToFields(emergencyDataError.error, "emergency");

    console.log("personalDataError", personalDataError);
    console.log("emergencyDataError", emergencyDataError);

    if (personalDataError.error) {
      dispatch(showLoader(false));

      return;
    }

    if (emergencyDataError.error) {
      dispatch(showLoader(false));

      return;
    }

    let data = {
      personal: personalData,
      emergencyContact: emergencyData,
    };

    try {
      // const response = await Axios.request(config);

      let response = await postFormData({
        form_key: "TENANT_PROFILE_UPDATE",
        data: data,
        method: "put",
      });

      if (response.success === true) {
        await updateDetails();
      } else {
        if (response.data.message) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMessage: response.data.message,
              alertTitle: "Error",
              autoClose: false,
              alertShow: true,
            })
          );
        }
      }
    } catch (error) {
      if (error.response?.data?.message) {
        dispatch(
          setAlert({
            alertType: "error",
            alertMessage: error.response.data.message,
            alertTitle: "Error",
            autoClose: false,
            alertShow: true,
          })
        );
      }
    }

    dispatch(showLoader(false));
  };

  const fetchDetails = async (unitId) => {
    dispatch(showLoader(true));

    const response = await getSingleData({
      API_NAME: "TENANT__PERSONAL__GET_DETAILS",
    });

    //   response = {
    //     "personalDetails": {
    //         "name": "Jaydeep Kumar",
    //         "mobile": "08084822841",
    //         "address": "Vill - Parariya , Post - Parariya",
    //         "email": "jaydeepkumarmandal@gmail.com"
    //     },
    //     "companyDetails": {
    //         "organisationName": "Lumiq",
    //         "organisationAddress": "Sec - 62, Noida",
    //         "organisationEmail": "jaydeepkumarmandal@gmail.com"
    //     }
    // }

    setEmergencyDetailsForm((prev) => {
      return prev.map((item) => {
        if (
          response?.emergencyContact &&
          item.id in response?.emergencyContact
        ) {
          return { ...item, value: response["emergencyContact"][item.id] };
        } else {
          return item;
        }
      });
    });

    setPersonalDetailsForm((prev) => {
      return prev.map((item) => {
        if (item.id in response) {
          return { ...item, value: response[item.id] };
        } else {
          return item;
        }
      });
    });

    let responseBlob = await getFileData({
      API_NAME: "ASSET_DOWNLOAD",
      data: {
        assetName: response?.profilePhotoUrl,
      },
    });
    if (responseBlob) {
      responseBlob = responseBlob.fileBlob;
      setProfileImage(URL.createObjectURL(responseBlob));
    }

    const documents = await getSingleData({
      API_NAME: "TENANT_DOCUMENTS_GET",
    });

    if (documents) {
      documents?.map((item) => {
        let setterFunction = null;
        if (item?.proofType === "Photo ID") {
          setterFunction = set_photo_id_details;
        }
        if (item?.proofType === "Address Proof") {
          setterFunction = set_address_proof_details;
        }
        if (item?.proofType === "Tax Information") {
          setterFunction = set_tax_info;
        }

        if (setterFunction) {
          setterFunction((prev) => {
            return prev.map((item1) => {
              if (item1.id === "asset") {
                return { ...item1, originalDocument: item?.originalDocument };
              } else if (item1.id in item) {
                return { ...item1, value: item[item1.id] };
              } else {
                return item1;
              }
            });
          });
        }

        console.log(photo_id_details);
      });
    }

    dispatch(showLoader(false));
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const uploadProfilePhoto = async (asset) => {
    console.log("uploadProfilePhoto");
    let data = { asset: asset };
    const resp = await postFormData({
      form_key: "TENANT_PROFILE_PHOTO_UPLOAD",
      data: data,
      isFile: true,
    });
    console.log("uploadProfilePhoto resp", resp);
  };

  // const getProfileDetails = async () => {

  // }

  return (
    <div className={styles.profile_wrap}>
      <div className={styles.header}>
        <span className={styles.profile} onClick={() => navigate(-1)}>
          <img alt="" src={BackArrow}></img>My Profile
        </span>
        <button
          onClick={() => navigate("/auth/changePassword")}
          className={styles.change_password}
        >
          Change Password
        </button>
      </div>
      <div
        className={
          section === "Basic Details"
            ? `${styles.profile_content} ${styles.profile_content_wrap}`
            : styles.profile_content
        }
      >
        <NavBar state={setSection} active={section} navLinks={navSections} />
        {section === "Basic Details" && (
          <div className={styles.basic_details}>
            <div className={styles.card_wrap}>
              <DetailsCard
                details={personalDetailsForm}
                setDetails={setPersonalDetailsForm}
                header={"Personal Details"}
                image={PersonalDetails}
                updateDetailsHandler={updateDetailsHandler}
                editState={editPersonalDetailsForm}
                setEditState={setEditPersonalDetailsForm}
              />
              <DetailsCard
                details={emergencyDetailsForm}
                setDetails={setEmergencyDetailsForm}
                header={"Emergency Contact Details"}
                image={EmergencyContact}
                updateDetailsHandler={updateDetailsHandler}
                editState={editCompanyDetailsForm}
                setEditState={setEditCompanyDetailsForm}
              />
            </div>
            <div className={styles.profile_img_container}>
              <input
                type="file"
                className={styles.file_input}
                onChange={(e) => {
                  e.preventDefault();
                  // console.log(e.target.files[0]);
                  uploadProfilePhoto(e.target.files[0]);
                }}
              ></input>
              <img
                src={profileImage ? profileImage : ProfilePic}
                alt=""
                className={styles.profile_img}
              ></img>
              <div className={styles.upload_icon_container}>
                <img
                  src={UploadIcon}
                  alt="Upload Icon"
                  className={styles.upload_icon}
                ></img>
              </div>
            </div>
          </div>
        )}
      </div>
      {section === "Documents" && (
        <div className={styles.documents_wrap}>
          <div className={styles.content_wrap}>
            <DetailsTable
              headerImg={PhotoId}
              tableTitle={"Photo ID"}
              tableData={photo_id_details}
              edit={Edit}
            />
            <DetailsTable
              headerImg={AddressInfo}
              tableTitle={"Address Proof"}
              tableData={address_proof_details}
              edit={Edit}
            />
          </div>
          <div className={styles.content_wrap}>
            <DetailsTable
              headerImg={TaxInfo}
              tableTitle={"Tax Information"}
              tableData={tax_info}
              edit={Edit}
            />
            <DetailsTable
              headerImg={contract_details_icon}
              tableTitle={"Agreement Info"}
              tableData={agreement_info}
              edit={Edit}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
