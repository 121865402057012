import React, { useEffect, useState } from "react";
import "./PaymentInvoiceStyle.css";
import Logo2 from "../../../../assets/common/rentIq_logo_invoice.png";
import { formatDate } from "../../../../utils/generalFunctions";
export const PaymentInvoice = ({ invoiceDetails, orgInfo }) => {
  const [totalPaymentAmount, setTotalPaymentAmount] = useState("₹ 0");
  const [totalCreditBalance, setTotalCreditBalance] = useState("₹ 0");
  const [totalDeposit, setTotalDeposit] = useState("₹ 0");
  useEffect(() => {
    console.log("invoiceDetails", invoiceDetails);

    // setTotalCreditBalance(
    //   "₹ " +
    //     invoiceDetails?.paymentData?.reduce((acc, curr) => {
    //       return acc + parseInt(curr.balance !== "" ? curr.balance : 0);
    //     }, 0)
    // );
    // setTotalPaymentAmount(
    //   "₹ " +
    //     invoiceDetails?.paymentData?.reduce((acc, curr) => {
    //       return acc + parseInt(curr.charges !== "" ? curr.charges : 0);
    //     }, 0)
    // );

    console.log(
      "invoiceDetails?.paymentData?.length",
      invoiceDetails?.paymentData?.length
    );
    console.log("invoiceDetails?.paymentData", invoiceDetails?.paymentData);

    setTotalCreditBalance(
      "₹ " +
        invoiceDetails?.paymentData[
          invoiceDetails?.paymentData?.length - 1
        ]?.balance?.toString() || 0
    );
    setTotalPaymentAmount(
      "₹ " +
        invoiceDetails?.paymentData?.reduce((acc, curr) => {
          return acc + parseInt(curr.charges !== "" ? curr.charges : 0);
        }, 0)
    );
    setTotalDeposit(
      "₹ " +
        invoiceDetails?.paymentData?.reduce((acc, curr) => {
          return acc + parseInt(curr?.deposit !== "" ? curr?.deposit : 0);
        }, 0)
    );

    // setTotalPaymentAmount("₹ " + totalPaymentAmount);
    // setTotalCreditBalance("₹ " + totalCreditBalance);
  }, [invoiceDetails]);

  // useEffect(() => {
  //   // print below div as invoice after page load 2 sec
  //   setTimeout(() => {
  //     Print();
  //   }, 2000);
  // });

  return (
    <div className={"tenants_invoice_warapper"} id="tenants_invoice_warapper">
      <div className={"tenants_invoice"}>
        <div className={"header"}>
          {/* <img
            className="rectangle"
            alt="Rectangle"
            src="https://c.animaapp.com/245SKHjh/img/rectangle-34625722.svg"
          />
          <img
            className="img"
            alt="Rectangle"
            src="https://c.animaapp.com/245SKHjh/img/rectangle-34625723.svg"
          /> */}

          <div className={"rectangles"}>
            <img src={Logo2} alt="Logo" />
            <div className={"rectangle rectangle2"}></div>
            <div className={"rectangle rectangle3"}>
              <div className={"heading_textwrapper"}>
                <div className="heading_title">RENTAL LEGDGER</div>
                <a
                  className="heading_link"
                  href="https://www.rentiq.in/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  www.rentIQ.com
                </a>
              </div>
            </div>
          </div>
          <div className={"mid_title_container"}>
            {/* <div className={"title1"}>INVOICE</div> */}
            <div className={"title1"}>{orgInfo?.organisationName}</div>
            <div>GSTIN: {orgInfo?.GST}</div>

            {/* <div className={"title2"}>ABN: 11605961215</div> */}
          </div>

          <div className={"invoice_details"}>
            <div className="left_details">
              <div>Date: {formatDate(invoiceDetails?.invoiceDate)}</div>
              <div>Tenant Name: {invoiceDetails?.customerName}</div>
              <div>Address: {invoiceDetails?.customerAddress}</div>
              <div>Accommodation Type: {invoiceDetails?.accommodationType}</div>
            </div>
            <div className="right_details">
              {/* <div>ABN: 11605961215</div> */}
              <div>Invoice Number : {invoiceDetails?.invoiceNumber}</div>
              <div>PAN No: {invoiceDetails?.panNumber}</div>
              {/* <div className="text-wrapper-51">Payment Type: Cash, EFTPOS</div> */}
              <div>Arrival: {formatDate(invoiceDetails.arrivalDate)}</div>
              <div>Departure: {formatDate(invoiceDetails.departureDate)}</div>
            </div>
          </div>
        </div>

        <center>
          <div className={"invoice_seperator"}></div>
        </center>

        <div className={"invoice_body"}>
          <table className={"invoice_data_table"}>
            {/* <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Payment Type</th>
                <th>Mode of Payment</th>
                <th>Charges (INR)</th>
                <th>Balance</th>
              </tr>
            </thead> */}
            {/* <tbody> */}
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Mode of Payment</th>
              <th>Charges (INR)</th>
              <th>Deposit (INR)</th>
              <th>Balance (INR)</th>
            </tr>
            {invoiceDetails?.paymentData?.map((rentDetail, index) => (
              <tr key={index}>
                <td>{formatDate(rentDetail.date)}</td>
                <td>{rentDetail.description}</td>
                <td>{rentDetail.modeOfPayment}</td>
                <td>{rentDetail.charges}</td>
                <td>{rentDetail.deposit}</td>
                <td>{rentDetail.balance}</td>
              </tr>
            ))}

            <tr className={"data_summary"}>
              {/* Merge four cell */}
              <td colSpan="3">Summary of Payment</td>
              <td>{totalPaymentAmount}</td>
              <td>{totalDeposit}</td>
              <td>{totalCreditBalance}</td>
            </tr>
            <tr className={"data_summary"}>
              {/* Merge four cell */}
              <td colSpan="5">Total amount paid (Including GST)</td>
              <td>{totalDeposit}</td>
            </tr>
            {/* </tbody> */}
          </table>
        </div>

        <div className={"invoice_footer"}>
          <center>
            <div className={"invoice_seperator"}></div>
          </center>

          <p className="footer_line1">
            Please contact us via Email: {orgInfo?.organisationEmail}
          </p>
          <p className="footer_line2">
            Address: {orgInfo?.organisationAddress}
          </p>
          <p>{window.location.href}</p>
        </div>
      </div>
    </div>
  );
};
