import React from "react";
import styles from "../../../styles/common/action_button/ActionButton.module.css";

// const ActionButton = ({ buttonText, click, edit }) => {
//   return (
//     <button className={styles.update_details} onClick={() => click(!edit)}>
//       {buttonText}
//     </button>
//   );
// };
const ActionButton = ({ buttonText, onClick }) => {
  return (
    <button className={styles.update_details} onClick={onClick}>
      {buttonText}
    </button>
  );
};

export default ActionButton;
