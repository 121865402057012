import styles from "../../../styles/common/nav_bar/NavBar.module.css";

const NavBar = (props) => {
  return (
    <div
      className={
        styles.nav_bar_header +
        " " +
        (props?.extraClass ? props.extraClass : "")
      }
    >
      {props.navLinks.map((navLink, index) => {
        return (
          <span
            onClick={() => props.state(navLink)}
            className={props.active === navLink ? styles.active : ""}
            key={index}
          >
            {navLink}
          </span>
        );
      })}
    </div>
  );
};

export default NavBar;
