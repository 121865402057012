import styles from "../../../../styles/sections/tenant/home/Home.module.css";
import RentStatus from "../../../../assets/sections/tenant/home/upcoming_rent.svg";
import LatestPaymentDate from "../../../../assets/sections/tenant/home/last_rent_paid.svg";
import NextDueDate from "../../../../assets/sections/tenant/home/rent_amount.svg";
import EndDate from "../../../../assets/sections/tenant/home/due_amount.svg";
import StatsBoard from "../../../common/statsboard/StatsBoard";
import DetailsTable from "../../../common/details_table/DetailsTable";
import unit_details_img from "../../../../assets/sections/tenant/home/unit_details.svg";
import CoTenants from "../../../../assets/sections/tenant/home/co_tenants.svg";
import { useEffect, useState } from "react";
import CustomTableV2 from "../../../common/custom_table_v2/CustomTableV2";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { STATS_KEYS } from "../../../../utils/constants";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { formatDate } from "../../../../utils/generalFunctions";

const Home = () => {
  const tenant_icons = [RentStatus, LatestPaymentDate, NextDueDate, EndDate];
  const statePageStats = useSelector((state) => state.pageStats);

  const tenant_details = [
    "Upcoming Rent",
    "Latest Payment Date",
    "Rent Amount",
    "Due Amount",
  ];

  const tenants_stats_mapping = [
    "nextDueDate",
    "latestPaymentDate",
    "rentAmount",
    "dueAmount",
  ];

  const [unitDetails, setUnitDetails] = useState([
    {
      id: "unitName",
      field_name: "Unit Name",
      value: "",
    },
    {
      id: "flatNo",
      field_name: "Flat No",
      value: "",
    },
    {
      id: "unitAddress",
      field_name: "Unit Address",
      value: "",
    },
    {
      id: "startDate",
      field_name: "Staying From",
      value: "",
    },
    {
      id: "endDate",
      field_name: "End Date",
      value: "",
    },

    // {
    //   field_name: "Contact Name",
    //   value: "Rohit Naik",
    // },
    // {
    //   field_name: "Mobile",
    //   value: "1234567890",
    // },
  ]);

  const [co_tenants, set_co_tenants] = useState([]);
  const dispatch = useDispatch();

  const getCoTenantsAndUnitDetails = async () => {
    dispatch(showLoader(true));
    const response = await getSingleData({
      API_NAME: "TENANT__HOME__COTENANTS",
    });

    if (typeof response === "object") {
      set_co_tenants([
        {
          field_name: "Name",
          value: "Phone Number",
        },
      ]);
      response.cotenant?.map((item) => {
        set_co_tenants((prev) => {
          return [
            ...prev,
            {
              field_name: item.tenantName,
              value: item.mobile,
            },
          ];
        });
      });
    }

    if (typeof response === "object") {
      unitDetails.map((item) => {
        if (item.id === "unitName") item.value = response.unitName;
        if (item.id === "flatNo") item.value = response.flatNo;
        if (item.id === "unitAddress") item.value = response.unitAddress;
        if (item.id === "startDate")
          item.value = formatDate(response.startDate);
        if (item.id === "endDate") item.value = formatDate(response.endDate);
      });
    }

    dispatch(showLoader(false));
  };

  useEffect(() => {
    // console.log("useEffect");
    getCoTenantsAndUnitDetails();
    fetchPageStats(dispatch, STATS_KEYS.EMPLOYEE__TENANTS__STATS_INTERNAL, "");
  }, []);

  return (
    <div className={styles.home}>
      <StatsBoard
        boardName=""
        statIcons={tenant_icons}
        statNames={tenant_details}
        statValues={statePageStats?.adminTenantStatsInternal}
        statValuesMapping={tenants_stats_mapping}
      ></StatsBoard>
      <div className={styles.content_wrap}>
        <DetailsTable
          headerImg={unit_details_img}
          tableTitle={"Unit Details"}
          tableData={unitDetails}
        />
        {co_tenants.length > 1 && (
          <DetailsTable
            headerImg={CoTenants}
            tableTitle={"Co-Tenants"}
            tableData={co_tenants}
          />
        )}
      </div>
    </div>
  );
};

export default Home;
