import React, { useEffect, useState } from "react";
import styles from "../../../styles/common/header/Header.module.css";
import FullPageIcon from "../../../assets/common/header/expand.svg";
import CollapsePageIcon from "../../../assets/common/header/collapse_page.svg";
import { useNavigate } from "react-router-dom";
import NotificationIcon from "../../../assets/common/header/notification.svg";
import ProfileIcon from "../../../assets/common/header/user.svg";
import MyProfileIcon from "../../../assets/common/header/MyProfileIcon.svg";
import SignOutIcon from "../../../assets/common/header/Sign_out.svg";
import DashboardExpand from "../../../assets/sections/tenant/tenant_navbar/expand.svg";
import TenantNavigationBar from "../../sections/tenant/tenant_navbar/TenantNavigationBar";
import NavigationBar from "../../sections/admin/navigation_bar/NavigationBar";
import { USER_TYPE } from "../../../utils/constants";
import { getSingleData } from "../../../generalApiCalls/getSingleData";
import {
  updateEmployeeInfo,
  updateOrganisationInfo,
  updateTenantInfo,
} from "../../../redux/reducers/UserSlicer";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const [fullScreen, setFullScreen] = useState(false);
  const [profileState, setProfileState] = useState(false);
  const tenantData = useSelector((state) => state.userInfo);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function toggleFullScreen() {
    if (fullScreen) {
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      document.documentElement.requestFullscreen();
      setFullScreen(true);
    }
  }

  const getTenantDetails = async () => {
    if (localStorage.getItem("userType") === "TENANT") {
      const response = await getSingleData({
        API_NAME: "TENANT__PERSONAL__GET_DETAILS",
      });

      dispatch(
        updateTenantInfo({
          name: response?.tenantName,
          tenantName: response?.tenantName,
          email: response?.email,
          mobile: response?.mobile,
          startDate: response?.startDate,
          endDate: response?.endDate,
          unitName: response?.unitName,
          unitAddress: response?.unitAddress,
          flatNo: response?.flatNo,
          panNumber: response?.panNumber,
          isActive: response?.isActive,
        })
      );
    }
  };

  const getEmployeeInfo = async () => {
    const response = await getSingleData({
      API_NAME: "ADMIN_PROFILE_GET",
    });

    dispatch(
      updateEmployeeInfo({
        name: response?.personalDetails.name,
        email: response?.personalDetails.email,
        mobile: response?.personalDetails.mobile,
        address: response?.personalDetails.address,
      })
    );

    dispatch(
      updateOrganisationInfo({
        organisationAddress: response?.companyDetails.organisationAddress,
        organisationName: response?.companyDetails.organisationName,
        organisationEmail: response?.companyDetails.organisationEmail,
        GST: response?.companyDetails.GST,
      })
    );
  };

  const getOrganisationDetails = async () => {
    const response = await getSingleData({
      API_NAME:
        localStorage.getItem("userType") === "TENANT"
          ? "TENANT_GET_ORGANISATION"
          : "ADMIN_GET_ORGANISATION",
    });

    dispatch(
      updateOrganisationInfo({
        organisationAddress: response?.organisationAddress,
        organisationName: response?.organisationName,
        organisationEmail: response?.organisationEmail,
        GST: response?.GST,
      })
    );
  };

  useEffect(() => {
    if (localStorage.getItem("userType") === "TENANT") {
      getTenantDetails();
      getOrganisationDetails();
    } else if (localStorage.getItem("userType") === "ADMIN") {
      getEmployeeInfo();
    }
  }, []);

  const signOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  const [navigationState, setNavigationState] = useState(false);

  return (
    <>
      {navigationState && (
        <div
          className={styles.nav_background}
          onClick={() => {
            setNavigationState(false);
          }}
        >
          {localStorage.getItem("userType") === USER_TYPE.TENANT ? (
            <TenantNavigationBar
              state={navigationState}
              setState={setNavigationState}
            />
          ) : (
            <NavigationBar
              state={navigationState}
              setState={setNavigationState}
            />
          )}
        </div>
      )}
      <div className={styles.admin_dashboard_header}>
        <span className={styles.user_name}>
          <img
            src={DashboardExpand}
            alt=""
            className={styles.collapse_button}
            onClick={() => {
              setNavigationState(!navigationState);
            }}
          ></img>
          <span>Hello {tenantData.tenantInfo.name || "Admin"}</span>
        </span>
        <div className={styles.admin_dashboard_header_actions}>
          <img
            src={!fullScreen ? FullPageIcon : CollapsePageIcon}
            alt="FullPageIcon"
            onClick={() => {
              toggleFullScreen();
            }}
          ></img>
          {/* <img
            src={NotificationIcon}
            alt="NotificationIcon"
            onClick={() => {
              // navigate("/tenant/notification");
              if (localStorage.getItem("userType") === USER_TYPE.TENANT) {
                navigate("/tenant/notification");
              } else {
                navigate("/admin/notification");
              }
            }}
          ></img> */}
          <img
            src={ProfileIcon}
            alt="ProfileIcon"
            onClick={() => {
              setProfileState(!profileState);
            }}
          ></img>
        </div>
        {profileState && (
          <div
            className={styles.profile}
            onMouseLeave={() => {
              setProfileState(false);
            }}
          >
            <span
              className={styles.profile_content}
              onClick={() => {
                if (localStorage.getItem("userType") === USER_TYPE.TENANT) {
                  navigate("/tenant/profile");
                } else {
                  navigate("/admin/profile");
                }
              }}
            >
              <img src={MyProfileIcon} alt=""></img> My Profile
            </span>
            <span
              className={styles.profile_content}
              onClick={() => {
                signOut();
              }}
            >
              <img src={SignOutIcon} alt=""></img> Sign Out
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
