import styles from "../../../styles/common/form_dropdown/FormDropDown.module.css";
import { useEffect, useState } from "react";
import DropDown from "../../../assets/common/dropdown_logo.svg";

const FormDropDown = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [state, setState] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSelect = (option) => {
    setSelectedOption(option.name || option); // Set the name property if it's an object
    setState(!state);
    if (option.name === "No") {
      props.selectHandler(props.id, false);
    } else {
      props.selectHandler(props.id, option.id || option); // Set the id property if it's an object
    }
  };

  const filteredOptions = props.options.filter((option) => {
    if (typeof option === "object") {
      return option.name.toLowerCase().includes(searchTerm.toLowerCase());
    } else {
      return option.toLowerCase().includes(searchTerm.toLowerCase());
    }
  });

  useEffect(() => {
    if (props.value) {
      props.options.map((option) => {
        if (typeof option === "object") {
          if (option.id === props.value) {
            setSelectedOption(option.name);
          }
        } else {
          if (option === props.value) {
            setSelectedOption(option);
          }
        }
      });
    }
  }, [props.value]);

  return (
    <div
      className={
        selectedOption
          ? `${styles.select_active} ${styles.select}`
          : `${styles.select_inactive} ${styles.select}`
      }
      onClick={() => setState(!state)}
    >
      <div className={styles.main_secion}>
        {state ? (
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
        ) : (
          <span>
            {selectedOption.name || selectedOption || (
              <span className={styles.placeholder}>
                Please Select an option
              </span>
            )}
          </span>
        )}
        <img
          src={DropDown}
          alt=""
          className={state ? "" : styles.drop_down_icon_active}
          onClick={() => setState(!state)}
        ></img>
      </div>
      {state && filteredOptions.length > 0 && (
        <div className={styles.option_wrap}>
          <div className={styles.option_wrap_inner}>
            {filteredOptions.map((option, index) => (
              <span
                key={index}
                onClick={() => handleSelect(option)}
                className={styles.choices}
              >
                {option.name || option}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormDropDown;
