// This file contains general functions that are used in multiple places in the app

// This function is used to clean the form object before sending it to the backend
const cleanFormObject = (formObject) => {
  Object.keys(formObject).forEach((key) => {
    if (formObject[key] == null || formObject[key] === "") {
      delete formObject[key];
    } else if (typeof formObject[key].name === "string") {
    } else if (typeof formObject[key] === "object") {
      Object.keys(formObject[key]).forEach((internalKey) => {
        if (typeof formObject[key][internalKey] === "object") {
          Object.keys(formObject[key][internalKey]).forEach((internalKey2) => {
            if (
              formObject[key][internalKey][internalKey2] == null ||
              formObject[key][internalKey][internalKey2] === ""
            ) {
              delete formObject[key][internalKey][internalKey2];
            }
          });
        }
        if (
          Object.keys(formObject[key][internalKey]).length === 0 &&
          formObject[key][internalKey].constructor === Object
        ) {
          delete formObject[key][internalKey];
        }
      });
    }
    if (
      typeof formObject[key] === "object" &&
      Object.keys(formObject[key]).length === 0 &&
      typeof formObject[key].name !== "string"
    ) {
      delete formObject[key];
    }
  });

  return formObject;
};

const formatDate = (inputDate) => {
  const date = new Date(inputDate);

  if (inputDate === null || inputDate === undefined || inputDate === "") {
    return "";
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  let result = `${day}/${month}/${year}`;

  return result;
};

const numberWithCommas = (number) => {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (err) {
    return number;
  }
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export { cleanFormObject, formatDate, numberWithCommas, getKeyByValue };
