import "./App.css";
import Login from "./components/authentication/Login";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignUp from "./components/authentication/SignUp";
import ChangePassword from "./components/authentication/ChangePassword";
import Tenant from "./components/sections/tenant/Tenant";
import Admin from "./components/sections/admin/Admin";
import SetPassword from "./components/authentication/SetPassword";
import { useSelector } from "react-redux";
import Alert from "./components/common/AlertBox/Alert";
import Loader from "./components/common/loader/Loader";

function App() {
  const isSuperAdmin = () => {
    let token = localStorage.getItem("accessToken");
    let userType = localStorage.getItem("userType");

    if (token !== null && token !== undefined && token !== "") {
      return userType === "ADMIN";
    } else {
      return false;
    }
  };

  const isTenant = () => {
    let token = localStorage.getItem("accessToken");
    let userType = localStorage.getItem("userType");

    if (token !== null && token !== undefined && token !== "") {
      return userType === "TENANT";
    } else {
      return false;
    }
  };

  // Protected route component for admin section
  const ProtectedRoutes = ({ element }) => {
    if (isSuperAdmin()) {
      return element;
    } else if (isTenant()) {
      return element;
    } else {
      alert("You are not authorized to access this page...");
      return <Navigate to="/" />;
    }
  };

  const RediredtToLogin = () => {
    return <Navigate to="/login" />;
  };
  // const stateData = useSelector((state) => state.loader);

  return (
    <div className="App">
      {<Alert />}
      {<Loader />}

      {/* {stateData.isLoading && <Loader />} */}

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RediredtToLogin />} />

          <Route
            path="/login/setPassword/:tempToken?"
            element={<SetPassword />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/signUp" element={<SignUp />} />

          {/* <Route path="/changePassword" element={<ChangePassword />} /> */}
          {/* <Route
            path="/changePasswordSuccess"
            element={<ChangePasswordSuccess />}
          /> */}
          {/* <Route path="/tenant/*" element={<Tenant />} />
          <Route path="/admin/*" element={<Admin />} /> */}
          <Route
            path="/admin/*"
            element={<ProtectedRoutes element={<Admin />} />}
          />
          <Route path="/auth/changePassword" element={<ChangePassword />} />
          <Route
            path="/tenant/*"
            element={<ProtectedRoutes element={<Tenant />} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
