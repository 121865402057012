import styles from "../../../../styles/sections/admin/car_parking/CarParkingForm.module.css";
import { useState } from "react";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { adminPaymentValidationSchema } from "../../../../utils/validators";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { useDispatch } from "react-redux";

const AddPaymentRecordForm = ({ setSendContractForm, tenantId }) => {
  const dispatch = useDispatch();

  let form_fields = [
    {
      field_name: "Payment Category",
      field_id: "paymentCategory",
      placeholder: "Enter Payment Category",
      type: "dropdown",
      options: ["Rent", "Bond", "Other"],
    },
    {
      field_name: "Payment Mode",
      field_id: "paymentMode",
      placeholder: "Enter Payment Mode",
      type: "dropdown",
      options: ["CREDIT CARD", "CASH", "NET BANKING"],
    },
    {
      field_name: "Rent Amount",
      field_id: "rentAmount",
      placeholder: "Enter Rent Amount",
      type: "number",
    },
    {
      field_name: "Paid Amount",
      field_id: "paymentAmount",
      placeholder: "Enter Payment Amount",
      type: "number",
    },
    // {
    //   field_name: "Balance Amount",
    //   field_id: "balance",
    //   placeholder: "Enter Balance Amount",
    //   type: "number",
    // },
    {
      field_name: "Payment Date",
      field_id: "actualPaymentDate",
      placeholder: "Enter Payment Date",
      type: "date",
    },
    {
      field_name: "Paid For Months",
      field_id: "rentFrequency",
      placeholder: "Enter No. of Months",
      type: "number",
    },
    {
      field_name: "Note",
      field_id: "description",
      placeholder: "Enter Description",
      type: "text",
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return form_fields.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newPayment = {};
    formFields.map((item) => {
      newPayment[item.field_id] = item.value;
    });

    newPayment = cleanFormObject(newPayment);

    // Validate the form data
    const { error } = adminPaymentValidationSchema.validate(newPayment);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // add
      response = await postFormData({
        form_key: "ADMIN__TENANTS__ADD_PAYMNET_RECORD",
        data: newPayment,
        urlId: tenantId,
      });

      if (response.success) {
        // navigate("/tenant/payment");
        window.location.reload();
        // alert(response.message);
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  return (
    <div className={styles.form_modal}>
      <form className={styles.form_container}>
        <div className={styles.header}>
          <div className={styles.title}>Add Payment Record</div>
          <button
            onClick={() => {
              setSendContractForm(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>
        </div>
        <ProfileUpdateForm
          form_fields={form_fields}
          handleFieldChange={handleFieldChange}
          submit="Add Record"
          formHandler={formHandler}
        />
      </form>
    </div>
  );
};

export default AddPaymentRecordForm;
