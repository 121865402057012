import styles from "../../styles/authentication/Login.module.css";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Axios from "../../axios";

import passwordIcon from "../../assets/authentication/password_img.svg";
import eye_closed from "../../assets/authentication/eye_closed.svg";
import eye_open from "../../assets/authentication/eye_open.svg";
import user_email from "../../assets/authentication/user_email.svg";

import user_red from "../../assets/authentication/user_img_red.svg";
import passwordIcon_red from "../../assets/authentication/password_img_red.svg";
import eye_closed_red from "../../assets/authentication/eye_closed_red.svg";
import eye_open_red from "../../assets/authentication/eye_open_red.svg";
import { useDispatch } from "react-redux";
import { showLoader } from "../../redux/reducers/loaderSlice";
import { cleanFormObject } from "../../utils/generalFunctions";
import {
  changePasswordValidation,
  loginValidation,
} from "../../utils/validators";
import { setAlert } from "../../redux/reducers/AlertSlice";
import { USER_TYPE, apiEndpointname } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import OtpAuthentication from "./OtpAuthentication";

const LoginForm = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [formNumber, setFormNumber] = useState(1);
  const [formJson, setFormJson] = useState([
    {
      id: 1,
      title: "Account Login",
      form_components: [
        {
          id: "email",
          title: "Email address",
          icon: user_email,
          error_icon: user_red,
          placeholder: "Enter your email",
          type: "email",
          value: "",
          error: false,
        },
        {
          id: "password",
          title: "Password",
          icon: passwordIcon,
          error_icon: passwordIcon_red,
          rightside_icon: eye_closed,
          error_rightside_icon: eye_closed_red,
          placeholder: "**********************",
          type: "password",
          value: "",
          error: false,
          showPassword: false,
        },
      ],
    },
  ]);

  const tagErrorToFields = (error) => {
    error?.details.map((item) => {
      let errorMessage = item.message;

      let errorFieldName = item.path[0];

      formJson.map((form, index) => {
        form.form_components.map((component, index2) => {
          if (component.id === errorFieldName) {
            setFormJson((prevFormJson) => {
              const updatedFormJson = [...prevFormJson];
              updatedFormJson[index].form_components = updatedFormJson[
                index
              ].form_components.map((c, index3) =>
                index2 === index3
                  ? {
                      ...c,
                      error: true,
                      error_message: errorMessage
                        .replace(/"/g, "")
                        .replace(errorFieldName, c.title),
                    }
                  : c
              );
              return updatedFormJson;
            });
          }
        });
      });
    });
  };

  const handleLogin = async () => {
    dispatch(showLoader(true));

    let loginData = {
      email: formJson[0].form_components[0].value,
      password: formJson[0].form_components[1].value,
    };

    loginData = cleanFormObject(loginData);

    const loginDataError = loginValidation.validate(loginData);

    tagErrorToFields(loginDataError.error);

    if (loginDataError.error) {
      setFormNumber(1);
      dispatch(
        setAlert({
          alertType: "error",
          alertMessage: "Please fill the Passwords correctly",
          alertTitle: "Error",
          autoClose: false,
          alertShow: true,
        })
      );
      dispatch(showLoader(false));

      return;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,

      url: "api/v1" + apiEndpointname.LOGIN,

      data: loginData,
    };

    try {
      const response = await Axios.request(config);

      console.log(response);

      if (response.status === 200 || response.status === 201) {
        // setFormNumber(2);
        // navigate("/login");
        if (response.data.success === true) {
          if (response.data.userType === USER_TYPE.ADMIN) {
            setFormNumber(2);
          } else {
            const accessToken = response.headers.authorization.split(" ")[1];
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("userType", response.data.userType);
            window.open("/tenant/home", "_self");
          }
        }
      } else {
        if (response.data.message) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMessage: response.data.message,
              alertTitle: "Error",
              autoClose: false,
              alertShow: true,
            })
          );
        }
      }
    } catch (error) {
      if (error.response?.data?.message) {
        dispatch(
          setAlert({
            alertType: "error",
            alertMessage: error.response.data.message,
            alertTitle: "Error",
            autoClose: false,
            alertShow: true,
          })
        );
      }
    }

    dispatch(showLoader(false));
  };

  return formNumber === 1 ? (
    <div className={styles.form}>
      {formNumber === 1 && (
        <div className={styles.form_title}>
          {formJson[formNumber - 1].title}
        </div>
      )}

      <div className={styles.form_components}>
        {formNumber === 1 &&
          formJson[formNumber - 1]?.form_components.map((component) => {
            return (
              <div className={styles.form_component} key={component.title}>
                <div className={styles.title}>{component.title}</div>
                <div
                  className={
                    styles.input_container +
                    " " +
                    (component.error && styles.input_container_error)
                  }
                >
                  <img
                    src={
                      component.error ? component.error_icon : component.icon
                    }
                    alt={component.title}
                  />
                  <input
                    type={
                      component.type === "password"
                        ? component.showPassword
                          ? "text"
                          : "password"
                        : component.type
                    }
                    placeholder={component.placeholder}
                    className={
                      styles.value +
                      (component.type === "password"
                        ? " " + styles.password_input
                        : "")
                    }
                    onChange={(e) => {
                      // Update the component's value in the state
                      setFormJson((prevFormJson) => {
                        const updatedFormJson = [...prevFormJson];
                        updatedFormJson[formNumber - 1].form_components =
                          updatedFormJson[formNumber - 1].form_components.map(
                            (c) =>
                              c.title === component.title
                                ? {
                                    ...c,
                                    value: e.target.value,
                                    error: false,
                                    error_message: "",
                                  }
                                : c
                          );
                        return updatedFormJson;
                      });
                    }}
                    value={component.value} // Use 'value' instead of 'defaultValue'
                  />

                  {component.rightside_icon && (
                    <img
                      className={styles.password_reveal}
                      src={
                        component.showPassword
                          ? component.error
                            ? eye_open_red
                            : eye_open
                          : component.error
                          ? eye_closed_red
                          : eye_closed
                      }
                      alt="eye_closed"
                      onClick={() => {
                        console.log("clicked");
                        // Update the component's showPassword in the state
                        setFormJson((prevFormJson) => {
                          const updatedFormJson = [...prevFormJson];
                          updatedFormJson[formNumber - 1].form_components =
                            updatedFormJson[formNumber - 1].form_components.map(
                              (c) =>
                                c.title === component.title
                                  ? {
                                      ...c,
                                      showPassword: !c.showPassword,
                                    }
                                  : c
                            );
                          return updatedFormJson;
                        });
                      }}
                    />
                  )}
                </div>
                {component.error && (
                  <span className={styles.error_message}>
                    {component.error_message}
                  </span>
                )}
              </div>
            );
          })}

        {/* {<div className={styles.error_msg}>{error}</div>} */}
        <div>
          <button className={styles.submit_button} onClick={handleLogin}>
            Login
          </button>
        </div>
        <div className={styles.forgot_password_wrap}>
          <span className={styles.forgot_password}>Forgot Password</span>
          <span>
            Don't have an account?{" "}
            <a href="/signUp" className={styles.forgot_password}>
              Sign up here
            </a>
          </span>
        </div>
      </div>
    </div>
  ) : (
    <OtpAuthentication email={formJson[0].form_components[0].value} />
  );
};

export default LoginForm;
