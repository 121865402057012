import React from "react";
import styles from "../../../styles/common/statsboard/StatsBoard.module.css";
import { useNavigate } from "react-router-dom";
import { formatDate, numberWithCommas } from "../../../utils/generalFunctions";

const StatsBoard = ({
  boardName,
  statValuesMapping,
  statIcons,
  statNames,
  statValues,
  onClickRedirectPath,
}) => {
  console.log(statValues);
  const navigate = useNavigate();

  return (
    <div
      className={styles.stats_board}
      onClick={() => {
        if (onClickRedirectPath) navigate(onClickRedirectPath);
      }}
    >
      <span className={styles.stat_board_title}>{boardName}</span>
      <div className={styles.stats_board_stats_wrapper}>
        {statIcons.map((stat, index) => (
          <div key={index} className={styles.stat_board_single_stat}>
            <img src={statIcons[index]} alt={statIcons[index]}></img>
            <span className={styles.stat_board_single_stat_name}>
              {statNames[index]}
            </span>
            {statValuesMapping &&
            statValues &&
            statValuesMapping[index]?.includes("Date") ? (
              <span className={styles.stat_board_single_stat_value}>
                {statValues[`${statValuesMapping[index]}`] !== "NA"
                  ? formatDate(statValues[`${statValuesMapping[index]}`])
                  : statValues[`${statValuesMapping[index]}`]}
              </span>
            ) : (
              <span className={styles.stat_board_single_stat_value}>
                {statValues &&
                  numberWithCommas(statValues[`${statValuesMapping[index]}`])}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsBoard;
