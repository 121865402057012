import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tenantInfo: {},
  employeeInfo: {},
  organisationInfo: {},
};

const tenantInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    updateTenantInfo: (state, action) => {
      state.tenantInfo = action.payload;
    },
    updateEmployeeInfo: (state, action) => {
      state.employeeInfo = action.payload;
    },
    updateOrganisationInfo: (state, action) => {
      state.organisationInfo = action.payload;
    },
  },
});

export const { updateTenantInfo, updateEmployeeInfo, updateOrganisationInfo } =
  tenantInfoSlice.actions;
export default tenantInfoSlice.reducer;
