import React from "react";

import styles from "../../styles/authentication/Login.module.css";
import LoginPage_BG from "../../assets/authentication/login_bg.svg";

import RI_logo from "../../assets/authentication/RentIq_logo.svg";
import SignUpForm from "./SignUpForm";

function SignUp() {
  return (
    <div className={styles.login}>
      <img
        src={LoginPage_BG}
        className={styles.loginPage_BG}
        alt="LoginPage_BG"
      />
      <div className={styles.form_container}>
        <div className={styles.logo}>
          <img src={RI_logo} alt="LP_logo" />
        </div>
        <div className={styles.form_section}>
          <SignUpForm />
        </div>
      </div>
    </div>
  );
}

export default SignUp;
