import React, { useState } from "react";
import styles from "../../styles/authentication/Login.module.css";

import lucide_smile from "../../assets/authentication/lucide_smile.svg";

import user from "../../assets/authentication/user_img.svg";
import address from "../../assets/authentication/address.svg";
import comapny_address from "../../assets/authentication/comapny_address.svg";
import company_email from "../../assets/authentication/company_email.svg";
import company from "../../assets/authentication/company.svg";
import document from "../../assets/authentication/document.svg";
import mobile from "../../assets/authentication/mobile.svg";
import user_email from "../../assets/authentication/user_email.svg";

import user_red from "../../assets/authentication/user_img_red.svg";
import address_red from "../../assets/authentication/address_red.svg";
import comapny_address_red from "../../assets/authentication/comapny_address_red.svg";
import company_email_red from "../../assets/authentication/company_email_red.svg";
import company_red from "../../assets/authentication/company_red.svg";
import document_red from "../../assets/authentication/document_red.svg";
import mobile_red from "../../assets/authentication/mobile_red.svg";
import user_email_red from "../../assets/authentication/user_email_red.svg";

import back_icon from "../../assets/authentication/back.svg";

import Axios from "../../axios";

import { apiEndpointname } from "../../utils/constants";
import { employeeSchema, organisationSchema } from "../../utils/validators";
import { cleanFormObject } from "../../utils/generalFunctions";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/reducers/AlertSlice";
import { showLoader } from "../../redux/reducers/loaderSlice";

function SignUpForm() {
  const [formNumber, setFormNumber] = useState(1);

  const [formJson, setFormJson] = useState([
    {
      id: 1,
      title: "Personal Details",
      form_components: [
        {
          id: "name",
          title: "Full Name",
          icon: user,
          error_icon: user_red,
          placeholder: "Enter your name",
          type: "text",
          value: "",
          error: "",
        },
        {
          id: "email",
          title: "Personal Email",
          icon: user_email,
          error_icon: user_email_red,
          placeholder: "Enter your email",
          type: "email",
          value: "",
          error: "",
        },
        {
          id: "mobile",
          title: "Mobile Number",
          icon: mobile,
          error_icon: mobile_red,
          placeholder: "Enter your mobile number",
          type: "number",
          value: "",
          error: "",
        },
        {
          id: "address",
          title: "Permanent Address",
          icon: address,
          error_icon: address_red,
          placeholder: "Enter your address",
          type: "text",
          value: "",
          error: "",
        },
      ],
    },
    {
      id: 2,
      title: "Organization Details",
      hint: "If you don't have a Organization, use your name as the Organization name and your email as the Organization email.",
      form_components: [
        {
          id: "organisationName",
          title: "Organization Name",
          icon: company,
          error_icon: company_red,
          placeholder: "Enter your Organization name",
          type: "text",
          value: "",
          error: "",
        },
        {
          id: "organisationAddress",
          title: "Organization Address",
          icon: comapny_address,
          error_icon: comapny_address_red,
          placeholder: "Enter your Organization address",
          type: "text",
          value: "",
          error: "",
        },
        {
          id: "organisationEmail",
          title: "Organization Email",
          icon: company_email,
          error_icon: company_email_red,
          placeholder: "Enter your Organization email",
          type: "email",
          value: "",
          error: "",
        },
        {
          id: "GST",
          title: "GST Number",
          icon: document,
          error_icon: document_red,
          placeholder: "Enter your Organization GST number",
          type: "text",
          value: "",
          error: "",
        },
      ],
    },
  ]);

  const tagErrorToFields = (error) => {
    error?.details.map((item) => {
      let errorMessage = item.message;

      let errorFieldName = item.path[0];

      formJson.map((form, index) => {
        form.form_components.map((component, index2) => {
          if (component.id === errorFieldName) {
            setFormJson((prevFormJson) => {
              const updatedFormJson = [...prevFormJson];
              updatedFormJson[index].form_components = updatedFormJson[
                index
              ].form_components.map((c, index3) =>
                index2 === index3
                  ? {
                      ...c,
                      error: true,
                      error_message: errorMessage
                        .replace(/"/g, "")
                        .replace(errorFieldName, c.title),
                    }
                  : c
              );
              return updatedFormJson;
            });
          }
        });
      });
    });
  };

  // const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();

  const handleSignUp = async () => {
    // console.log(formJson);

    if (formNumber === 1) {
      setFormNumber(2);
      return;
    }

    dispatch(showLoader(true));

    let employeeData = {
      name: formJson[0].form_components[0].value,
      email: formJson[0].form_components[1].value,
      mobile: formJson[0].form_components[2].value,
      address: formJson[0].form_components[3].value,
    };

    let organizationData = {
      organisationName: formJson[1].form_components[0].value,
      organisationAddress: formJson[1].form_components[1].value,
      organisationEmail: formJson[1].form_components[2].value,
      GST: formJson[1].form_components[3].value,
    };

    employeeData = cleanFormObject(employeeData);
    organizationData = cleanFormObject(organizationData);

    const employeeDataError = employeeSchema.validate(employeeData);
    const organizationDataError = organisationSchema.validate(organizationData);

    tagErrorToFields(employeeDataError.error);
    tagErrorToFields(organizationDataError.error);

    console.log(employeeDataError);
    console.log(organizationDataError);

    if (employeeDataError.error) {
      setFormNumber(1);
      dispatch(
        setAlert({
          alertType: "error",
          alertMessage: "Please fill the form correctly",
          alertTitle: "Error",
          autoClose: false,
          alertShow: true,
        })
      );
      dispatch(showLoader(false));

      return;
    }

    if (organizationDataError.error) {
      dispatch(
        setAlert({
          alertType: "error",
          alertMessage: "Please fill the form correctly",
          alertTitle: "Error",
          autoClose: false,
          alertShow: true,
        })
      );
      dispatch(showLoader(false));

      return;
    }

    let data = {
      employee: employeeData,
      organisation: organizationData,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "api/v1" + apiEndpointname["SIGNUP"],
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await Axios.request(config);

      console.log(response);

      if (response.status === 200 || response.status === 201) {
        setFormNumber(3);
      } else {
        console.log("else");

        if (response.data.message) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMessage: response.data.message,
              alertTitle: "Error",
              autoClose: false,
              alertShow: true,
            })
          );
        }
      }
    } catch (error) {
      if (error.response?.data?.message) {
        dispatch(
          setAlert({
            alertType: "error",
            alertMessage: error.response.data.message,
            alertTitle: "Error",
            autoClose: false,
            alertShow: true,
          })
        );
      }
    }

    dispatch(showLoader(false));
  };

  const SuccessModal = () => {
    return (
      <div className={styles.success_modal_wrapper}>
        <div className={styles.success_modal}>
          <div className={styles.circle_loader}>
            <div className={styles.checkmark + " " + styles.draw}></div>
            <img
              src={lucide_smile}
              alt="lucide_smile"
              className={styles.approval_icon}
            ></img>
          </div>

          <div className={styles.success_modal_title}>Thank You !</div>
          <div className={styles.success_modal_message}>
            We have sent an email with an account activation link. Please use
            the login link to set up your credentials.
          </div>
          <div
            className={styles.success_modal_button}
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            OK
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.form}>
      {formNumber === 3 && <SuccessModal />}

      {formNumber === 2 && (
        <div className={styles.back_button} onClick={() => setFormNumber(1)}>
          <img src={back_icon} alt="back" /> Go Back
        </div>
      )}

      {formNumber !== 3 && (
        <div className={styles.form_title}>
          {formJson[formNumber - 1].title}
        </div>
      )}

      {formNumber === 2 && (
        <div className={styles.form_hint}>{formJson[formNumber - 1].hint}</div>
      )}

      <div className={styles.form_components}>
        {formNumber !== 3 &&
          formJson[formNumber - 1]?.form_components.map((component) => {
            return (
              <div className={styles.form_component} key={component.title}>
                <div className={styles.title}>{component.title}</div>
                <div
                  className={
                    styles.input_container +
                    " " +
                    (component.error && styles.input_container_error)
                  }
                >
                  <img
                    src={
                      component.error ? component.error_icon : component.icon
                    }
                    alt="user"
                  />
                  <input
                    type={component.type}
                    placeholder={component.placeholder}
                    className={styles.value}
                    onChange={(e) => {
                      // Update the component's value in the state
                      setFormJson((prevFormJson) => {
                        const updatedFormJson = [...prevFormJson];
                        updatedFormJson[formNumber - 1].form_components =
                          updatedFormJson[formNumber - 1].form_components.map(
                            (c) =>
                              c.title === component.title
                                ? {
                                    ...c,
                                    value: e.target.value,
                                    error: false,
                                    error_message: "",
                                  }
                                : c
                          );
                        return updatedFormJson;
                      });
                    }}
                    value={component.value} // Use 'value' instead of 'defaultValue'
                  />
                </div>
                {component.error && (
                  <span className={styles.error_message}>
                    {component.error_message}
                  </span>
                )}
              </div>
            );
          })}

        {/* {<div className={styles.error_msg}>{error}</div>} */}
        <div>
          <button className={styles.submit_button} onClick={handleSignUp}>
            {formNumber === 1 ? "Next" : "Create Account"}
          </button>
        </div>
        <span>
          Already have an account ?{" "}
          <a href="/login" className={styles.forgot_password}>
            Login here
          </a>
        </span>
      </div>
    </div>
  );
}

export default SignUpForm;
