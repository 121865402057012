import styles from "../../../../styles/sections/tenant/support_and_help/SupportAndHelp.module.css";
import DetailsTable from "../../../common/details_table/DetailsTable";
import ContactInfo from "../../../../assets/sections/tenant/support_and_help/contact_info.svg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const SupportAndHelp = () => {
  const organisationInfo = useSelector(
    (state) => state?.userInfo?.organisationInfo
  );

  const [table_data, setTable_data] = useState([
    {
      field_name: "Organisation Name",
      value: "",
      id: "organisationName",
    },
    {
      field_name: "Organisation Address",
      value: "",
      id: "organisationAddress",
    },
    {
      field_name: "Organisation Email",
      value: "",
      id: "organisationEmail",
    },
  ]);
  useEffect(() => {
    if (organisationInfo) {
      setTable_data((prev) => {
        return prev.map((prevItem) => {
          const updatedValue = organisationInfo[prevItem.id];
          return {
            ...prevItem,
            value: updatedValue !== undefined ? updatedValue : prevItem.value,
          };
        });
      });
    }
  }, [organisationInfo]);

  return (
    <div className={styles.support}>
      <div className={styles.documents_wrap}>
        <div className={styles.content_wrap}>
          <DetailsTable
            headerImg={ContactInfo}
            tableTitle={"Contact Info"}
            tableData={table_data}
          />
        </div>
      </div>
    </div>
  );
};

export default SupportAndHelp;
