import styles from "../../../../styles/sections/admin/broadcasts/broadcastMessages.module.css";

import { useEffect, useRef, useState } from "react";

import NavBar from "../../../common/nav_bar/NavBar";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../utils/generalFunctions";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { set } from "lodash";

const BroadcastMessages = () => {
  // const navSections = ["Sent Message", "Scheduled Message"];
  const navSections = ["Sent Message"];

  const navigate = useNavigate();
  // const tenantId = useParams().tenantId;

  const [section, setSection] = useState("Sent Message");
  const dispatch = useDispatch();

  const [currentMsgDetails, setCurrentMsgDetails] = useState({});
  const [openUserListPopUp, setOpenUserListPopUp] = useState(false);

  const handleGetMoreDetails = async (messageId) => {
    setCurrentMsgDetails({});
    dispatch(showLoader(true));

    const response = await getSingleData({
      API_NAME: "ADMIN__MESSAGES__GET_ONE_MESSAGE",
      oneId: messageId,
    });

    if (response) {
      setCurrentMsgDetails(response);
      setOpenUserListPopUp(true);
    }
    dispatch(showLoader(false));
  };

  const [messagesList, setMessagesList] = useState([]);

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData["id"] = item._id;
      mappedData["message"] = item.message;
      mappedData["messageType"] = item.messageType;
      mappedData["date"] = item.sendDateTime?.split("T")[0];
      mappedData["time"] = item.sendDateTime?.split("T")[1].split(".")[0];
      mappedData["tenantIdCount"] = item.tenantIdCount;
      mappedData["tenantUnitMappping"] = item.tenantUnitMappping;

      mappedDataList.push(mappedData);
    });

    setMessagesList(mappedDataList);
  };

  const initialRender = useRef(true);
  const [searchText, setSearchText] = useState("");
  const scroll = useSelector((state) => state.pageScroll);
  const [isSearchUsed, setIsSearchUsed] = useState(false);
  const stateDataTableData = useSelector((state) => state.getTableData);

  useEffect(() => {
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__MESSAGES__MESSAGES",
        scroll.pageNo,
        searchText
      );
      //   fetchPageStats(dispatch, STATS_KEYS.EMPLOYEE__TENANTS__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__MESSAGES__MESSAGES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__MESSAGES__MESSAGES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__MESSAGES__MESSAGES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__MESSAGES__MESSAGES",
        scroll.pageNo,
        searchText
      );
      //   fetchPageStats(dispatch, STATS_KEYS.EMPLOYEE__TENANTS__STATS, "");
    }
  }, [scroll.pageNo, searchText]);

  useEffect(() => {
    if (stateDataTableData.adminMessages?.length >= 0)
      processTableData(stateDataTableData.adminMessages);
  }, [stateDataTableData.adminMessages]);

  const containerRef = useRef(null);
  //   const dispatch = useDispatch();

  //   const scroll = useSelector((state) => state.pageScroll);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  let totalPages = stateDataTotalPages.adminMessagesTotalPages;

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      if (
        // if less than 2px then scrolled to the bottom
        container.scrollTop + container.clientHeight + 2 >=
        container.scrollHeight
      ) {
        // User has scrolled to the bottom
        console.log("I reached last, calling the next API");
        // You can call your next API here
        if (scroll.pageNo < totalPages) {
          console.log("I am calling the next API");
          dispatch(
            updatePageNumber({
              pageNo: scroll.pageNo + 1,
            })
          );
        }
      }
    }
  };

  const [editModeUserList, setEditModeUserList] = useState(false);

  const UserListPopUp = () => {
    return (
      <div className={styles.user_list_popup}>
        <div className={styles.user_list_popup_table}>
          <button
            onClick={() => {
              setOpenUserListPopUp(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>

          {currentMsgDetails?.tenantUnitMappping?.map((item, index) => {
            return (
              <div className={styles.user_list_item} key={index}>
                {editModeUserList && (
                  <input
                    type="checkbox"
                    className={styles.table_checkbox}
                    defaultChecked={true}
                  />
                )}

                <div className={styles.user_list_item_unit}>
                  {item.unitName}
                </div>
                <div className={styles.user_list_item_name}>
                  {item.tenantName}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={styles.user_list_popup_footer + " " + styles.schedule_CTA}
        >
          {/* {!editModeUserList && (
            <button
              className={styles.reschedule_button}
              onClick={() => setEditModeUserList(true)}
            >
              Edit List
            </button>
          )} */}
          {editModeUserList && (
            <>
              <button
                className={styles.cancel_button}
                onClick={() => setEditModeUserList(false)}
              >
                Cancel
              </button>
              <button className={styles.reschedule_button}>Update List</button>
            </>
          )}
        </div>
      </div>
    );
  };

  const MessageCard = ({ message }) => {
    return (
      <div className={styles.message_card}>
        <div className={styles.message_header}>
          <span className={styles.header_title}>Message Sent to</span>
          <span
            className={styles.tenant_count}
            onClick={() => {
              handleGetMoreDetails(message.id);
            }}
          >
            {message?.tenantIdCount} Tenants
          </span>
        </div>
        <div className={styles.message_body}>
          <p className={styles.message_content}>
            {message.message}
            {/* <span className={styles.message_expander}> More Info </span> */}
          </p>

          {/* if date is future then show CTA else not */}
          {message.date > new Date().toISOString().split("T")[0] && (
            <div className={styles.schedule_CTA}>
              <button className={styles.cancel_button}>Cancel</button>
              <button className={styles.reschedule_button}>Rescehdule</button>
            </div>
          )}
        </div>
        <div className={styles.message_footer}>
          <span className={styles.message_date}>
            {formatDate(message.date)}
          </span>
          <span className={styles.message_time}>{message.time}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.tenant_details}>
        <div className={styles.details_header}>
          <div className={styles.detailed_section}>
            <img src={BackArrow} alt="" onClick={() => navigate(-1)}></img>
            Message History
          </div>
        </div>
        <NavBar state={setSection} active={section} navLinks={navSections} />
        <div
          className={styles.history_table}
          onScroll={handleScroll}
          ref={containerRef}
        >
          {messagesList.map((item, index) => {
            return <MessageCard message={item} key={index} />;
          })}
        </div>
        {openUserListPopUp && (
          <div className={styles.user_list_popup_container}>
            <UserListPopUp />
          </div>
        )}
      </div>
    </>
  );
};

export default BroadcastMessages;
